import images from '../assets/images';

export const QUEST_STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  FINISHED: 'FINISHED',
};

export const QUEST_DIFFICULTY = {
  NOVICE: {
    id: 0,
    label: 'Novice',
    icon: images['task-bronze-star.png'],
  },
  INTERMEDIATE: {
    id: 1,
    label: 'Intermediate',
    icon: images['task-silver-star.png'],
  },
  EXPERIENCED: {
    id: 2,
    label: 'Experienced',
    icon: images['task-gold-star.png'],
  },
  MASTER: {
    id: 3,
    label: 'Master',
    icon: images['task-two-stars.png'],
  },
  GRANDMASTER: {
    id: 4,
    label: 'Grandmaster',
    icon: images['task-three-stars.png'],
  },
};

export const QUEST_LENGTH = {
  VERY_SHORT: {
    id: 0,
    label: 'Very Short',
    icon: images['task-bronze-sword.png'],
  },
  SHORT: {
    id: 1,
    label: 'Short',
    icon: images['task-iron-sword.png'],
  },
  MEDIUM: {
    id: 2,
    label: 'Medium',
    icon: images['task-mith-sword.png'],
  },
  LONG: {
    id: 3,
    label: 'Long',
    icon: images['task-rune-sword.png'],
  },
  VERY_LONG: {
    id: 4,
    label: 'Very Long',
    icon: images['task-dragon-sword.png'],
  },
};

export const QUEST_SERIES = {
  CAMELOT: {
    id: 0,
    label: 'Camelot',
    icon: images['task-kandarin.png'],
  },
  DESERT: {
    id: 1,
    label: 'Desert',
    icon: images['task-kharidian.png'],
  },
  DORGESHUUN: {
    id: 2,
    label: 'Dorgeshuun',
    icon: images['task-bandos.png'],
  },
  GUILDS: {
    id: 3,
    label: 'Guilds / Dragonkin',
    icon: images['task-legend.png'],
  },
  ELEMENTAL: {
    id: 4,
    label: 'Elemental Workshop',
    icon: images['task-elemental.png'],
  },
  ELF: {
    id: 5,
    label: 'Elven',
    icon: images['task-tiranwnn.png'],
  },
  GNOME: {
    id: 6,
    label: 'Gnome',
    icon: images['task-gnome.png'],
  },
  FAIRY: {
    id: 7,
    label: 'Fairy Tale',
    icon: images['task-cosmic.png'],
  },
  FREMENNIK: {
    id: 8,
    label: 'Fremennik',
    icon: images['task-fremennik.png'],
  },
  KOUREND: {
    id: 9,
    label: 'Great Kourend',
    icon: images['task-xeric.png'],
  },
  KARAMJA: {
    id: 10,
    label: 'Karamja',
    icon: images['task-karamja.png'],
  },
  MAHJARRAT: {
    id: 11,
    label: 'Mahjarrat / Return of Zaros',
    icon: images['task-zaros.png'],
  },
  MYREQUE: {
    id: 12,
    label: 'Myreque',
    icon: images['task-morytania.png'],
  },
  OGRE: {
    id: 13,
    label: 'Ogre',
    icon: images['task-goblin.png'],
  },
  PIRATE: {
    id: 14,
    label: 'Pirate',
    icon: images['task-steel-scim.png'],
  },
  RED_AXE: {
    id: 15,
    label: 'Rise of the Red Axe',
    icon: images['task-dragon-baxe.png'],
  },
  TEMPLE_KNIGHT: {
    id: 16,
    label: 'Temple Knight / Sea Slug',
    icon: images['task-steel-kite.png'],
  },
  TROLL: {
    id: 17,
    label: 'Troll',
    icon: images['task-bossing.png'],
  },
  TWISTED_TALES: {
    id: 18,
    label: 'Twisted Tales',
    icon: images['task-xeric.png'],
  },
};

export const REGION_IMPOSSIBLE_TO_COMPLETE = 'Impossible';

const quests = [
  {
    id: '300',
    label: "Cook's Assistant",
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.VERY_SHORT,
    type: 'quest',
    autoUnlockRegions: ['Kandarin', 'Morytania'],
    regions: ['Misthalin'],
    prereqs: [],
    skillReqs: [],
    wiki: "https://oldschool.runescape.wiki/w/Cook's_Assistant",
  },
  {
    id: '302',
    label: 'Demon Slayer',
    points: 3,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Misthalin'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Demon_Slayer',
  },
  {
    id: '312',
    label: 'The Restless Ghost',
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: ['Misthalin'],
    regions: [],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/The_Restless_Ghost',
  },
  {
    id: '313',
    label: 'Romeo & Juliet',
    points: 5,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: ['Desert', 'Fremennik'],
    regions: ['Misthalin'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Romeo_&_Juliet',
  },
  {
    id: '315',
    label: 'Sheep Shearer',
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.VERY_SHORT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Misthalin'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Sheep_Shearer',
  },
  {
    id: '316',
    label: 'Shield of Arrav',
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.MAHJARRAT,
    type: 'quest',
    autoUnlockRegions: ['Fremennik'],
    regions: ['Misthalin'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Shield_of_Arrav',
  },
  {
    id: '305',
    label: 'Ernest the Chicken',
    points: 4,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: ['Fremennik'],
    regions: ['Misthalin'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Ernest_the_Chicken',
  },
  {
    id: '1278',
    label: 'Vampyre Slayer',
    points: 3,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Misthalin'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Vampyre_Slayer',
  },
  {
    id: '307',
    label: 'Imp Catcher',
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Misthalin'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Imp_Catcher',
  },
  {
    id: '311',
    label: 'Prince Ali Rescue',
    points: 3,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Desert'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Prince_Ali_Rescue',
  },
  {
    id: '3138',
    label: "Doric's Quest",
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.VERY_SHORT,
    type: 'quest',
    autoUnlockRegions: ['Desert', 'Fremennik'],
    regions: ['Asgarnia'],
    prereqs: [],
    skillReqs: [],
    wiki: "https://oldschool.runescape.wiki/w/Doric's_Quest",
  },
  {
    id: '299',
    label: "Black Knights' Fortress",
    points: 3,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: ['Desert', 'Fremennik', 'Kandarin'],
    regions: ['Asgarnia'],
    prereqs: [],
    skillReqs: [{ skill: 'QP', level: 12 }],
    wiki: "https://oldschool.runescape.wiki/w/Black_Knights'_Fortress",
  },
  {
    id: '318',
    label: "Witch's Potion",
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.VERY_SHORT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Asgarnia'],
    prereqs: [],
    skillReqs: [],
    wiki: "https://oldschool.runescape.wiki/w/Witch's_Potion",
  },
  {
    id: '308',
    label: "The Knight's Sword",
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Asgarnia'],
    prereqs: [],
    skillReqs: [{ skill: 'Mining', level: 10 }],
    wiki: "https://oldschool.runescape.wiki/w/The_Knight's_Sword",
  },
  {
    id: '306',
    label: 'Goblin Diplomacy',
    points: 5,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: ['Desert', 'Fremennik'],
    regions: ['Asgarnia'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Goblin_Diplomacy',
  },
  {
    id: '310',
    label: "Pirate's Treasure",
    points: 2,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.PIRATE,
    type: 'quest',
    autoUnlockRegions: ['Morytania'],
    regions: ['Asgarnia'],
    prereqs: [],
    skillReqs: [],
    wiki: "https://oldschool.runescape.wiki/w/Pirate's_Treasure",
  },
  {
    id: '3139',
    label: 'Dragon Slayer I',
    points: 2,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.GUILDS,
    type: 'quest',
    autoUnlockRegions: ['Misthalin'],
    regions: [],
    prereqs: [],
    skillReqs: [{ skill: 'QP', level: 32 }],
    wiki: 'https://oldschool.runescape.wiki/w/Dragon_Slayer_I',
  },
  {
    id: '350',
    label: 'Druidic Ritual',
    points: 4,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: ['Misthalin'],
    regions: [],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Druidic_Ritual',
  },
  {
    id: '389',
    label: 'Lost City',
    points: 3,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.FAIRY,
    type: 'quest',
    autoUnlockRegions: ['Misthalin'],
    regions: [],
    prereqs: [],
    skillReqs: [
      { skill: 'Woodcutting', level: 36 },
      { skill: 'Crafting', level: 31 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Lost_City',
  },
  {
    id: '448',
    label: "Witch's House",
    points: 4,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Asgarnia'],
    prereqs: [],
    skillReqs: [],
    wiki: "https://oldschool.runescape.wiki/w/Witch's_House",
  },
  {
    id: '394',
    label: "Merlin's Crystal",
    points: 6,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.CAMELOT,
    type: 'quest',
    autoUnlockRegions: ['Asgarnia', 'Fremennik', 'Kandarin'],
    regions: [],
    prereqs: [],
    skillReqs: [],
    wiki: "https://oldschool.runescape.wiki/w/Merlin's_Crystal",
  },
  {
    id: '3142',
    label: "Heroes' Quest",
    points: 1,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.GUILDS,
    type: 'quest',
    autoUnlockRegions: ['Fremennik'],
    regions: ['Asgarnia'],
    prereqs: ['350', '316', '394', '3139', '389'],
    skillReqs: [
      { skill: 'Cooking', level: 53 },
      { skill: 'Fishing', level: 53 },
      { skill: 'Mining', level: 50 },
      { skill: 'Herblore', level: 25 },
      { skill: 'QP', level: 55 },
    ],
    wiki: "https://oldschool.runescape.wiki/w/Heroes'_Quest",
  },
  {
    id: '419',
    label: 'Scorpion Catcher',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Asgarnia', 'Kandarin'],
    prereqs: ['322'],
    skillReqs: [{ skill: 'Prayer', level: 31 }],
    wiki: 'https://oldschool.runescape.wiki/w/Scorpion_Catcher',
  },
  {
    id: '361',
    label: 'Family Crest',
    points: 1,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: ['Fremennik'],
    regions: [REGION_IMPOSSIBLE_TO_COMPLETE],
    prereqs: [],
    skillReqs: [
      { skill: 'Magic', level: 59 },
      { skill: 'Crafting', level: 40 },
      { skill: 'Smithing', level: 40 },
      { skill: 'Mining', level: 40 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Family_Crest',
  },
  {
    id: '439',
    label: 'Tribal Totem',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Karamja'],
    prereqs: [],
    skillReqs: [{ skill: 'Thieving', level: 21 }],
    wiki: 'https://oldschool.runescape.wiki/w/Tribal_Totem',
  },
  {
    id: '364',
    label: 'Fishing Contest',
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Kandarin'],
    prereqs: [],
    skillReqs: [{ skill: 'Fishing', level: 10 }],
    wiki: 'https://oldschool.runescape.wiki/w/Fishing_Contest',
  },
  {
    id: '397',
    label: "Monk's Friend",
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Kandarin'],
    prereqs: [],
    skillReqs: [],
    wiki: "https://oldschool.runescape.wiki/w/Monk's_Friend",
  },
  {
    id: '434',
    label: 'Temple of Ikov',
    points: 1,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.MAHJARRAT,
    type: 'quest',
    autoUnlockRegions: ['Desert'],
    regions: ['Kandarin'],
    prereqs: [],
    skillReqs: [
      { skill: 'Ranged', level: 40 },
      { skill: 'Thieving', level: 42 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Temple_of_Ikov',
  },
  {
    id: '337',
    label: 'Clock Tower',
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Kandarin'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Clock_Tower',
  },
  {
    id: '381',
    label: 'Holy Grail',
    points: 2,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.CAMELOT,
    type: 'quest',
    autoUnlockRegions: ['Kandarin'],
    regions: [],
    prereqs: ['394'],
    skillReqs: [{ skill: 'Attack', level: 20 }],
    wiki: 'https://oldschool.runescape.wiki/w/Holy_Grail',
  },
  {
    id: '438',
    label: 'Tree Gnome Village',
    points: 2,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.GNOME,
    type: 'quest',
    autoUnlockRegions: ['Kandarin'],
    regions: [],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Tree_Gnome_Village',
  },
  {
    id: '363',
    label: 'Fight Arena',
    points: 2,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.MAHJARRAT,
    type: 'quest',
    autoUnlockRegions: ['Desert', 'Fremennik'],
    regions: ['Kandarin'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Fight_Arena',
  },
  {
    id: '379',
    label: 'Hazeel Cult',
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.MAHJARRAT,
    type: 'quest',
    autoUnlockRegions: ['Desert', 'Fremennik'],
    regions: ['Kandarin'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Hazeel_Cult',
  },
  {
    id: '423',
    label: 'Sheep Herder',
    points: 4,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.ELF,
    type: 'quest',
    autoUnlockRegions: ['Tirannwn'],
    regions: ['Kandarin'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Sheep_Herder',
  },
  {
    id: '407',
    label: 'Plague City',
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.ELF,
    type: 'quest',
    autoUnlockRegions: ['Fremennik', 'Tirannwn'],
    regions: ['Kandarin'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Plague_City',
  },
  {
    id: '420',
    label: 'Sea Slug',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.TEMPLE_KNIGHT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Kandarin'],
    prereqs: [],
    skillReqs: [{ skill: 'Firemaking', level: 30 }],
    wiki: 'https://oldschool.runescape.wiki/w/Sea_Slug',
  },
  {
    id: '3154',
    label: 'Waterfall Quest',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.ELF,
    type: 'quest',
    autoUnlockRegions: ['Desert', 'Fremennik', 'Tirannwn'],
    regions: ['Kandarin'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Waterfall_Quest',
  },
  {
    id: '335',
    label: 'Biohazard',
    points: 3,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.ELF,
    type: 'quest',
    autoUnlockRegions: ['Fremennik', 'Tirannwn'],
    regions: ['Asgarnia', 'Kandarin'],
    prereqs: ['407'],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Biohazard',
  },
  {
    id: '386',
    label: 'Jungle Potion',
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.KARAMJA,
    type: 'quest',
    autoUnlockRegions: ['Karamja'],
    regions: [],
    prereqs: ['350'],
    skillReqs: [{ skill: 'Herblore', level: 3 }],
    wiki: 'https://oldschool.runescape.wiki/w/Jungle_Potion',
  },
  {
    id: '374',
    label: 'The Grand Tree',
    points: 5,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.GNOME,
    type: 'quest',
    autoUnlockRegions: ['Kandarin'],
    regions: [],
    prereqs: [],
    skillReqs: [{ skill: 'Agility', level: 25 }],
    wiki: 'https://oldschool.runescape.wiki/w/The_Grand_Tree',
  },
  {
    id: '424',
    label: 'Shilo Village',
    points: 2,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.KARAMJA,
    type: 'quest',
    autoUnlockRegions: ['Karamja'],
    regions: [],
    prereqs: ['386'],
    skillReqs: [
      { skill: 'Crafting', level: 20 },
      { skill: 'Agility', level: 32 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Shilo_Village',
  },
  {
    id: '442',
    label: 'Underground Pass',
    points: 5,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.VERY_LONG,
    series: QUEST_SERIES.ELF,
    type: 'quest',
    autoUnlockRegions: ['Fremennik', 'Tirannwn'],
    regions: [REGION_IMPOSSIBLE_TO_COMPLETE],
    prereqs: ['335'],
    skillReqs: [{ skill: 'Ranged', level: 25 }],
    wiki: 'https://oldschool.runescape.wiki/w/Underground_Pass',
  },
  {
    id: '3149',
    label: 'Observatory Quest',
    points: 2,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Kandarin'],
    prereqs: [],
    skillReqs: [{ skill: 'Crafting', level: 10 }],
    wiki: 'https://oldschool.runescape.wiki/w/Observatory_Quest',
  },
  {
    id: '436',
    label: 'The Tourist Trap',
    points: 2,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: ['Desert'],
    regions: [],
    prereqs: [],
    skillReqs: [
      { skill: 'Fletching', level: 10 },
      { skill: 'Smithing', level: 20 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/The_Tourist_Trap',
  },
  {
    id: '445',
    label: 'Watchtower',
    points: 4,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.OGRE,
    type: 'quest',
    autoUnlockRegions: ['Kandarin'],
    regions: [],
    prereqs: ['350'],
    skillReqs: [
      { skill: 'Magic', level: 15 },
      { skill: 'Mining', level: 40 },
      { skill: 'Herblore', level: 14 },
      { skill: 'Agility', level: 25 },
      { skill: 'Thieving', level: 15 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Watchtower',
  },
  {
    id: '351',
    label: 'Dwarf Cannon',
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Asgarnia', 'Kandarin'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Dwarf_Cannon',
  },
  {
    id: '401',
    label: 'Murder Mystery',
    points: 3,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.CAMELOT,
    type: 'quest',
    autoUnlockRegions: ['Kandarin'],
    regions: [],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Murder_Mystery',
  },
  {
    id: '347',
    label: 'The Dig Site',
    points: 2,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.MAHJARRAT,
    type: 'quest',
    autoUnlockRegions: ['Misthalin'],
    regions: [],
    prereqs: ['350'],
    skillReqs: [
      { skill: 'Herblore', level: 10 },
      { skill: 'Agility', level: 10 },
      { skill: 'Thieving', level: 25 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/The_Dig_Site',
  },
  {
    id: '370',
    label: "Gertrude's Cat",
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: ['Fremennik'],
    regions: ['Misthalin'],
    prereqs: [],
    skillReqs: [],
    wiki: "https://oldschool.runescape.wiki/w/Gertrude's_Cat",
  },
  {
    id: '3145',
    label: "Legends' Quest",
    points: 4,
    difficulty: QUEST_DIFFICULTY.MASTER,
    length: QUEST_LENGTH.VERY_LONG,
    series: QUEST_SERIES.GUILDS,
    type: 'quest',
    autoUnlockRegions: ['Fremennik'],
    regions: [REGION_IMPOSSIBLE_TO_COMPLETE],
    prereqs: ['3154', '361', '3142', '424', '442'],
    skillReqs: [
      { skill: 'Strength', level: 50 },
      { skill: 'Prayer', level: 42 },
      { skill: 'Magic', level: 56 },
      { skill: 'Woodcutting', level: 50 },
      { skill: 'Crafting', level: 50 },
      { skill: 'Smithing', level: 50 },
      { skill: 'Mining', level: 52 },
      { skill: 'Herblore', level: 45 },
      { skill: 'Agility', level: 50 },
      { skill: 'Thieving', level: 50 },
      { skill: 'QP', level: 107 },
    ],
    wiki: "https://oldschool.runescape.wiki/w/Legends'_Quest",
  },
  {
    id: '314',
    label: 'Rune Mysteries',
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: ['Desert', 'Fremennik', 'Kandarin', 'Wilderness'],
    regions: ['Misthalin'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Rune_Mysteries',
  },
  {
    id: '334',
    label: 'Big Chompy Bird Hunting',
    points: 2,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.OGRE,
    type: 'quest',
    autoUnlockRegions: ['Morytania', 'Tirannwn'],
    regions: ['Kandarin'],
    prereqs: [],
    skillReqs: [
      { skill: 'Ranged', level: 30 },
      { skill: 'Cooking', level: 30 },
      { skill: 'Fletching', level: 5 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Big_Chompy_Bird_Hunting',
  },
  {
    id: '354',
    label: 'Elemental Workshop I',
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.ELEMENTAL,
    type: 'quest',
    autoUnlockRegions: ['Kandarin'],
    regions: [],
    prereqs: [],
    skillReqs: [
      { skill: 'Crafting', level: 20 },
      { skill: 'Smithing', level: 20 },
      { skill: 'Mining', level: 20 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Elemental_Workshop_I',
  },
  {
    id: '408',
    label: 'Priest in Peril',
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.MYREQUE,
    type: 'quest',
    autoUnlockRegions: ['Misthalin'],
    regions: [],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Priest_in_Peril',
  },
  {
    id: '403',
    label: 'Nature Spirit',
    points: 2,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.MYREQUE,
    type: 'quest',
    autoUnlockRegions: ['Misthalin'],
    regions: [],
    prereqs: ['408', '312'],
    skillReqs: [{ skill: 'Crafting', level: 18 }],
    wiki: 'https://oldschool.runescape.wiki/w/Nature_Spirit',
  },
  {
    id: '342',
    label: 'Death Plateau',
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.TROLL,
    type: 'quest',
    autoUnlockRegions: ['Asgarnia', 'Desert', 'Fremennik', 'Kandarin'],
    regions: [],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Death_Plateau',
  },
  {
    id: '441',
    label: 'Troll Stronghold',
    points: 1,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.TROLL,
    type: 'quest',
    autoUnlockRegions: ['Asgarnia', 'Desert', 'Fremennik', 'Kandarin'],
    regions: [],
    prereqs: ['342'],
    skillReqs: [{ skill: 'Agility', level: 15 }],
    wiki: 'https://oldschool.runescape.wiki/w/Troll_Stronghold',
  },
  {
    id: '429',
    label: 'Tai Bwo Wannai Trio',
    points: 2,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.KARAMJA,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Karamja'],
    prereqs: ['386'],
    skillReqs: [
      { skill: 'Cooking', level: 30 },
      { skill: 'Fishing', level: 5 },
      { skill: 'Agility', level: 15 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Tai_Bwo_Wannai_Trio',
  },
  {
    id: '415',
    label: 'Regicide',
    points: 3,
    difficulty: QUEST_DIFFICULTY.MASTER,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.ELF,
    type: 'quest',
    autoUnlockRegions: ['Tirannwn'],
    regions: [],
    prereqs: ['442'],
    skillReqs: [
      { skill: 'Crafting', level: 10 },
      { skill: 'Agility', level: 56 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Regicide',
  },
  {
    id: '352',
    label: "Eadgar's Ruse",
    points: 1,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.TROLL,
    type: 'quest',
    autoUnlockRegions: ['Asgarnia', 'Desert', 'Fremennik'],
    regions: [],
    prereqs: ['350', '441'],
    skillReqs: [{ skill: 'Herblore', level: 31 }],
    wiki: "https://oldschool.runescape.wiki/w/Eadgar's_Ruse",
  },
  {
    id: '421',
    label: "Shades of Mort'ton",
    points: 3,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Morytania'],
    prereqs: ['350', '408'],
    skillReqs: [
      { skill: 'Firemaking', level: 5 },
      { skill: 'Crafting', level: 20 },
      { skill: 'Herblore', level: 15 },
    ],
    wiki: "https://oldschool.runescape.wiki/w/Shades_of_Mort'ton",
  },
  {
    id: '368',
    label: 'The Fremennik Trials',
    points: 3,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.FREMENNIK,
    type: 'quest',
    autoUnlockRegions: ['Fremennik'],
    regions: [],
    prereqs: [],
    skillReqs: [
      { skill: 'Woodcutting', level: 40 },
      { skill: 'Fletching', level: 25 },
      { skill: 'Crafting', level: 40 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/The_Fremennik_Trials',
  },
  {
    id: '382',
    label: 'Horror from the Deep',
    points: 2,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.FREMENNIK,
    type: 'quest',
    autoUnlockRegions: ['Fremennik'],
    regions: [],
    prereqs: ['322'],
    skillReqs: [{ skill: 'Agility', level: 35 }],
    wiki: 'https://oldschool.runescape.wiki/w/Horror_from_the_Deep',
  },
  {
    id: '435',
    label: 'Throne of Miscellania',
    points: 1,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.FREMENNIK,
    type: 'quest',
    autoUnlockRegions: ['Fremennik'],
    regions: [],
    prereqs: ['368', '3142'],
    skillReqs: [
      { skill: 'Woodcutting', level: 45 },
      { skill: 'Fishing', level: 35 },
      { skill: 'Mining', level: 30 },
      { skill: 'Farming', level: 10 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Throne_of_Miscellania',
  },
  {
    id: '395',
    label: 'Monkey Madness I',
    points: 3,
    difficulty: QUEST_DIFFICULTY.MASTER,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.GNOME,
    type: 'quest',
    autoUnlockRegions: ['Kandarin'],
    regions: [],
    prereqs: ['438', '374'],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Monkey_Madness_I',
  },
  {
    id: '378',
    label: 'Haunted Mine',
    points: 2,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Morytania'],
    prereqs: ['408'],
    skillReqs: [
      { skill: 'Agility', level: 15 },
      { skill: 'Runecraft', level: 35 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Haunted_Mine',
  },
  {
    id: '440',
    label: 'Troll Romance',
    points: 2,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.TROLL,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Asgarnia', 'Kandarin'],
    prereqs: ['441'],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Troll_Romance',
  },
  {
    id: '385',
    label: 'In Search of the Myreque',
    points: 2,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.MYREQUE,
    type: 'quest',
    autoUnlockRegions: ['Morytania'],
    regions: [],
    prereqs: ['403'],
    skillReqs: [{ skill: 'Agility', level: 25 }],
    wiki: 'https://oldschool.runescape.wiki/w/In_Search_of_the_Myreque',
  },
  {
    id: '340',
    label: 'Creature of Fenkenstrain',
    points: 2,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: ['Desert', 'Fremennik', 'Kandarin', 'Morytania'],
    regions: [],
    prereqs: ['408', '312'],
    skillReqs: [
      { skill: 'Crafting', level: 20 },
      { skill: 'Thieving', level: 25 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Creature_of_Fenkenstrain',
  },
  {
    id: '416',
    label: 'Roving Elves',
    points: 1,
    difficulty: QUEST_DIFFICULTY.MASTER,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.ELF,
    type: 'quest',
    autoUnlockRegions: ['Tirannwn'],
    regions: [],
    prereqs: ['3154', '415'],
    skillReqs: [{ skill: 'Agility', level: 56 }],
    wiki: 'https://oldschool.runescape.wiki/w/Roving_Elves',
  },
  {
    id: '371',
    label: 'Ghosts Ahoy',
    points: 2,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.LONG,
    type: 'quest',
    autoUnlockRegions: ['Fremennik'],
    regions: ['Morytania'],
    prereqs: ['408', '312'],
    skillReqs: [
      { skill: 'Cooking', level: 20 },
      { skill: 'Agility', level: 25 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Ghosts_Ahoy',
  },
  {
    id: '406',
    label: 'One Small Favour',
    points: 2,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.VERY_LONG,
    series: QUEST_SERIES.KARAMJA,
    type: 'quest',
    autoUnlockRegions: ['Fremennik', 'Kandarin', 'Desert'],
    regions: [],
    prereqs: ['314', '424'],
    skillReqs: [
      { skill: 'Crafting', level: 25 },
      { skill: 'Smithing', level: 30 },
      { skill: 'Herblore', level: 18 },
      { skill: 'Agility', level: 36 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/One_Small_Favour',
  },
  {
    id: '398',
    label: 'Mountain Daughter',
    points: 2,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.FREMENNIK,
    type: 'quest',
    autoUnlockRegions: ['Fremennik'],
    regions: [],
    prereqs: [],
    skillReqs: [{ skill: 'Agility', level: 20 }],
    wiki: 'https://oldschool.runescape.wiki/w/Mountain_Daughter',
  },
  {
    id: '333',
    label: 'Between a Rock...',
    points: 2,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.RED_AXE,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Asgarnia', 'Fremennik', 'Kandarin'],
    prereqs: ['351', '364'],
    skillReqs: [
      { skill: 'Defence', level: 30 },
      { skill: 'Smithing', level: 50 },
      { skill: 'Mining', level: 40 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Between_a_Rock...',
  },
  {
    id: '362',
    label: 'The Feud',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: ['Asgarnia', 'Desert', 'Fremennik'],
    regions: [],
    prereqs: [],
    skillReqs: [{ skill: 'Thieving', level: 30 }],
    wiki: 'https://oldschool.runescape.wiki/w/The_Feud',
  },
  {
    id: '373',
    label: 'The Golem',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Desert'],
    prereqs: [],
    skillReqs: [
      { skill: 'Crafting', level: 20 },
      { skill: 'Thieving', level: 25 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/The_Golem',
  },
  {
    id: '345',
    label: 'Desert Treasure I',
    points: 3,
    difficulty: QUEST_DIFFICULTY.MASTER,
    length: QUEST_LENGTH.VERY_LONG,
    series: QUEST_SERIES.MAHJARRAT,
    type: 'quest',
    autoUnlockRegions: ['Desert'],
    regions: [],
    prereqs: ['408', '347', '436', '3154', '434', '441'],
    skillReqs: [
      { skill: 'Magic', level: 50 },
      { skill: 'Firemaking', level: 50 },
      { skill: 'Thieving', level: 53 },
      { skill: 'Slayer', level: 10 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Desert_Treasure_I',
  },
  {
    id: '383',
    label: "Icthlarin's Little Helper",
    points: 2,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.DESERT,
    type: 'quest',
    autoUnlockRegions: ['Fremennik'],
    regions: ['Desert'],
    prereqs: ['370'],
    skillReqs: [],
    wiki: "https://oldschool.runescape.wiki/w/Icthlarin's_Little_Helper",
  },
  {
    id: '433',
    label: 'Tears of Guthix',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: ['Misthalin'],
    regions: [],
    prereqs: [],
    skillReqs: [
      { skill: 'Firemaking', level: 49 },
      { skill: 'Crafting', level: 20 },
      { skill: 'Mining', level: 20 },
      { skill: 'QP', level: 43 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Tears_of_Guthix',
  },
  {
    id: '449',
    label: 'Zogre Flesh Eaters',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.OGRE,
    type: 'quest',
    autoUnlockRegions: ['Morytania'],
    regions: ['Kandarin'],
    prereqs: ['386', '334'],
    skillReqs: [
      { skill: 'Strength', level: 10 },
      { skill: 'Ranged', level: 30 },
      { skill: 'Fletching', level: 30 },
      { skill: 'Smithing', level: 4 },
      { skill: 'Herblore', level: 8 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Zogre_Flesh_Eaters',
  },
  {
    id: '390',
    label: 'The Lost Tribe',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.DORGESHUUN,
    type: 'quest',
    autoUnlockRegions: ['Desert', 'Fremennik'],
    regions: ['Asgarnia'],
    prereqs: ['306', '314'],
    skillReqs: [
      { skill: 'Mining', level: 17 },
      { skill: 'Agility', level: 13 },
      { skill: 'Thieving', level: 13 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/The_Lost_Tribe',
  },
  {
    id: '372',
    label: 'The Giant Dwarf',
    points: 2,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.RED_AXE,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Asgarnia', 'Fremennik'],
    prereqs: [],
    skillReqs: [
      { skill: 'Magic', level: 33 },
      { skill: 'Firemaking', level: 16 },
      { skill: 'Crafting', level: 12 },
      { skill: 'Thieving', level: 14 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/The_Giant_Dwarf',
  },
  {
    id: '414',
    label: 'Recruitment Drive',
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.TEMPLE_KNIGHT,
    type: 'quest',
    autoUnlockRegions: ['Desert', 'Fremennik'],
    regions: ['Asgarnia'],
    prereqs: ['299', '350'],
    skillReqs: [{ skill: 'QP', level: 12 }],
    wiki: 'https://oldschool.runescape.wiki/w/Recruitment_Drive',
  },
  {
    id: '3147',
    label: "Mourning's End Part I",
    points: 2,
    difficulty: QUEST_DIFFICULTY.MASTER,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.ELF,
    type: 'quest',
    autoUnlockRegions: ['Tirannwn'],
    regions: [],
    prereqs: ['423', '416'],
    skillReqs: [
      { skill: 'Ranged', level: 60 },
      { skill: 'Thieving', level: 50 },
    ],
    wiki: "https://oldschool.runescape.wiki/w/Mourning's_End_Part_I",
  },
  {
    id: '365',
    label: 'Forgettable Tale...',
    points: 2,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.RED_AXE,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Asgarnia', 'Fremennik', 'Kandarin'],
    prereqs: ['364', '372'],
    skillReqs: [
      { skill: 'Cooking', level: 22 },
      { skill: 'Farming', level: 17 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Forgettable_Tale...',
  },
  {
    id: '369',
    label: 'Garden of Tranquillity',
    points: 2,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.VERY_LONG,
    type: 'quest',
    autoUnlockRegions: ['Desert', 'Fremennik', 'Kandarin'],
    regions: [],
    prereqs: ['340'],
    skillReqs: [{ skill: 'Farming', level: 25 }],
    wiki: 'https://oldschool.runescape.wiki/w/Garden_of_Tranquillity',
  },
  {
    id: '430',
    label: 'A Tail of Two Cats',
    points: 2,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.GUILDS,
    type: 'quest',
    autoUnlockRegions: ['Fremennik'],
    regions: ['Asgarnia', 'Desert'],
    prereqs: ['383'],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/A_Tail_of_Two_Cats',
  },
  {
    id: '444',
    label: 'Wanted!',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.TEMPLE_KNIGHT,
    type: 'quest',
    autoUnlockRegions: ['Desert', 'Fremennik'],
    regions: ['Asgarnia', 'Morytania', 'Wilderness'],
    prereqs: ['408', '414', '390', '3140'],
    skillReqs: [{ skill: 'QP', level: 32 }],
    wiki: 'https://oldschool.runescape.wiki/w/Wanted!',
  },
  {
    id: '3148',
    label: "Mourning's End Part II",
    points: 2,
    difficulty: QUEST_DIFFICULTY.MASTER,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.ELF,
    type: 'quest',
    autoUnlockRegions: ['Tirannwn'],
    regions: [],
    prereqs: ['334', '3147'],
    skillReqs: [],
    wiki: "https://oldschool.runescape.wiki/w/Mourning's_End_Part_II",
  },
  {
    id: '418',
    label: 'Rum Deal',
    points: 2,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.PIRATE,
    type: 'quest',
    autoUnlockRegions: ['Morytania'],
    regions: [],
    prereqs: ['408', '449'],
    skillReqs: [
      { skill: 'Prayer', level: 47 },
      { skill: 'Fishing', level: 50 },
      { skill: 'Crafting', level: 42 },
      { skill: 'Slayer', level: 42 },
      { skill: 'Farming', level: 40 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Rum_Deal',
  },
  {
    id: '422',
    label: 'Shadow of the Storm',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Desert'],
    prereqs: ['302', '373'],
    skillReqs: [{ skill: 'Crafting', level: 30 }],
    wiki: 'https://oldschool.runescape.wiki/w/Shadow_of_the_Storm',
  },
  {
    id: '393',
    label: 'Making History',
    points: 3,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: ['Tirannwn'],
    regions: ['Fremennik', 'Kandarin', 'Morytania'],
    prereqs: ['408', '312'],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Making_History',
  },
  {
    id: '412',
    label: 'Ratcatchers',
    points: 2,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: [],
    regions: [REGION_IMPOSSIBLE_TO_COMPLETE],
    prereqs: ['383'],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Ratcatchers',
  },
  {
    id: '427',
    label: 'Spirits of the Elid',
    points: 2,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.DESERT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Desert'],
    prereqs: [],
    skillReqs: [
      { skill: 'Ranged', level: 37 },
      { skill: 'Magic', level: 33 },
      { skill: 'Mining', level: 37 },
      { skill: 'Thieving', level: 37 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Spirits_of_the_Elid',
  },
  {
    id: '346',
    label: 'Devious Minds',
    points: 1,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.MAHJARRAT,
    type: 'quest',
    autoUnlockRegions: ['Desert', 'Fremennik'],
    regions: ['Asgarnia', 'Morytania', 'Wilderness'],
    prereqs: ['3138', '444', '441', '3140'],
    skillReqs: [
      { skill: 'Fletching', level: 50 },
      { skill: 'Smithing', level: 65 },
      { skill: 'Runecraft', level: 50 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Devious_Minds',
  },
  {
    id: '377',
    label: 'The Hand in the Sand',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Asgarnia', 'Kandarin'],
    prereqs: [],
    skillReqs: [
      { skill: 'Crafting', level: 49 },
      { skill: 'Thieving', level: 17 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/The_Hand_in_the_Sand',
  },
  {
    id: '356',
    label: "Enakhra's Lament",
    points: 2,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.MAHJARRAT,
    type: 'quest',
    autoUnlockRegions: ['Desert'],
    regions: [],
    prereqs: [],
    skillReqs: [
      { skill: 'Prayer', level: 43 },
      { skill: 'Magic', level: 39 },
      { skill: 'Firemaking', level: 45 },
      { skill: 'Crafting', level: 50 },
    ],
    wiki: "https://oldschool.runescape.wiki/w/Enakhra's_Lament",
  },
  {
    id: '336',
    label: 'Cabin Fever',
    points: 2,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.PIRATE,
    type: 'quest',
    autoUnlockRegions: ['Morytania'],
    regions: [],
    prereqs: ['310', '312', '418'],
    skillReqs: [
      { skill: 'Ranged', level: 40 },
      { skill: 'Crafting', level: 45 },
      { skill: 'Smithing', level: 50 },
      { skill: 'Agility', level: 42 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Cabin_Fever',
  },
  {
    id: '359',
    label: 'Fairytale I - Growing Pains',
    points: 2,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.FAIRY,
    type: 'quest',
    autoUnlockRegions: ['Misthalin'],
    regions: [],
    prereqs: ['403', '389'],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Fairytale_I_-_Growing_Pains',
  },
  {
    id: '413',
    label: 'Recipe for Disaster',
    points: 10,
    difficulty: QUEST_DIFFICULTY.GRANDMASTER,
    length: QUEST_LENGTH.VERY_LONG,
    type: 'quest',
    autoUnlockRegions: [],
    regions: [REGION_IMPOSSIBLE_TO_COMPLETE],
    prereqs: [
      '335',
      '300',
      '302',
      '364',
      '370',
      '306',
      '334',
      '403',
      '422',
      '3142',
      '382',
      '389',
      '424',
      '442',
      '345',
      '3145',
      '395',
    ],
    skillReqs: [
      { skill: 'Ranged', level: 40 },
      { skill: 'Magic', level: 59 },
      { skill: 'Cooking', level: 70 },
      { skill: 'Woodcutting', level: 36 },
      { skill: 'Fletching', level: 10 },
      { skill: 'Fishing', level: 53 },
      { skill: 'Firemaking', level: 50 },
      { skill: 'Crafting', level: 40 },
      { skill: 'Smithing', level: 40 },
      { skill: 'Mining', level: 50 },
      { skill: 'Herblore', level: 25 },
      { skill: 'Agility', level: 48 },
      { skill: 'Thieving', level: 53 },
      { skill: 'Slayer', level: 10 },
      { skill: 'QP', level: 175 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Recipe_for_Disaster',
  },
  {
    id: '413.0',
    label: "Recipe for Disaster/Another Cook's Quest",
    points: 1,
    difficulty: QUEST_DIFFICULTY.GRANDMASTER,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: ['Kandarin', 'Morytania'],
    regions: [],
    prereqs: ['300'],
    skillReqs: [{ skill: 'Cooking', level: 10 }],
    wiki: "https://oldschool.runescape.wiki/w/Recipe_for_Disaster/Another_Cook's_Quest",
  },
  {
    id: '413.1',
    label: 'Recipe for Disaster/Defeating the Culinaromancer',
    points: 1,
    difficulty: QUEST_DIFFICULTY.GRANDMASTER,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: [REGION_IMPOSSIBLE_TO_COMPLETE],
    prereqs: [],
    skillReqs: [{ skill: 'QP', level: 175 }],
    wiki: 'https://oldschool.runescape.wiki/w/Recipe_for_Disaster/Defeating_the_Culinaromancer',
  },
  {
    id: '413.2',
    label: 'Recipe for Disaster/Freeing the Mountain Dwarf',
    points: 1,
    difficulty: QUEST_DIFFICULTY.GRANDMASTER,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Asgarnia', 'Kandarin'],
    prereqs: ['364'],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Recipe_for_Disaster/Freeing_the_Mountain_Dwarf',
  },
  {
    id: '413.3',
    label: 'Recipe for Disaster/Freeing the Goblin generals',
    points: 1,
    difficulty: QUEST_DIFFICULTY.GRANDMASTER,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Asgarnia', ['Kandarin', 'Morytania']],
    prereqs: ['306'],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Recipe_for_Disaster/Freeing_the_Goblin_generals',
  },
  {
    id: '413.4',
    label: 'Recipe for Disaster/Freeing Pirate Pete',
    points: 1,
    difficulty: QUEST_DIFFICULTY.GRANDMASTER,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: ['Morytania'],
    regions: ['Kandarin'],
    prereqs: [],
    skillReqs: [{ skill: 'Cooking', level: 31 }],
    wiki: 'https://oldschool.runescape.wiki/w/Recipe_for_Disaster/Freeing_Pirate_Pete',
  },
  {
    id: '413.5',
    label: 'Recipe for Disaster/Freeing the Lumbridge Guide',
    points: 1,
    difficulty: QUEST_DIFFICULTY.GRANDMASTER,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Asgarnia', 'Kandarin'],
    prereqs: ['335', '302', '334', '403'],
    skillReqs: [{ skill: 'Cooking', level: 40 }],
    wiki: 'https://oldschool.runescape.wiki/w/Recipe_for_Disaster/Freeing_the_Lumbridge_Guide',
  },
  {
    id: '413.6',
    label: 'Recipe for Disaster/Freeing Evil Dave',
    points: 1,
    difficulty: QUEST_DIFFICULTY.GRANDMASTER,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Desert', ['Kandarin', 'Morytania']],
    prereqs: ['370', '422'],
    skillReqs: [{ skill: 'Cooking', level: 25 }],
    wiki: 'https://oldschool.runescape.wiki/w/Recipe_for_Disaster/Freeing_Evil_Dave',
  },
  {
    id: '413.7',
    label: 'Recipe for Disaster/Freeing King Awowogei',
    points: 1,
    difficulty: QUEST_DIFFICULTY.GRANDMASTER,
    length: QUEST_LENGTH.LONG,
    type: 'quest',
    autoUnlockRegions: ['Kandarin'],
    regions: [],
    prereqs: ['395'],
    skillReqs: [
      { skill: 'Cooking', level: 70 },
      { skill: 'Agility', level: 48 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Recipe_for_Disaster/Freeing_King_Awowogei',
  },
  {
    id: '413.8',
    label: 'Recipe for Disaster/Freeing Sir Amik Varze',
    points: 1,
    difficulty: QUEST_DIFFICULTY.GRANDMASTER,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Asgarnia', 'Fremennik', ['Kandarin', 'Morytania']],
    prereqs: ['3142', '389', '424', '442', '3145'],
    skillReqs: [{ skill: 'QP', level: 107 }],
    wiki: 'https://oldschool.runescape.wiki/w/Recipe_for_Disaster/Freeing_Sir_Amik_Varze',
  },
  {
    id: '413.9',
    label: 'Recipe for Disaster/Freeing Skrach Uglogwee',
    points: 1,
    difficulty: QUEST_DIFFICULTY.GRANDMASTER,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Kandarin'],
    prereqs: ['334'],
    skillReqs: [
      { skill: 'Cooking', level: 41 },
      { skill: 'Firemaking', level: 20 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Recipe_for_Disaster/Freeing_Skrach_Uglogwee',
  },
  {
    id: '384',
    label: 'In Aid of the Myreque',
    points: 2,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.MYREQUE,
    type: 'quest',
    autoUnlockRegions: ['Misthalin'],
    regions: [],
    prereqs: ['385'],
    skillReqs: [
      { skill: 'Magic', level: 7 },
      { skill: 'Crafting', level: 25 },
      { skill: 'Mining', level: 15 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/In_Aid_of_the_Myreque',
  },
  {
    id: '426',
    label: "A Soul's Bane",
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Misthalin'],
    prereqs: [],
    skillReqs: [],
    wiki: "https://oldschool.runescape.wiki/w/A_Soul's_Bane",
  },
  {
    id: '3152',
    label: 'Rag and Bone Man I',
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Misthalin', 'Karamja'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Rag_and_Bone_Man_I',
  },
  {
    id: '411',
    label: 'Rag and Bone Man II',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.LONG,
    type: 'quest',
    autoUnlockRegions: [],
    regions: [REGION_IMPOSSIBLE_TO_COMPLETE],
    prereqs: ['3152', '340', '368', '449', '382', '3153'],
    skillReqs: [{ skill: 'Slayer', level: 40 }],
    wiki: 'https://oldschool.runescape.wiki/w/Rag_and_Bone_Man_II',
  },
  {
    id: '428',
    label: 'Swan Song',
    points: 2,
    difficulty: QUEST_DIFFICULTY.MASTER,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: ['Desert', 'Fremennik', 'Kandarin'],
    regions: [],
    prereqs: ['369', '406'],
    skillReqs: [
      { skill: 'Magic', level: 66 },
      { skill: 'Cooking', level: 62 },
      { skill: 'Fishing', level: 62 },
      { skill: 'Firemaking', level: 42 },
      { skill: 'Crafting', level: 40 },
      { skill: 'Smithing', level: 45 },
      { skill: 'QP', level: 100 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Swan_Song',
  },
  {
    id: '417',
    label: 'Royal Trouble',
    points: 1,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.FREMENNIK,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Fremennik'],
    prereqs: ['435'],
    skillReqs: [
      { skill: 'Agility', level: 40 },
      { skill: 'Slayer', level: 40 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Royal_Trouble',
  },
  {
    id: '343',
    label: 'Death to the Dorgeshuun',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.DORGESHUUN,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Misthalin', ['Asgarnia', 'Fremennik', 'Desert']],
    prereqs: ['390'],
    skillReqs: [
      { skill: 'Agility', level: 23 },
      { skill: 'Thieving', level: 23 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Death_to_the_Dorgeshuun',
  },
  {
    id: '360',
    label: 'Fairytale II - Cure a Queen',
    points: 2,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.FAIRY,
    type: 'quest',
    autoUnlockRegions: ['Misthalin'],
    regions: [],
    prereqs: ['350', '359'],
    skillReqs: [
      { skill: 'Herblore', level: 57 },
      { skill: 'Thieving', level: 40 },
      { skill: 'Farming', level: 49 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Fairytale_II_-_Cure_a_Queen',
  },
  {
    id: '391',
    label: 'Lunar Diplomacy',
    points: 2,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.FREMENNIK,
    type: 'quest',
    autoUnlockRegions: ['Fremennik'],
    regions: [],
    prereqs: ['314', '368', '389', '424'],
    skillReqs: [
      { skill: 'Defence', level: 40 },
      { skill: 'Magic', level: 65 },
      { skill: 'Woodcutting', level: 55 },
      { skill: 'Firemaking', level: 49 },
      { skill: 'Crafting', level: 61 },
      { skill: 'Mining', level: 60 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Lunar_Diplomacy',
  },
  {
    id: '358',
    label: 'The Eyes of Glouphrie',
    points: 2,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.GNOME,
    type: 'quest',
    autoUnlockRegions: ['Kandarin'],
    regions: [],
    prereqs: ['374'],
    skillReqs: [
      { skill: 'Magic', level: 46 },
      { skill: 'Construction', level: 5 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/The_Eyes_of_Glouphrie',
  },
  {
    id: '341',
    label: 'Darkness of Hallowvale',
    points: 2,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.VERY_LONG,
    series: QUEST_SERIES.MYREQUE,
    type: 'quest',
    autoUnlockRegions: ['Morytania'],
    regions: [],
    prereqs: ['384'],
    skillReqs: [
      { skill: 'Strength', level: 40 },
      { skill: 'Magic', level: 33 },
      { skill: 'Crafting', level: 32 },
      { skill: 'Mining', level: 20 },
      { skill: 'Agility', level: 26 },
      { skill: 'Thieving', level: 22 },
      { skill: 'Construction', level: 5 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Darkness_of_Hallowvale',
  },
  {
    id: '425',
    label: 'The Slug Menace',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.TEMPLE_KNIGHT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Asgarnia', 'Kandarin', 'Desert'],
    prereqs: ['420', '444'],
    skillReqs: [
      { skill: 'Crafting', level: 30 },
      { skill: 'Thieving', level: 30 },
      { skill: 'Slayer', level: 30 },
      { skill: 'Runecraft', level: 30 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/The_Slug_Menace',
  },
  {
    id: '355',
    label: 'Elemental Workshop II',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.ELEMENTAL,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Kandarin'],
    prereqs: ['354'],
    skillReqs: [
      { skill: 'Magic', level: 20 },
      { skill: 'Smithing', level: 30 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Elemental_Workshop_II',
  },
  {
    id: '402',
    label: "My Arm's Big Adventure",
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.TROLL,
    type: 'quest',
    autoUnlockRegions: ['Asgarnia', 'Desert', 'Fremennik'],
    regions: [],
    prereqs: ['386', '362', '352'],
    skillReqs: [
      { skill: 'Woodcutting', level: 10 },
      { skill: 'Farming', level: 29 },
    ],
    wiki: "https://oldschool.runescape.wiki/w/My_Arm's_Big_Adventure",
  },
  {
    id: '357',
    label: 'Enlightened Journey',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: ['Kandarin'],
    regions: ['Asgarnia'],
    prereqs: [],
    skillReqs: [
      { skill: 'Firemaking', level: 20 },
      { skill: 'Crafting', level: 36 },
      { skill: 'Farming', level: 30 },
      { skill: 'QP', level: 20 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Enlightened_Journey',
  },
  {
    id: '353',
    label: "Eagles' Peak",
    points: 2,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Kandarin'],
    prereqs: [],
    skillReqs: [{ skill: 'Hunter', level: 27 }],
    wiki: "https://oldschool.runescape.wiki/w/Eagles'_Peak",
  },
  {
    id: '331',
    label: 'Animal Magnetism',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: ['Fremennik'],
    regions: ['Asgarnia', 'Morytania'],
    prereqs: ['305', '408', '312'],
    skillReqs: [
      { skill: 'Ranged', level: 30 },
      { skill: 'Woodcutting', level: 35 },
      { skill: 'Crafting', level: 19 },
      { skill: 'Slayer', level: 18 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Animal_Magnetism',
  },
  {
    id: '339',
    label: 'Contact!',
    points: 1,
    difficulty: QUEST_DIFFICULTY.MASTER,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.DESERT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Desert'],
    prereqs: ['311', '383'],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Contact!',
  },
  {
    id: '338',
    label: 'Cold War',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.LONG,
    type: 'quest',
    autoUnlockRegions: ['Desert', 'Fremennik'],
    regions: [],
    prereqs: [],
    skillReqs: [
      { skill: 'Crafting', level: 30 },
      { skill: 'Agility', level: 30 },
      { skill: 'Thieving', level: 15 },
      { skill: 'Hunter', level: 10 },
      { skill: 'Construction', level: 34 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Cold_War',
  },
  {
    id: '367',
    label: 'The Fremennik Isles',
    points: 1,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.FREMENNIK,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Fremennik'],
    prereqs: ['368'],
    skillReqs: [
      { skill: 'Agility', level: 40 },
      { skill: 'Construction', level: 20 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/The_Fremennik_Isles',
  },
  {
    id: '437',
    label: 'Tower of Life',
    points: 2,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Kandarin'],
    prereqs: [],
    skillReqs: [{ skill: 'Construction', level: 10 }],
    wiki: 'https://oldschool.runescape.wiki/w/Tower_of_Life',
  },
  {
    id: '375',
    label: 'The Great Brain Robbery',
    points: 2,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.PIRATE,
    type: 'quest',
    autoUnlockRegions: ['Morytania'],
    regions: [],
    prereqs: ['340', '336'],
    skillReqs: [
      { skill: 'Prayer', level: 50 },
      { skill: 'Crafting', level: 16 },
      { skill: 'Construction', level: 30 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/The_Great_Brain_Robbery',
  },
  {
    id: '447',
    label: 'What Lies Below',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Misthalin', ['Wilderness', 'Desert']],
    prereqs: ['314'],
    skillReqs: [{ skill: 'Runecraft', level: 35 }],
    wiki: 'https://oldschool.runescape.wiki/w/What_Lies_Below',
  },
  {
    id: '3150',
    label: "Olaf's Quest",
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.FREMENNIK,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Fremennik'],
    prereqs: ['368'],
    skillReqs: [
      { skill: 'Woodcutting', level: 50 },
      { skill: 'Firemaking', level: 40 },
    ],
    wiki: "https://oldschool.runescape.wiki/w/Olaf's_Quest",
  },
  {
    id: '332',
    label: 'Another Slice of H.A.M.',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.DORGESHUUN,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Asgarnia', 'Fremennik'],
    prereqs: ['343', '347', '372'],
    skillReqs: [
      { skill: 'Attack', level: 15 },
      { skill: 'Prayer', level: 25 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Another_Slice_of_H.A.M.',
  },
  {
    id: '349',
    label: 'Dream Mentor',
    points: 2,
    difficulty: QUEST_DIFFICULTY.MASTER,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.FREMENNIK,
    type: 'quest',
    autoUnlockRegions: ['Fremennik'],
    regions: [],
    prereqs: ['352', '391'],
    skillReqs: [{ skill: 'Combat', level: 85 }],
    wiki: 'https://oldschool.runescape.wiki/w/Dream_Mentor',
  },
  {
    id: '376',
    label: 'Grim Tales',
    points: 1,
    difficulty: QUEST_DIFFICULTY.MASTER,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Asgarnia'],
    prereqs: ['350', '448'],
    skillReqs: [
      { skill: 'Woodcutting', level: 71 },
      { skill: 'Herblore', level: 52 },
      { skill: 'Agility', level: 59 },
      { skill: 'Thieving', level: 58 },
      { skill: 'Farming', level: 45 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Grim_Tales',
  },
  {
    id: '387',
    label: "King's Ransom",
    points: 1,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.CAMELOT,
    type: 'quest',
    autoUnlockRegions: ['Kandarin'],
    regions: [],
    prereqs: ['299', '381', '406'],
    skillReqs: [
      { skill: 'Defence', level: 65 },
      { skill: 'Magic', level: 45 },
    ],
    wiki: "https://oldschool.runescape.wiki/w/King's_Ransom",
  },
  {
    id: '396',
    label: 'Monkey Madness II',
    points: 4,
    difficulty: QUEST_DIFFICULTY.GRANDMASTER,
    length: QUEST_LENGTH.VERY_LONG,
    series: QUEST_SERIES.GNOME,
    type: 'quest',
    autoUnlockRegions: ['Kandarin'],
    regions: [],
    prereqs: ['357', '358', '445', '441', '395'],
    skillReqs: [
      { skill: 'Firemaking', level: 60 },
      { skill: 'Agility', level: 55 },
      { skill: 'Thieving', level: 55 },
      { skill: 'Slayer', level: 69 },
      { skill: 'Runecraft', level: 70 },
      { skill: 'Hunter', level: 60 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Monkey_Madness_II',
  },
  {
    id: '309',
    label: 'Misthalin Mystery',
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Misthalin'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Misthalin_Mystery',
  },
  {
    id: '3136',
    label: 'Client of Kourend',
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.KOUREND,
    type: 'quest',
    autoUnlockRegions: ['Fremennik'],
    regions: ['Kourend'],
    prereqs: ['3155'],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Client_of_Kourend',
  },
  {
    id: '3135',
    label: 'Bone Voyage',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: ['Misthalin'],
    regions: [],
    prereqs: ['347'],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Bone_Voyage',
  },
  {
    id: '409',
    label: 'The Queen of Thieves',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.KOUREND,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Kourend'],
    prereqs: ['3136'],
    skillReqs: [{ skill: 'Thieving', level: 20 }],
    wiki: 'https://oldschool.runescape.wiki/w/The_Queen_of_Thieves',
  },
  {
    id: '344',
    label: 'The Depths of Despair',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.KOUREND,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Kourend'],
    prereqs: ['3136'],
    skillReqs: [{ skill: 'Agility', level: 18 }],
    wiki: 'https://oldschool.runescape.wiki/w/The_Depths_of_Despair',
  },
  {
    id: '301',
    label: 'The Corsair Curse',
    points: 2,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Asgarnia', 'Kandarin'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/The_Corsair_Curse',
  },
  {
    id: '348',
    label: 'Dragon Slayer II',
    points: 5,
    difficulty: QUEST_DIFFICULTY.GRANDMASTER,
    length: QUEST_LENGTH.VERY_LONG,
    series: QUEST_SERIES.GUILDS,
    type: 'quest',
    autoUnlockRegions: ['Fremennik'],
    regions: [],
    prereqs: ['3136', '331', '3135', '371', '430', '349', '3145'],
    skillReqs: [
      { skill: 'Hitpoints', level: 50 },
      { skill: 'Magic', level: 75 },
      { skill: 'Crafting', level: 62 },
      { skill: 'Smithing', level: 70 },
      { skill: 'Mining', level: 68 },
      { skill: 'Agility', level: 60 },
      { skill: 'Thieving', level: 60 },
      { skill: 'Construction', level: 50 },
      { skill: 'QP', level: 200 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Dragon_Slayer_II',
  },
  {
    id: '431',
    label: 'Tale of the Righteous',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.KOUREND,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Kourend'],
    prereqs: ['3136'],
    skillReqs: [
      { skill: 'Strength', level: 16 },
      { skill: 'Mining', level: 10 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Tale_of_the_Righteous',
  },
  {
    id: '432',
    label: 'A Taste of Hope',
    points: 1,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.MYREQUE,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Morytania'],
    prereqs: ['350', '341'],
    skillReqs: [
      { skill: 'Attack', level: 40 },
      { skill: 'Crafting', level: 48 },
      { skill: 'Herblore', level: 40 },
      { skill: 'Agility', level: 45 },
      { skill: 'Slayer', level: 38 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/A_Taste_of_Hope',
  },
  {
    id: '392',
    label: 'Making Friends with My Arm',
    points: 2,
    difficulty: QUEST_DIFFICULTY.MASTER,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.TROLL,
    type: 'quest',
    autoUnlockRegions: ['Desert', 'Fremennik'],
    regions: [],
    prereqs: ['313', '338', '402', '428'],
    skillReqs: [
      { skill: 'Firemaking', level: 66 },
      { skill: 'Mining', level: 72 },
      { skill: 'Agility', level: 68 },
      { skill: 'Construction', level: 35 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Making_Friends_with_My_Arm',
  },
  {
    id: '543',
    label: 'The Forsaken Tower',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.KOUREND,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Kourend'],
    prereqs: ['3136'],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/The_Forsaken_Tower',
  },
  {
    id: '542',
    label: 'The Ascent of Arceuus',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.KOUREND,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Kourend'],
    prereqs: ['3136'],
    skillReqs: [{ skill: 'Hunter', level: 12 }],
    wiki: 'https://oldschool.runescape.wiki/w/The_Ascent_of_Arceuus',
  },
  {
    id: '3155',
    label: 'X Marks the Spot',
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.KOUREND,
    type: 'quest',
    autoUnlockRegions: ['Fremennik', 'Kourend'],
    regions: ['Misthalin', 'Asgarnia'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/X_Marks_the_Spot',
  },
  {
    id: '603',
    label: 'Song of the Elves',
    points: 4,
    difficulty: QUEST_DIFFICULTY.GRANDMASTER,
    length: QUEST_LENGTH.VERY_LONG,
    series: QUEST_SERIES.ELF,
    type: 'quest',
    autoUnlockRegions: ['Tirannwn'],
    regions: [],
    prereqs: ['350', '393', '3148'],
    skillReqs: [
      { skill: 'Woodcutting', level: 70 },
      { skill: 'Smithing', level: 70 },
      { skill: 'Mining', level: 70 },
      { skill: 'Herblore', level: 70 },
      { skill: 'Agility', level: 70 },
      { skill: 'Farming', level: 70 },
      { skill: 'Hunter', level: 70 },
      { skill: 'Construction', level: 70 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Song_of_the_Elves',
  },
  {
    id: '3141',
    label: 'The Fremennik Exiles',
    points: 2,
    difficulty: QUEST_DIFFICULTY.MASTER,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.FREMENNIK,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Fremennik'],
    prereqs: ['368', '398', '367', '3142', '391'],
    skillReqs: [
      { skill: 'Fishing', level: 60 },
      { skill: 'Crafting', level: 65 },
      { skill: 'Smithing', level: 60 },
      { skill: 'Slayer', level: 60 },
      { skill: 'Runecraft', level: 55 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/The_Fremennik_Exiles',
  },
  {
    id: '1276',
    label: 'Sins of the Father',
    points: 2,
    difficulty: QUEST_DIFFICULTY.MASTER,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.MYREQUE,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Morytania'],
    prereqs: ['1278', '432'],
    skillReqs: [
      { skill: 'Attack', level: 50 },
      { skill: 'Magic', level: 49 },
      { skill: 'Woodcutting', level: 62 },
      { skill: 'Fletching', level: 60 },
      { skill: 'Crafting', level: 56 },
      { skill: 'Agility', level: 52 },
      { skill: 'Slayer', level: 50 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Sins_of_the_Father',
  },
  {
    id: '3151',
    label: 'A Porcine of Interest',
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Misthalin', 'Asgarnia'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/A_Porcine_of_Interest',
  },
  {
    id: '752',
    label: 'Getting Ahead',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.KOUREND,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Kourend'],
    prereqs: [],
    skillReqs: [
      { skill: 'Crafting', level: 30 },
      { skill: 'Construction', level: 26 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Getting_Ahead',
  },
  {
    id: '2874',
    label: 'Below Ice Mountain',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: ['Desert'],
    regions: ['Asgarnia'],
    prereqs: [],
    skillReqs: [{ skill: 'QP', level: 16 }],
    wiki: 'https://oldschool.runescape.wiki/w/Below_Ice_Mountain',
  },
  {
    id: '949',
    label: 'A Night at the Theatre',
    points: 2,
    difficulty: QUEST_DIFFICULTY.MASTER,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.MYREQUE,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Morytania'],
    prereqs: ['432'],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/A_Night_at_the_Theatre',
  },
  {
    id: '2971',
    label: 'A Kingdom Divided',
    points: 2,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.KOUREND,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Kourend'],
    prereqs: ['350', '543', '542', '344', '409', '3136', '320'],
    skillReqs: [
      { skill: 'Magic', level: 35 },
      { skill: 'Woodcutting', level: 52 },
      { skill: 'Crafting', level: 38 },
      { skill: 'Mining', level: 42 },
      { skill: 'Herblore', level: 50 },
      { skill: 'Agility', level: 54 },
      { skill: 'Thieving', level: 52 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/A_Kingdom_Divided',
  },
  {
    id: '322',
    label: "Alfred Grimhand's Barcrawl",
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'miniquest',
    autoUnlockRegions: ['Fremennik'],
    regions: ['Misthalin', 'Karamja', 'Asgarnia', 'Kandarin'],
    prereqs: [],
    skillReqs: [],
    wiki: "https://oldschool.runescape.wiki/w/Alfred_Grimhand's_Barcrawl",
  },
  {
    id: '3146',
    label: 'The Mage Arena',
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.MEDIUM,
    type: 'miniquest',
    autoUnlockRegions: [],
    regions: ['Wilderness'],
    prereqs: [],
    skillReqs: [{ skill: 'Magic', level: 60 }],
    wiki: 'https://oldschool.runescape.wiki/w/The_Mage_Arena',
  },
  {
    id: '3140',
    label: 'Enter the Abyss',
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    type: 'miniquest',
    autoUnlockRegions: ['Fremennik', 'Desert', 'Wilderness'],
    regions: [],
    prereqs: ['314'],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Enter_the_Abyss',
  },
  {
    id: '3137',
    label: 'Curse of the Empty Lord',
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.MAHJARRAT,
    type: 'miniquest',
    autoUnlockRegions: ['Desert', 'Fremennik'],
    regions: [],
    prereqs: ['312'],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Curse_of_the_Empty_Lord',
  },
  {
    id: '3153',
    label: 'Skippy and the Mogres',
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.VERY_SHORT,
    type: 'miniquest',
    autoUnlockRegions: [],
    regions: ['Asgarnia'],
    prereqs: [],
    skillReqs: [{ skill: 'Cooking', level: 20 }],
    wiki: 'https://oldschool.runescape.wiki/w/Skippy_and_the_Mogres',
  },
  {
    id: '324',
    label: 'The Enchanted Key',
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.LONG,
    type: 'miniquest',
    autoUnlockRegions: [],
    regions: [REGION_IMPOSSIBLE_TO_COMPLETE],
    prereqs: ['393'],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/The_Enchanted_Key',
  },
  {
    id: '3144',
    label: 'Lair of Tarn Razorlor',
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.SHORT,
    type: 'miniquest',
    autoUnlockRegions: [],
    regions: ['Morytania'],
    prereqs: ['378'],
    skillReqs: [{ skill: 'Slayer', level: 40 }],
    wiki: 'https://oldschool.runescape.wiki/w/Lair_of_Tarn_Razorlor',
  },
  {
    id: '325',
    label: "The General's Shadow",
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.MAHJARRAT,
    type: 'miniquest',
    autoUnlockRegions: ['Desert', 'Fremennik'],
    regions: [],
    prereqs: ['363', '3137'],
    skillReqs: [],
    wiki: "https://oldschool.runescape.wiki/w/The_General's_Shadow",
  },
  {
    id: '320',
    label: 'Architectural Alliance',
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.VERY_LONG,
    series: QUEST_SERIES.KOUREND,
    type: 'miniquest',
    autoUnlockRegions: [],
    regions: ['Kourend'],
    prereqs: [],
    skillReqs: [
      { skill: 'Crafting', level: 30 },
      { skill: 'Mining', level: 42 },
      { skill: 'Slayer', level: 10 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Architectural_Alliance',
  },
  {
    id: '1275',
    label: 'Bear Your Soul',
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.KOUREND,
    type: 'miniquest',
    autoUnlockRegions: [],
    regions: ['Kourend', 'Asgarnia'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Bear_Your_Soul',
  },
  {
    id: '329',
    label: 'Family Pest',
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.VERY_SHORT,
    type: 'miniquest',
    autoUnlockRegions: [],
    regions: ['Misthalin', 'Desert', 'Kandarin', 'Fremennik'],
    prereqs: ['361'],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Family_Pest',
  },
  {
    id: '330',
    label: 'The Mage Arena II',
    difficulty: QUEST_DIFFICULTY.MASTER,
    length: QUEST_LENGTH.LONG,
    type: 'miniquest',
    autoUnlockRegions: [],
    regions: ['Wilderness'],
    prereqs: ['3146'],
    skillReqs: [{ skill: 'Magic', level: 75 }],
    wiki: 'https://oldschool.runescape.wiki/w/The_Mage_Arena_II',
  },
  {
    id: '3143',
    label: 'In Search of Knowledge',
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.MEDIUM,
    type: 'miniquest',
    autoUnlockRegions: [],
    regions: ['Kourend'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/In_Search_of_Knowledge',
  },
  {
    id: '1688',
    label: "Daddy's Home",
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    type: 'miniquest',
    autoUnlockRegions: [],
    regions: ['Misthalin'],
    prereqs: [],
    skillReqs: [],
    wiki: "https://oldschool.runescape.wiki/w/Daddy's_Home",
  },
  {
    id: '90000', // TODO this doesn't have an official ID yet
    label: 'The Frozen Door',
    difficulty: QUEST_DIFFICULTY.MASTER,
    length: QUEST_LENGTH.LONG,
    type: 'miniquest',
    autoUnlockRegions: [],
    regions: ['Asgarnia'],
    prereqs: ['345'],
    skillReqs: [
      { skill: 'Strength', level: 70 },
      { skill: 'Hitpoints', level: 70 },
      { skill: 'Ranged', level: 70 },
      { skill: 'Agility', level: 70 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/The_Frozen_Door',
  },
  {
    id: '90001', // TODO need official ID
    label: 'Land of the Goblins',
    points: 2,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.DORGESHUUN,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Asgarnia', 'Fremennik', 'Kandarin'],
    prereqs: ['364', '332'],
    skillReqs: [
      { skill: 'Herblore', level: 48 },
      { skill: 'Thieving', level: 45 },
      { skill: 'Fishing', level: 40 },
      { skill: 'Agility', level: 38 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Land_of_the_Goblins',
  },
  {
    id: '90002', // TODO need official ID
    label: 'Secrets of the North',
    points: 2,
    difficulty: QUEST_DIFFICULTY.MASTER,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.MAHJARRAT,
    type: 'quest',
    autoUnlockRegions: ['Desert', 'Fremennik'],
    regions: [],
    prereqs: ['379', '346', '392', '325'],
    skillReqs: [
      { skill: 'Hunter', level: 56 },
      { skill: 'Thieving', level: 64 },
      { skill: 'Agility', level: 69 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Secrets_of_the_North',
  },
  {
    id: '90003', // TODO need official ID
    label: "Hopespear's Will",
    difficulty: QUEST_DIFFICULTY.MASTER,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.DORGESHUUN,
    type: 'miniquest',
    autoUnlockRegions: [],
    regions: [REGION_IMPOSSIBLE_TO_COMPLETE],
    prereqs: ['360', '345'],
    skillReqs: [{ skill: 'Prayer', level: 50 }],
    wiki: 'https://oldschool.runescape.wiki/w/Hopespear%27s_Will',
  },
  {
    id: '90004', // TODO need official ID
    label: 'Beneath Cursed Sands',
    points: 2,
    difficulty: QUEST_DIFFICULTY.MASTER,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.DESERT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Desert'],
    prereqs: ['339'],
    skillReqs: [
      { skill: 'Agility', level: 62 },
      { skill: 'Crafting', level: 55 },
      { skill: 'Firemaking', level: 55 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Beneath_Cursed_Sands',
  },
  {
    id: '90005', // TODO need official ID
    label: 'Into the Tombs',
    difficulty: QUEST_DIFFICULTY.MASTER,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.DESERT,
    type: 'miniquest',
    autoUnlockRegions: [],
    regions: ['Misthalin', 'Desert'],
    prereqs: ['90004'],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Into_the_Tombs',
  },
  {
    id: '90006', // TODO need official ID
    label: 'Temple of the Eye',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: ['Desert'],
    regions: [],
    prereqs: ['3140'],
    skillReqs: [{ skill: 'Runecraft', level: 10 }],
    wiki: 'https://oldschool.runescape.wiki/w/Temple_of_the_Eye',
  },
  {
    id: '90007', // TODO need official ID
    label: 'Sleeping Giants',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Desert'],
    prereqs: [],
    skillReqs: [{ skill: 'Smithing', level: 15 }],
    wiki: 'https://oldschool.runescape.wiki/w/Sleeping_Giants',
  },
  {
    id: '90008', // TODO need official ID
    label: 'The Garden of Death',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.TWISTED_TALES,
    type: 'quest',
    autoUnlockRegions: ['Desert'],
    regions: ['Kourend'],
    prereqs: [],
    skillReqs: [{ skill: 'Farming', level: 20 }],
    wiki: 'https://oldschool.runescape.wiki/w/The_Garden_of_Death',
  },
  {
    id: '90009', // TODO need official ID
    label: 'Desert Treasure II - The Fallen Empire',
    points: 5,
    difficulty: QUEST_DIFFICULTY.GRANDMASTER,
    length: QUEST_LENGTH.VERY_LONG,
    series: QUEST_SERIES.MAHJARRAT,
    type: 'quest',
    autoUnlockRegions: ['Desert'],
    regions: [],
    prereqs: ['345', '90002', '356', '90006', '90008', '2874', '90011'],
    skillReqs: [
      { skill: 'Firemaking', level: 75 },
      { skill: 'Magic', level: 75 },
      { skill: 'Thieving', level: 70 },
      { skill: 'Herblore', level: 62 },
      { skill: 'Runecraft', level: 60 },
      { skill: 'Construction', level: 60 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Desert_Treasure_II_-_The_Fallen_Empire',
  },
  {
    id: '90010', // TODO need official ID
    label: 'The Path of Glouphrie',
    points: 2,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.GNOME,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Kandarin'],
    prereqs: ['358', '3154', '438'],
    skillReqs: [
      { skill: 'Strength', level: 60 },
      { skill: 'Slayer', level: 56 },
      { skill: 'Thieving', level: 56 },
      { skill: 'Ranged', level: 47 },
      { skill: 'Agility', level: 45 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/The_Path_of_Glouphrie',
  },
  {
    id: '90011', // TODO need official ID
    label: 'His Faithful Servants',
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.MAHJARRAT,
    type: 'miniquest',
    autoUnlockRegions: ['Desert'],
    regions: ['Morytania'],
    prereqs: ['408'],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/His_Faithful_Servants',
  },
];

function getQuestsById() {
  const questsById = {};
  quests.forEach(quest => {
    questsById[quest.id] = quest;
  });
  return questsById;
}

export const questsById = getQuestsById();

export default quests;
