import { CATEGORY } from './categories';
import { DIFFICULTY } from './constants';

export const REGION_ANY = 'General';

export default {
  435: {
    id: '435',
    label: '1 Easy Clue Scroll',
    description: 'Open a Reward casket for completing an easy clue scroll.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.EASY,
    prerequisite: '',
  },
  442: {
    id: '442',
    label: '1 Medium Clue Scroll',
    description: 'Open a Reward casket for completing a medium clue scroll.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.MEDIUM,
    prerequisite: '',
  },
  436: {
    id: '436',
    label: '25 Easy Clue Scrolls',
    description: 'Open 25 Reward caskets for completing easy clue scrolls.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.EASY,
    prerequisite: '435',
  },
  497: {
    id: '497',
    label: 'Achieve Your First Level Up',
    description: 'Level up any of your skills for the first time.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.MILESTONE,
    prerequisite: '',
  },
  498: {
    id: '498',
    label: 'Achieve Your First Level 5',
    description: 'Reach level 5 in any skill (not including Agility, Hitpoints and Runecraft).',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.MILESTONE,
    prerequisite: '497',
  },
  499: {
    id: '499',
    label: 'Achieve Your First Level 10',
    description: 'Reach level 10 in any skill (not including Agility and Hitpoints).',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.MILESTONE,
    prerequisite: '498',
  },
  500: {
    id: '500',
    label: 'Achieve Your First Level 20',
    description: 'Reach level 20 in any skill.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.MILESTONE,
    prerequisite: '499',
  },
  665: {
    id: '665',
    label: 'Build a Room in Your Player Owned House',
    description: 'Build a room in your Player Owned House.',
    skillReqs: [{ skill: 'Construction', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  11: {
    id: '11',
    label: 'Burn Some Coloured Logs',
    description: 'Burn some logs that have been coloured with a firelighter.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.SHARED,
    prerequisite: '',
  },
  603: {
    id: '603',
    label: 'Burn Some Food',
    description: 'Burn any kind of food while trying to cook it.',
    skillReqs: [{ skill: 'Cooking', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  679: {
    id: '679',
    label: 'Burn Some Normal Logs',
    description: 'Burn some Normal Logs.',
    skillReqs: [{ skill: 'Firemaking', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FIREMAKING,
    prerequisite: '',
  },
  680: {
    id: '680',
    label: 'Burn Some Oak Logs',
    description: 'Burn some Oak Logs.',
    skillReqs: [{ skill: 'Firemaking', level: 15 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FIREMAKING,
    prerequisite: '',
  },
  316: {
    id: '316',
    label: 'Bury Some Bones',
    description: 'Bury any kind of Bones.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.PRAYER,
    prerequisite: '',
  },
  1505: {
    id: '1505',
    label: 'Buy Something From Trader Crewmembers',
    description: 'Buy something from the Trader Crewmembers.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  739: {
    id: '739',
    label: 'Cast Home Teleport',
    description: 'Cast the Home Teleport spell.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  687: {
    id: '687',
    label: 'Catch a Baby Impling',
    description: 'Catch a Baby Impling.',
    skillReqs: [{ skill: 'Hunter', level: 17 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  691: {
    id: '691',
    label: 'Catch a Butterfly',
    description: 'Catch any butterfly.',
    skillReqs: [
      { skill: 'Hunter', level: 15 },
      { skill: 'Hunter', level: 25 },
    ],
    regions: ['Kandarin', 'Fremennik', 'Kourend'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  649: {
    id: '649',
    label: 'Catch a Herring',
    description: 'Catch a Raw Herring whilst Fishing.',
    skillReqs: [{ skill: 'Fishing', level: 10 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  648: {
    id: '648',
    label: 'Catch a Shrimp',
    description: 'Catch Raw Shrimp while Fishing.',
    skillReqs: [{ skill: 'Fishing', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  650: {
    id: '650',
    label: 'Catch an Anchovy',
    description: 'Catch a Raw Anchovy whilst Fishing.',
    skillReqs: [{ skill: 'Fishing', level: 15 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  626: {
    id: '626',
    label: 'Check Your Slayer Task',
    description: 'Use an Enchanted Gem to check your Slayer Task.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  596: {
    id: '596',
    label: 'Chop Some Logs With a Steel Axe',
    description: 'Chop any kind of logs using a Steel Axe.',
    skillReqs: [{ skill: 'Woodcutting', level: 6 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  595: {
    id: '595',
    label: 'Chop Some Logs',
    description: 'Chop any kind of logs.',
    skillReqs: [{ skill: 'Woodcutting', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  699: {
    id: '699',
    label: 'Clean a Grimy Guam',
    description: 'Clean a Grimy Guam.',
    skillReqs: [{ skill: 'Herblore', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  1882: {
    id: '1882',
    label: 'Complete 1 Slayer Task',
    description: 'Complete 1 Slayer Task.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  696: {
    id: '696',
    label: 'Complete a Rooftop Agility Course',
    description: 'Complete a lap of any Rooftop Agility Course.',
    skillReqs: [{ skill: 'Agility', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  496: {
    id: '496',
    label: 'Complete the Leagues Tutorial',
    description: 'Complete the Leagues Tutorial and begin your adventure.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  602: {
    id: '602',
    label: 'Cook Shrimp',
    description: 'Cook Raw Shrimp.',
    skillReqs: [{ skill: 'Cooking', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  641: {
    id: '641',
    label: 'Craft a Leather Body',
    description: 'Craft a Leather Body.',
    skillReqs: [{ skill: 'Crafting', level: 14 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '',
  },
  673: {
    id: '673',
    label: 'Craft Any Rune',
    description: 'Use a Runecrafting Altar to craft any type of rune from Essence.',
    skillReqs: [{ skill: 'Runecraft', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  1725: {
    id: '1725',
    label: 'Create a Compost Potion',
    description: 'Create a Compost Potion.',
    skillReqs: [{ skill: 'Herblore', level: 22 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  1724: {
    id: '1724',
    label: 'Create an Antipoison',
    description: 'Create an Antipoison.',
    skillReqs: [{ skill: 'Herblore', level: 5 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  1644: {
    id: '1644',
    label: 'Cry in a wheat field',
    description: 'Cry in a wheat field.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  640: {
    id: '640',
    label: 'Cut a Sapphire',
    description: 'Cut a Sapphire.',
    skillReqs: [{ skill: 'Crafting', level: 20 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '',
  },
  1645: {
    id: '1645',
    label: 'Dance in a graveyard',
    description: 'Dance in a graveyard.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  219: {
    id: '219',
    label: 'Defeat a Goblin',
    description: "Defeat a Goblin. After all, this still isn't the Goblin League.",
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  220: {
    id: '220',
    label: 'Defeat a Guard',
    description: 'Defeat a Guard.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  210: {
    id: '210',
    label: 'Defeat a Moss Giant',
    description: 'Defeat a Moss Giant.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  701: {
    id: '701',
    label: 'Drink a Strength Potion',
    description: 'Drink a Strength Potion.',
    skillReqs: [{ skill: 'Herblore', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  1626: {
    id: '1626',
    label: 'Dye a cape orange',
    description: 'Dye a cape orange.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  15: {
    id: '15',
    label: 'Eat some Purple Sweets',
    description: 'Eat some Purple Sweets.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.GENERAL,
    prerequisite: '',
  },
  1625: {
    id: '1625',
    label: 'Enter Puro Puro from Gielinor',
    description: 'Enter Puro Puro from a crop circle in mainland Gielinor.',
    skillReqs: [{ skill: 'Hunter', level: 17 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  969: {
    id: '969',
    label: 'Enter your Player Owned House',
    description: 'Enter your Player Owned House.',
    skillReqs: [{ skill: 'Construction', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  725: {
    id: '725',
    label: 'Equip a Full Mithril Set',
    description:
      'Equip a Mithril Platebody, a Mithril Full Helm and either some Mithril Platelegs or a Mithril Plateskirt.',
    skillReqs: [{ skill: 'Defence', level: 20 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.DEFENCE,
    prerequisite: '',
  },
  734: {
    id: '734',
    label: 'Equip a Maple Shortbow',
    description: 'Equip a Maple Shortbow.',
    skillReqs: [{ skill: 'Ranged', level: 30 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.RANGED,
    prerequisite: '',
  },
  720: {
    id: '720',
    label: 'Equip a Mithril Weapon',
    description: 'Equip any Mithril weapon.',
    skillReqs: [{ skill: 'Attack', level: 20 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.ATTACK,
    prerequisite: '',
  },
  629: {
    id: '629',
    label: 'Equip a Spiny Helmet',
    description: 'Equip a Spiny Helmet.',
    skillReqs: [{ skill: 'Defence', level: 5 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  729: {
    id: '729',
    label: 'Equip a Studded Body and Chaps',
    description: 'Equip a Studded Body along with some Studded Chaps.',
    skillReqs: [
      { skill: 'Ranged', level: 20 },
      { skill: 'Defence', level: 20 },
    ],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.RANGED,
    prerequisite: '',
  },
  1406: {
    id: '1406',
    label: 'Equip a Willow Shield',
    description: 'Equip a Willow Shield.',
    skillReqs: [
      { skill: 'Defence', level: 30 },
      { skill: 'Fletching', level: 42 },
    ],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.RANGED,
    prerequisite: '',
  },
  738: {
    id: '738',
    label: 'Equip an Elemental Staff',
    description: 'Equip a basic elemental staff.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  1405: {
    id: '1405',
    label: 'Equip some Black armour',
    description: 'Equip either a Black Platebody, some Black Platelegs or a Black Full Helm.',
    skillReqs: [{ skill: 'Defence', level: 10 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.DEFENCE,
    prerequisite: '',
  },
  1404: {
    id: '1404',
    label: 'Equip some Steel armour',
    description: 'Equip either a Steel Platebody, some Steel Platelegs or a Steel Full Helm.',
    skillReqs: [{ skill: 'Defence', level: 5 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.DEFENCE,
    prerequisite: '',
  },
  1716: {
    id: '1716',
    label: 'Fletch 1000 arrow shafts',
    description: 'Fletch 1000 arrow shafts.',
    skillReqs: [{ skill: 'Fletching', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FLETCHING,
    prerequisite: '',
  },
  618: {
    id: '618',
    label: 'Fletch 150 Iron Arrows',
    description: 'Fletch 150 Iron Arrows.',
    skillReqs: [{ skill: 'Fletching', level: 15 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FLETCHING,
    prerequisite: '',
  },
  1715: {
    id: '1715',
    label: 'Fletch 25 Oak Stocks',
    description: 'Fletch 25 Oak Stocks.',
    skillReqs: [{ skill: 'Fletching', level: 24 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FLETCHING,
    prerequisite: '',
  },
  617: {
    id: '617',
    label: 'Fletch an Oak Shortbow',
    description: 'Fletch an Oak Shortbow.',
    skillReqs: [{ skill: 'Fletching', level: 20 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FLETCHING,
    prerequisite: '',
  },
  616: {
    id: '616',
    label: 'Fletch Some Arrow Shafts',
    description: 'Fletch some Arrow Shafts.',
    skillReqs: [{ skill: 'Fletching', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FLETCHING,
    prerequisite: '',
  },
  175: {
    id: '175',
    label: 'Gain a Unique Item From a Beginner Clue',
    description: 'Gain a unique item from a Beginner Clue Scroll Reward Casket.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.BEGINNER,
    prerequisite: '',
  },
  165: {
    id: '165',
    label: 'Gain a Unique Item From an Easy Clue',
    description: 'Gain a unique item from an Easy Clue Scroll Reward Casket.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.EASY,
    prerequisite: '',
  },
  1695: {
    id: '1695',
    label: 'Have a conversation with a cat',
    description: 'Have a conversation with a cat.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1633: {
    id: '1633',
    label: 'Kill a Necromancer',
    description: 'Kill a Necromancer.',
    skillReqs: [],
    regions: ['Kourend', 'Kandarin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  681: {
    id: '681',
    label: 'Light a Torch',
    description: 'Light a Torch.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  674: {
    id: '674',
    label: 'Locate a Runecrafting Altar With a Talisman',
    description: 'Use any Runecrafting Altar.',
    skillReqs: [{ skill: 'Runecraft', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  700: {
    id: '700',
    label: 'Make an Attack Potion',
    description: 'Make an Attack Potion.',
    skillReqs: [{ skill: 'Herblore', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  1508: {
    id: '1508',
    label: 'Make some Flour',
    description: 'Make some Flour in a windmill.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1729: {
    id: '1729',
    label: 'Mine 10 Tin Ore',
    description: 'Mine 10 Tin Ore.',
    skillReqs: [{ skill: 'Mining', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  1730: {
    id: '1730',
    label: 'Mine 15 coal',
    description: 'Mine 15 coal.',
    skillReqs: [{ skill: 'Mining', level: 30 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  609: {
    id: '609',
    label: 'Mine some Copper Ore',
    description: 'Mine some Copper Ore.',
    skillReqs: [{ skill: 'Mining', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  610: {
    id: '610',
    label: 'Mine some Ore With a Steel Pickaxe',
    description: 'Mine any ore using a Steel Pickaxe.',
    skillReqs: [{ skill: 'Mining', level: 6 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  597: {
    id: '597',
    label: 'Obtain a Bird Nest',
    description: 'Obtain a Bird Nest whilst cutting down trees.',
    skillReqs: [{ skill: 'Woodcutting', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  1402: {
    id: '1402',
    label: 'Obtain a Casket from Fishing',
    description: 'Obtain a Casket from Fishing.',
    skillReqs: [{ skill: 'Fishing', level: 16 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  611: {
    id: '611',
    label: 'Obtain a Gem While Mining',
    description: 'Obtain any kind of gem whilst Mining a rock.',
    skillReqs: [{ skill: 'Mining', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  694: {
    id: '694',
    label: 'Obtain a Mark of Grace',
    description: 'Obtain a Mark of Grace from any Rooftop Agility Course.',
    skillReqs: [{ skill: 'Agility', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  580: {
    id: '580',
    label: 'Open 28 Coin Pouches At Once',
    description: 'Open 28 Coin Pouches at once.',
    skillReqs: [{ skill: 'Thieving', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  495: {
    id: '495',
    label: 'Open the Leagues Menu',
    description: 'Open the Leagues Menu found within the Journal Panel.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  579: {
    id: '579',
    label: 'Pickpocket a Citizen',
    description: 'Pickpocket a Man or a Woman.',
    skillReqs: [{ skill: 'Thieving', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  587: {
    id: '587',
    label: 'Plant Seeds in an Allotment Patch',
    description: 'Plant some seeds in an Allotment patch.',
    skillReqs: [{ skill: 'Farming', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  588: {
    id: '588',
    label: 'Protect Your Crops',
    description: 'Pay a farmer to protect any of your crops.',
    skillReqs: [{ skill: 'Farming', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  664: {
    id: '664',
    label: 'Purchase a Player Owned House',
    description: 'Purchase a Player Owned House.',
    skillReqs: [{ skill: 'Construction', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  586: {
    id: '586',
    label: 'Rake a Farming Patch',
    description: 'Rake any Farming patch.',
    skillReqs: [{ skill: 'Farming', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  522: {
    id: '522',
    label: 'Reach Base Level 10',
    description: 'Reach level 10 in every skill.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.BASE,
    prerequisite: '521',
  },
  523: {
    id: '523',
    label: 'Reach Base Level 20',
    description: 'Reach level 20 in every skill.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.BASE,
    prerequisite: '522',
  },
  521: {
    id: '521',
    label: 'Reach Base Level 5',
    description: 'Reach level 5 in every skill.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.BASE,
    prerequisite: '',
  },
  709: {
    id: '709',
    label: 'Reach Combat Level 10',
    description: 'Reach Combat Level 10.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.LEVEL,
    prerequisite: '',
  },
  710: {
    id: '710',
    label: 'Reach Combat Level 25',
    description: 'Reach Combat Level 25.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.LEVEL,
    prerequisite: '709',
  },
  509: {
    id: '509',
    label: 'Reach Total Level 100',
    description: 'Reach a Total Level of 100.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.TOTAL,
    prerequisite: '',
  },
  510: {
    id: '510',
    label: 'Reach Total Level 250',
    description: 'Reach a Total Level of 250.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.TOTAL,
    prerequisite: '509',
  },
  511: {
    id: '511',
    label: 'Reach Total Level 500',
    description: 'Reach a Total Level of 500.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.TOTAL,
    prerequisite: '510',
  },
  624: {
    id: '624',
    label: 'Receive a Slayer Task',
    description: 'Receive a Slayer Task from any Slayer Master.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  313: {
    id: '313',
    label: 'Restore 5 Prayer Points at an Altar',
    description: 'Restore 5 or more Prayer Points at any altar.',
    skillReqs: [{ skill: 'Prayer', level: 5 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.PRAYER,
    prerequisite: '',
  },
  1688: {
    id: '1688',
    label: 'Scrape some blue dragonhide',
    description: 'Scrape some blue dragonhide.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.GENERAL,
    prerequisite: '',
  },
  655: {
    id: '655',
    label: 'Smelt a Bronze Bar',
    description: 'Use a Furnace to smelt a Bronze Bar.',
    skillReqs: [{ skill: 'Smithing', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  656: {
    id: '656',
    label: 'Smelt an Iron Bar',
    description: 'Use a Furnace to smelt an Iron Bar.',
    skillReqs: [{ skill: 'Smithing', level: 15 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  657: {
    id: '657',
    label: 'Smith 150 Iron Arrowtips',
    description: 'Use an Anvil to smith 150 Iron Arrowtips.',
    skillReqs: [{ skill: 'Smithing', level: 20 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  1731: {
    id: '1731',
    label: 'Smith a Bronze full helm',
    description: 'Use an Anvil to smith a Bronze full helm.',
    skillReqs: [{ skill: 'Smithing', level: 7 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  1732: {
    id: '1732',
    label: 'Smith a Bronze plateskirt',
    description: 'Use an Anvil to smith a Bronze plateskirt.',
    skillReqs: [{ skill: 'Smithing', level: 16 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  688: {
    id: '688',
    label: 'Snare a Bird',
    description: 'Catch any bird with a Bird Snare.',
    skillReqs: [{ skill: 'Hunter', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  639: {
    id: '639',
    label: 'Spin a Ball of Wool',
    description: 'Use a Spinning Wheel to spin a Ball of Wool.',
    skillReqs: [{ skill: 'Crafting', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '',
  },
  581: {
    id: '581',
    label: 'Steal a Chocolate Slice',
    description: 'Steal a Chocolate Slice from a Bakery Stall.',
    skillReqs: [{ skill: 'Thieving', level: 5 }],
    regions: ['Fremennik', 'Kandarin', 'Kourend'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1403: {
    id: '1403',
    label: 'Steal Some Silk',
    description: 'Steal some silk from a silk stall.',
    skillReqs: [{ skill: 'Thieving', level: 20 }],
    regions: ['Kourend', 'Kandarin', 'Tirannwn'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  604: {
    id: '604',
    label: 'Successfully Cook 5 Pieces of Food',
    description: 'Cook 5 pieces of food in a row without burning them.',
    skillReqs: [{ skill: 'Cooking', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  1708: {
    id: '1708',
    label: 'Successfully Cut a Red Topaz',
    description: 'Successfully Cut a Red Topaz.',
    skillReqs: [{ skill: 'Crafting', level: 16 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '',
  },
  314: {
    id: '314',
    label: 'Superhuman Strength and Improved Reflexes',
    description: 'Use both the Superhuman Strength prayer and the Improved Reflexes prayer at the same time.',
    skillReqs: [{ skill: 'Prayer', level: 16 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.PRAYER,
    prerequisite: '',
  },
  666: {
    id: '666',
    label: 'Turn any Logs Into a Plank',
    description: 'Use a Sawmill to turn Logs into a Plank.',
    skillReqs: [{ skill: 'Construction', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  1632: {
    id: '1632',
    label: 'Use a herring on a tree',
    description: 'Not quite of the crimson variety.',
    skillReqs: [{ skill: 'Fishing', level: 10 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  717: {
    id: '717',
    label: "Visit Death's Domain",
    description: "Visit Death's Domain.",
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  672: {
    id: '672',
    label: 'Visit the Rune Essence Mine',
    description: 'Visit the Rune Essence Mine.',
    skillReqs: [{ skill: 'Runecraft', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  1624: {
    id: '1624',
    label: 'Become a mushroom',
    description: 'Have a wizard practice some Magic on you.',
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1150: {
    id: '1150',
    label: 'Complete a Game of Novice Pest Control',
    description: 'Complete a game of Novice Minigame Pest Control or higher.',
    skillReqs: [{ skill: 'Combat', level: 40 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.PC,
    prerequisite: '',
  },
  1097: {
    id: '1097',
    label: 'Craft an Air Rune',
    description: 'Craft an Essence at the Air Altar.',
    skillReqs: [{ skill: 'Runecraft', level: 1 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  1128: {
    id: '1128',
    label: 'Defeat a Black Demon in Asgarnia',
    description: 'Defeat a Black Demon in Asgarnia.',
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1126: {
    id: '1126',
    label: 'Defeat a Blue Dragon in Asgarnia',
    description: 'Defeat a Blue Dragon in Asgarnia.',
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1109: {
    id: '1109',
    label: 'Defeat a Troll in Asgarnia',
    description: 'Defeat a Troll in Asgarnia.',
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1689: {
    id: '1689',
    label: 'Defeat the Evil chicken',
    description: 'Defeat the Evil chicken.',
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1682: {
    id: '1682',
    label: 'Eat a magic cabbage',
    description: 'Eat a magic cabbage.',
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1146: {
    id: '1146',
    label: 'Enter the Taverley Dungeon',
    description: 'Enter the Taverley Dungeon.',
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1407: {
    id: '1407',
    label: 'Equip an Imcando Hammer',
    description: 'Equip an Imcando hammer.',
    skillReqs: [{ skill: 'Mining', level: 14 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  1694: {
    id: '1694',
    label: 'Give Thurgo a redberry pie',
    description: 'Give Thurgo a redberry pie.',
    skillReqs: [{ skill: 'Cooking', level: 10 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  1670: {
    id: '1670',
    label: 'Kill a port sarim prisoner',
    description: 'Kill a port sarim prisoner.',
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1103: {
    id: '1103',
    label: 'Move Your House to Taverley',
    description: 'Move your Player Owned House to Taverley.',
    skillReqs: [{ skill: 'Construction', level: 10 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  1408: {
    id: '1408',
    label: 'Open an Ornate Lockbox',
    description: 'Open an Ornate Lockbox from the Camdozaal Vault.',
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1681: {
    id: '1681',
    label: 'Pay Atlas to re-train you',
    description: 'Pay Atlas to re-train you.',
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1104: {
    id: '1104',
    label: 'Receive a Slayer Task From Turael or Spria',
    description: 'Receive a Slayer Task from Turael in Burthorpe or Spria in Draynor.',
    skillReqs: [{ skill: 'Slayer', level: 1 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  1629: {
    id: '1629',
    label: 'Talk to the Mysterious Old Man in Falador',
    description: 'Talk to the Mysterious Old Man in Falador.',
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1144: {
    id: '1144',
    label: 'Unlock a Gate in Taverley Dungeon',
    description: 'Unlock a gate in Taverley Dungeon using the Dusty Key.',
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1424: {
    id: '1424',
    label: '1 Tempoross Kill',
    description: 'Help the Spirit Anglers subdue the Tempoross.',
    skillReqs: [{ skill: 'Fishing', level: 35 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.TEMPOROSS,
    prerequisite: '',
  },
  1175: {
    id: '1175',
    label: 'Complete the Al Kharid Agility Course',
    description: 'Complete a lap of the Al Kharid Rooftop Agility Course.',
    skillReqs: [{ skill: 'Agility', level: 20 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  1221: {
    id: '1221',
    label: 'Complete The Golem',
    description: 'Complete Quest The Golem quest.',
    skillReqs: [
      { skill: 'Crafting', level: 20 },
      { skill: 'Thieving', level: 25 },
    ],
    regions: ['Desert'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.INTERMEDIATE,
    prerequisite: '',
  },
  1177: {
    id: '1177',
    label: 'Craft a Fire Rune',
    description: 'Craft a Essence at the Fire Altar.',
    skillReqs: [{ skill: 'Runecraft', level: 14 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  1217: {
    id: '1217',
    label: 'Cut a Cactus in the Kharidian Desert',
    description: 'Cut a Cactus in the Kharidian Desert.',
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1184: {
    id: '1184',
    label: 'Defeat an Al Kharid Warrior',
    description: 'Defeat an Al Kharid warrior.',
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1218: {
    id: '1218',
    label: 'Enter Sophanem',
    description: 'Enter Sophanem.',
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1219: {
    id: '1219',
    label: 'Enter the Kalphite Lair',
    description: 'Enter the Kalphite Lair.',
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1627: {
    id: '1627',
    label: 'Get sent to port sarim jail',
    description: 'Become an outlaw and get sent to Port Sarim Jail.',
    skillReqs: [],
    regions: ['Desert', 'Asgarnia'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1205: {
    id: '1205',
    label: 'Go Through the Al Kharid Gate',
    description: 'Go through the Al Kharid gate.',
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1676: {
    id: '1676',
    label: 'Kill a Seagull in the Desert',
    description: 'Kill a Seagull in the Desert.',
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1594: {
    id: '1594',
    label: 'Obtain Fossilised Dung',
    description: 'Obtain Fossilised Dung.',
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOA,
    prerequisite: '',
  },
  1165: {
    id: '1165',
    label: "Pick a Winter Sq'irk",
    description: "Pick a Winter Sq'irk in the Sorceress's Garden.",
    skillReqs: [{ skill: 'Thieving', level: 1 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1675: {
    id: '1675',
    label: 'Pick up a cabbage in the Citharede Abbey',
    description: 'Pick up a Cabbage in the Citharede Abbey.',
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1154: {
    id: '1154',
    label: 'Room 1 of Pyramid Plunder',
    description: 'Search the Golden Chest in Room 1 of Pyramid Plunder in Sophanem.',
    skillReqs: [{ skill: 'Thieving', level: 21 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1155: {
    id: '1155',
    label: 'Room 2 of Pyramid Plunder',
    description: 'Search the Golden Chest in Room 2 of Pyramid Plunder in Sophanem.',
    skillReqs: [{ skill: 'Thieving', level: 31 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1156: {
    id: '1156',
    label: 'Room 3 of Pyramid Plunder',
    description: 'Search the Golden Chest in Room 3 of Pyramid Plunder in Sophanem.',
    skillReqs: [{ skill: 'Thieving', level: 41 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1375: {
    id: '1375',
    label: 'Take a Carpet Ride from Pollnivneach to Sophanem',
    description: 'Take a carpet ride from Pollnivneach to Sophanem.',
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1209: {
    id: '1209',
    label: 'Tan a Cow Hide in the Kharidian Desert',
    description: 'Tan a Cow Hide in the Kharidian Desert.',
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1169: {
    id: '1169',
    label: "Turn in a Winter Sq'irkjuice to Osman",
    description: "Turn in a Winter Sq'irkjuice to Osman in Al Kharid.",
    skillReqs: [{ skill: 'Thieving', level: 1 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1649: {
    id: '1649',
    label: 'Chop down an arctic pine tree',
    description: 'Chop down an arctic pine tree.',
    skillReqs: [{ skill: 'Woodcutting', level: 54 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  905: {
    id: '905',
    label: 'Defeat a Cockatrice in the Fremennik Province',
    description: 'Defeat a Cockatrice in the Fremennik Province.',
    skillReqs: [{ skill: 'Slayer', level: 25 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  909: {
    id: '909',
    label: 'Defeat a Pyrefiend in the Fremennik Province',
    description: 'Defeat a Pyrefiend in the Fremennik Province.',
    skillReqs: [{ skill: 'Slayer', level: 30 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  900: {
    id: '900',
    label: 'Defeat a Rock Crab in the Fremennik Province',
    description: 'Defeat a Rock Crab in the Fremennik Province.',
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  914: {
    id: '914',
    label: 'Defeat a Troll in the Fremennik Province',
    description: 'Defeat a Troll in the Fremennik Province.',
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  947: {
    id: '947',
    label: 'Deposit an Item Using Peer the Seer',
    description: 'Deposit an item into your Bank using Peer the Seer.',
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1647: {
    id: '1647',
    label: 'Kill a Yak',
    description: 'Kill a Yak.',
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  882: {
    id: '882',
    label: 'Switch to the Lunar Spellbook',
    description: 'Switch to the Lunar Spellbook.',
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  952: {
    id: '952',
    label: 'Use the Bank on Jatizso',
    description: 'Use the Bank on Jatizso.',
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  956: {
    id: '956',
    label: 'Use the Bank on Lunar Isle',
    description: 'Use the Bank on Lunar Isle.',
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  954: {
    id: '954',
    label: 'Use the Bank on Neitiznot',
    description: 'Use the Bank on Neitiznot.',
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1070: {
    id: '1070',
    label: 'Brimstail Rune Essence Mine teleport',
    description: 'Have Brimstail teleport you to the Rune Essence Mine.',
    skillReqs: [{ skill: 'Runecraft', level: 1 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  1013: {
    id: '1013',
    label: 'Catch a Crimson Swift in the Feldip Hills',
    description: 'Catch a Crimson Swift in the Feldip Hills.',
    skillReqs: [{ skill: 'Hunter', level: 1 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1727: {
    id: '1727',
    label: 'Catch a wild kebbit',
    description: 'Catch a wild kebbit.',
    skillReqs: [{ skill: 'Hunter', level: 23 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1021: {
    id: '1021',
    label: 'Complete a Fishing Trawler Game',
    description: 'Complete a Minigame Fishing Trawler game at Port Khazard.',
    skillReqs: [{ skill: 'Fishing', level: 15 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  1509: {
    id: '1509',
    label: 'Complete Fishing Contest',
    description: 'Complete the Quest Fishing Contest Quest.',
    skillReqs: [{ skill: 'Fishing', level: 10 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.NOVICE,
    prerequisite: '',
  },
  1064: {
    id: '1064',
    label: "Complete Monk's Friend",
    description: "Complete the Quest Monk's Friend quest.",
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.NOVICE,
    prerequisite: '',
  },
  1068: {
    id: '1068',
    label: 'Complete Sea Slug',
    description: 'Complete the Quest Sea Slug quest.',
    skillReqs: [{ skill: 'Firemaking', level: 30 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.INTERMEDIATE,
    prerequisite: '',
  },
  1009: {
    id: '1009',
    label: 'Complete the Gnome Stronghold Agility Course',
    description: 'Complete a lap of the Gnome Stronghold Agility Course.',
    skillReqs: [{ skill: 'Agility', level: 1 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  1044: {
    id: '1044',
    label: 'Defeat a Bloodveld in Kandarin',
    description: 'Defeat a Bloodveld in Kandarin.',
    skillReqs: [{ skill: 'Slayer', level: 50 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  1047: {
    id: '1047',
    label: 'Defeat a Fire Giant in Kandarin',
    description: 'Defeat a Fire Giant in Kandarin.',
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1507: {
    id: '1507',
    label: 'Drink a Poison Chalice',
    description: 'Drink a Poison Chalice.',
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1072: {
    id: '1072',
    label: 'Equip a Marksman Chompy Hat',
    description: 'Equip a Marksman Chompy Hat.',
    skillReqs: [
      { skill: 'Fletching', level: 5 },
      { skill: 'Cooking', level: 30 },
      { skill: 'Ranged', level: 30 },
      { skill: 'Crafting', level: 5 },
    ],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.CHOMPIES,
    prerequisite: '',
  },
  1655: {
    id: '1655',
    label: 'Flex in front of The Guns',
    description: 'Flex in front of The Guns',
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1506: {
    id: '1506',
    label: 'Get Stung By Bees',
    description: 'Get stung by bees at the Beehives.',
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1800: {
    id: '1800',
    label: 'Kill a Newtroost',
    description: 'Kill a Newtroost underneath the Tower of Life.',
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1628: {
    id: '1628',
    label: 'Kill Salarin the Twisted',
    description: 'Kill Salarin the Twisted in Yanille Agility Dungeon.',
    skillReqs: [{ skill: 'Agility', level: 67 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1504: {
    id: '1504',
    label: 'Pick Wheat in Catherby',
    description: 'Pick some wheat in the field by the Catherby Farming patches.',
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1656: {
    id: '1656',
    label: 'Prove yourself to Ponts',
    description: 'Prove yourself to Ponts.',
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1027: {
    id: '1027',
    label: 'Receive a Slayer Task From Nieve/Steve',
    description: 'Receive a Slayer Task from Nieve/Steve in the Tree Gnome Stronghold.',
    skillReqs: [{ skill: 'Combat', level: 85 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  1060: {
    id: '1060',
    label: 'Visit the Tree Gnome Stronghold',
    description: 'Visit the Tree Gnome Stronghold.',
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1057: {
    id: '1057',
    label: 'Visit the Tree Gnome Village',
    description: 'Visit the Tree Gnome Village.',
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  964: {
    id: '964',
    label: 'Catch a Karambwanji',
    description: 'Catch a Karambwanji on Karamja.',
    skillReqs: [{ skill: 'Fishing', level: 5 }],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  980: {
    id: '980',
    label: 'Defeat a Greater Demon on Karamja',
    description: 'Defeat a Greater Demon on Karamja.',
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  970: {
    id: '970',
    label: 'Defeat a TzHaar',
    description: 'Defeat any TzHaar in Mor Ul Rek.',
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1006: {
    id: '1006',
    label: 'Enter the Brimhaven Dungeon',
    description: 'Enter the Brimhaven Dungeon.',
    skillReqs: [{ skill: 'Woodcutting', level: 10 }],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1002: {
    id: '1002',
    label: 'Pick a Pineapple on Karamja',
    description: 'Pick a Pineapple on Karamja.',
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  975: {
    id: '975',
    label: 'Receive a Slayer Task From Duradel',
    description: 'Receive a Slayer Task From Duradel in Shilo Village.',
    skillReqs: [
      { skill: 'Slayer', level: 50 },
      { skill: 'Combat', level: 100 },
    ],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  958: {
    id: '958',
    label: 'Receive an Agility Arena Ticket',
    description: 'Receive an Agility Arena Ticket from the Brimhaven Agility Arena.',
    skillReqs: [{ skill: 'Agility', level: 1 }],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  328: {
    id: '328',
    label: '1 Sarachnis Kill',
    description: 'Kill Sarachnis in the Forthos Dungeon.',
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SARACHNIS,
    prerequisite: '',
  },
  233: {
    id: '233',
    label: '1 Wintertodt Kill',
    description: 'Help the Pyromancers subdue the Wintertodt.',
    skillReqs: [{ skill: 'Firemaking', level: 50 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WINTERTODT,
    prerequisite: '',
  },
  1646: {
    id: '1646',
    label: 'Brutally murder Conrad King',
    description: 'Brutally murder Conrad King.',
    skillReqs: [{ skill: 'Thieving', level: 20 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  245: {
    id: '245',
    label: 'Complete 1 Farming Contract',
    description: 'Complete a Farming Contract for Guildmaster Jane in the Farming Guild.',
    skillReqs: [{ skill: 'Farming', level: 45 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  7: {
    id: '7',
    label: 'Complete Architectural Alliance',
    description: 'Complete the mini-quest Quest Architectural Alliance.',
    skillReqs: [{ skill: 'Mining', level: 42 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.EXPERIENCED,
    prerequisite: '',
  },
  3: {
    id: '3',
    label: 'Complete Ascent of Arceuus',
    description: 'Complete the Quest Ascent of Arceuus quest.',
    skillReqs: [{ skill: 'Hunter', level: 12 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.INTERMEDIATE,
    prerequisite: '',
  },
  0: {
    id: '0',
    label: 'Complete Client of Kourend',
    description: 'Complete the Quest Client of Kourend quest.',
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.NOVICE,
    prerequisite: '',
  },
  4: {
    id: '4',
    label: 'Complete Depths of Despair',
    description: 'Complete the Quest Depths of Despair quest.',
    skillReqs: [{ skill: 'Agility', level: 18 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.INTERMEDIATE,
    prerequisite: '',
  },
  2: {
    id: '2',
    label: 'Complete Forsaken Tower',
    description: 'Complete the Quest Forsaken Tower quest.',
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.INTERMEDIATE,
    prerequisite: '',
  },
  6: {
    id: '6',
    label: 'Complete In Search Of Knowledge',
    description: 'Complete the mini-quest Quest In Search Of Knowledge.',
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.EXPERIENCED,
    prerequisite: '',
  },
  1: {
    id: '1',
    label: 'Complete Queen of Thieves',
    description: 'Complete the Quest Queen of Thieves quest.',
    skillReqs: [{ skill: 'Thieving', level: 20 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.INTERMEDIATE,
    prerequisite: '',
  },
  5: {
    id: '5',
    label: 'Complete Tale Of The Righteous',
    description: 'Complete the Quest Tale of the Righteous quest.',
    skillReqs: [
      { skill: 'Strength', level: 16 },
      { skill: 'Mining', level: 10 },
    ],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.INTERMEDIATE,
    prerequisite: '',
  },
  1634: {
    id: '1634',
    label: 'Drink a LizardKicker',
    description: 'Drink a LizardKicker.',
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1643: {
    id: '1643',
    label: 'Equip a Protest Banner',
    description: 'Equip a Protest Banner.',
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1636: {
    id: '1636',
    label: 'Fish an old boot in stranglewood',
    description: 'Fish an old boot in stranglewood.',
    skillReqs: [{ skill: 'Fishing', level: 1 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  414: {
    id: '414',
    label: 'Open 1 Grubby Chest',
    description: 'Open the grubby chest in the Forthos Dungeon.',
    skillReqs: [{ skill: 'Thieving', level: 57 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  415: {
    id: '415',
    label: 'Open 15 Grubby Chests',
    description: 'Open the grubby chest in the Forthos Dungeon 15 times.',
    skillReqs: [{ skill: 'Thieving', level: 57 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '414',
  },
  1640: {
    id: '1640',
    label: 'Pray at an altar in Molch while poisoned.',
    description: 'Pray at an altar in Molch while poisoned.',
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  476: {
    id: '476',
    label: 'Receive a Konar Slayer Task',
    description: 'Have a Slayer Task assigned by Konar.',
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  421: {
    id: '421',
    label: 'Steal 1 Artefact',
    description: 'Steal an artefact for Captain Khaled in Piscarilius.',
    skillReqs: [{ skill: 'Thieving', level: 49 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  428: {
    id: '428',
    label: 'Turn in 1 Library Book',
    description: 'Find and turn in a book in the Arceuus Library.',
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.OTHER,
    prerequisite: '',
  },
  816: {
    id: '816',
    label: 'Build a Bank on Fossil Island',
    description: 'Build a Bank at the Museum Camp on Fossil Island.',
    skillReqs: [{ skill: 'Construction', level: 21 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  1692: {
    id: '1692',
    label: 'Charm Gertrude',
    description: 'Charm Gertrude.',
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1673: {
    id: '1673',
    label: 'Chop a log from a potato tree',
    description: 'Chop a log from a tree that is curiously in a potato field.',
    skillReqs: [{ skill: 'Woodcutting', level: 1 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  1674: {
    id: '1674',
    label: 'Churn some butter',
    description: 'Use a churn to make some butter.',
    skillReqs: [{ skill: 'Cooking', level: 38 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  815: {
    id: '815',
    label: "Complete Gertrude's Cat",
    description: "Complete the Quest Gertrude's Cat quest.",
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.NOVICE,
    prerequisite: '',
  },
  813: {
    id: '813',
    label: 'Complete Romeo & Juliet',
    description: 'Complete the Quest Romeo & Juliet Quest',
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.NOVICE,
    prerequisite: '',
  },
  809: {
    id: '809',
    label: 'Complete Sheep Shearer',
    description: 'Complete the Quest Sheep Shearer quest.',
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.NOVICE,
    prerequisite: '',
  },
  768: {
    id: '768',
    label: 'Complete the Draynor Agility Course',
    description: 'Complete a lap of the Draynor Rooftop Agility Course.',
    skillReqs: [{ skill: 'Agility', level: 1 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  821: {
    id: '821',
    label: 'Complete the Natural History Quiz',
    description: 'Complete the Natural History Quiz in the Varrock Museum.',
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1880: {
    id: '1880',
    label: 'Complete the Varrock Agility Course',
    description: 'Complete a lap of the Varrock Rooftop Agility Course.',
    skillReqs: [{ skill: 'Agility', level: 30 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  812: {
    id: '812',
    label: 'Complete Vampyre Slayer',
    description: 'Complete the Quest Vampyre Slayer quest.',
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.INTERMEDIATE,
    prerequisite: '',
  },
  771: {
    id: '771',
    label: 'Craft 50 Water Runes',
    description: 'Craft 50 Water Runes from Essence at the Water Altar.',
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  775: {
    id: '775',
    label: "Defeat the Lesser Demon in the Wizards' Tower",
    description: "Defeat the Lesser Demon at the top of the Wizards' Tower.",
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  822: {
    id: '822',
    label: 'Enter Draynor Manor',
    description: 'Enter Draynor Manor through the front door.',
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  803: {
    id: '803',
    label: 'Enter Zanaris',
    description: 'Enter the lost city of Zanaris.',
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  811: {
    id: '811',
    label: 'Equip Some Fancy, Fancier or Fighting Boots',
    description: 'Equip a pair of Fancy Boots, Fancier Boots or Fighting Boots.',
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1668: {
    id: '1668',
    label: 'Feed a draynor guard some stew',
    description: 'Feed a Draynor guard some stew in Draynor Village.',
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  814: {
    id: '814',
    label: 'Fully Unlock the Mycelium Transportation System',
    description: 'Unlock every destination for the Mycelium Transportation System on Fossil Island.',
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1671: {
    id: '1671',
    label: 'Get a chair to follow you',
    description: 'Get a chair to follow you.',
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1672: {
    id: '1672',
    label: 'Get your revenge against a Dark Wizard',
    description: 'Get your revenge against a Dark Wizard, south of Varrock.',
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1679: {
    id: '1679',
    label: 'Have Elsie tell you a story',
    description: 'Have Elsie tell you a story in Varrock.',
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1669: {
    id: '1669',
    label: 'Have Ned make you some rope',
    description: 'Have Ned make you some rope using a ball of wool in Draynor Village.',
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1665: {
    id: '1665',
    label: 'Insult Aggie the Witch',
    description: 'Insult Aggie the Witch in Draynor Village.',
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1663: {
    id: '1663',
    label: 'Milk a cow',
    description: 'Milk a cow in Lumbridge.',
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  807: {
    id: '807',
    label: 'Pet a Stray Dog in Varrock',
    description: 'Pet a Stray Dog in Varrock.',
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1495: {
    id: '1495',
    label: 'Pet the Museum Camp dog',
    description: 'Pet the dog in the Museum Camp on Fossil Island.',
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  763: {
    id: '763',
    label: 'Pickpocket a H.A.M. Member',
    description: 'Pickpocket any H.A.M. member at their hideout.',
    skillReqs: [{ skill: 'Thieving', level: 15 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  784: {
    id: '784',
    label: 'Receive a Slayer Task From Vannaka',
    description: 'Receive a Slayer Task from Vannaka in Edgeville Dungeon.',
    skillReqs: [{ skill: 'Combat', level: 40 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  1690: {
    id: '1690',
    label: 'Slash a web in varrock sewers',
    description: 'Slash a web in Varrock sewers.',
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  766: {
    id: '766',
    label: 'Steal From the Varrock Tea Stall',
    description: 'Steal from the Tea Stall in Varrock.',
    skillReqs: [{ skill: 'Thieving', level: 5 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1630: {
    id: '1630',
    label: 'Taste a Dorgeshuun Delicacy',
    description: 'Taste a Dorgeshuun Delicacy.',
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  805: {
    id: '805',
    label: 'Travel to Fossil Island',
    description: 'Take the Museum Barge to Fossil Island.',
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  817: {
    id: '817',
    label: 'Use a Fairy Ring',
    description: 'Use any Fairy Ring.',
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  759: {
    id: '759',
    label: 'Use the Range in Lumbridge Castle',
    description: 'Use the Range in Lumbridge Castle to cook some food.',
    skillReqs: [{ skill: 'Cooking', level: 1 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  1284: {
    id: '1284',
    label: 'Complete an Easy Temple Trek',
    description: 'Complete an easy Temple Trek.',
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.OTHER,
    prerequisite: '',
  },
  1237: {
    id: '1237',
    label: 'Craft a Snelm',
    description: 'Craft any Snelm.',
    skillReqs: [{ skill: 'Crafting', level: 15 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '',
  },
  1252: {
    id: '1252',
    label: 'Defeat a Ghast',
    description: 'Defeat a Ghast in Mort Myre.',
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1244: {
    id: '1244',
    label: 'Defeat a Werewolf in Morytania',
    description: 'Defeat a Werewolf in Morytania.',
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1248: {
    id: '1248',
    label: "Defeat One of Fenkenstrain's Experiments",
    description: "Defeat one of Fenkenstrain's Experiments in the Experiment Cave.",
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1478: {
    id: '1478',
    label: 'Open a Bronze Chest',
    description: "Open a Shades of Mort'ton.",
    skillReqs: [{ skill: 'Firemaking', level: 5 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.SHADES,
    prerequisite: '',
  },
  1240: {
    id: '1240',
    label: 'Receive a Slayer Task From Mazchna',
    description: 'Receive a Slayer Task from Mazchna in Canifis.',
    skillReqs: [{ skill: 'Combat', level: 20 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  1295: {
    id: '1295',
    label: 'Visit Harmony Island',
    description: 'Visit Harmony Island.',
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1297: {
    id: '1297',
    label: "Visit Mos Le'Harmless",
    description: "Visit Mos Le'Harmless.",
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1287: {
    id: '1287',
    label: 'Visit Port Phasmatys',
    description: 'Visit Port Phasmatys.',
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  880: {
    id: '880',
    label: 'Charter a Ship From Prifddinas to Port Tyras',
    description: 'Take a Charter Ship from Prifddinas to Port Tyras.',
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  823: {
    id: '823',
    label: 'Cook a Rabbit in Tirannwn',
    description: 'Cook a Rabbit anywhere within Tirannwn.',
    skillReqs: [{ skill: 'Cooking', level: 1 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  830: {
    id: '830',
    label: 'Cross a Trap in Isafdar',
    description: 'Successfully cross any kind of trap in Isafdar.',
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  841: {
    id: '841',
    label: 'Defeat a Moss Giant in Tirannwn',
    description: 'Defeat a Moss Giant in Tirannwn.',
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  837: {
    id: '837',
    label: 'Defeat a Waterfiend in Tirannwn',
    description: 'Defeat a Waterfiend in Tirannwn.',
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  825: {
    id: '825',
    label: 'Harvest Some Sweetcorn in Prifddinas',
    description: 'Harvest some Sweetcorn from the Prifddinas Allotment patch.',
    skillReqs: [{ skill: 'Farming', level: 20 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  881: {
    id: '881',
    label: 'Talk to Ilfeen in Tirannwn',
    description: 'Talk to Ilfeen in Tirannwn.',
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1657: {
    id: '1657',
    label: "Use Broken Handz's telescope",
    description: "Use Broken Hand'z telescope to spy on somene.",
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  873: {
    id: '873',
    label: 'Use the Bank in Lletya',
    description: 'Use the Bank in Lletya.',
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1323: {
    id: '1323',
    label: 'Defeat a Chaos Dwarf in the Wilderness',
    description: 'Defeat a Chaos Dwarf in the Wilderness.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1319: {
    id: '1319',
    label: 'Defeat a Fire Giant in the Wilderness',
    description: 'Defeat a Fire Giant in the Wilderness.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1367: {
    id: '1367',
    label: 'Enter the Wilderness God Wars Dungeon',
    description: 'Enter the Wilderness God Wars Dungeon.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1365: {
    id: '1365',
    label: 'Enter the Wilderness Resource Area',
    description: 'Enter the Wilderness Resource Area.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1652: {
    id: '1652',
    label: 'Equip a team cape',
    description: 'Equip a team cape.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1346: {
    id: '1346',
    label: 'Order a Drink at The Old Nite',
    description: 'Order a drink at The Old Nite.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1363: {
    id: '1363',
    label: 'Pray at the Southern Chaos Temple',
    description: 'Pray at the altar in the Southern Chaos Temple.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1364: {
    id: '1364',
    label: 'Pray at the Western Chaos Temple',
    description: 'Pray at the altar in the Western Chaos Temple.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1361: {
    id: '1361',
    label: 'Use a Lever to Teleport to Edgeville',
    description: 'Use the lever near the Mage Arena to teleport to Edgeville.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1368: {
    id: '1368',
    label: 'Use the Bank at the Mage Arena',
    description: 'Use the Bank at the Mage Arena.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  383: {
    id: '383',
    label: 'Visit Ferox Enclave',
    description: 'Visit Ferox Enclave.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1456: {
    id: '1456',
    label: '1 Combat Achievement',
    description: 'Complete 1 Combat Achievement',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.GENERAL,
    prerequisite: '',
  },
  456: {
    id: '456',
    label: '1 Elite Clue Scroll',
    description: 'Open a Reward casket for completing an elite clue scroll.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.ELITE,
    prerequisite: '',
  },
  449: {
    id: '449',
    label: '1 Hard Clue Scroll',
    description: 'Open a Reward casket for completing a hard clue scroll.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.HARD,
    prerequisite: '',
  },
  1457: {
    id: '1457',
    label: '10 Combat Achievements',
    description: 'Complete 10 Combat Achievements',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.GENERAL,
    prerequisite: '1456',
  },
  318: {
    id: '318',
    label: '100 Superior Slayer Encounters',
    description: 'Defeat 100 superior foes while on a Slayer Task.',
    skillReqs: [{ skill: 'Slayer', level: 5 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '317',
  },
  1807: {
    id: '1807',
    label: '15 Collection log slots',
    description: 'Obtain 15 unique Collection Log slots.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.COLLECTION_LOG,
    prerequisite: '1806',
  },
  438: {
    id: '438',
    label: '150 Easy Clue Scrolls',
    description: 'Open 150 Reward caskets for completing easy clue scrolls.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.EASY,
    prerequisite: '437',
  },
  445: {
    id: '445',
    label: '150 Medium Clue Scrolls',
    description: 'Open 150 Reward caskets for completing medium clue scrolls.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.MEDIUM,
    prerequisite: '444',
  },
  450: {
    id: '450',
    label: '25 Hard Clue Scrolls',
    description: 'Open 25 Reward caskets for completing hard clue scrolls.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.HARD,
    prerequisite: '449',
  },
  443: {
    id: '443',
    label: '25 Medium Clue Scrolls',
    description: 'Open 25 Reward caskets for completing medium clue scrolls.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.MEDIUM,
    prerequisite: '442',
  },
  315: {
    id: '315',
    label: '25 Superior Slayer Encounters',
    description: 'Defeat 25 superior foes while on a Slayer Task.',
    skillReqs: [{ skill: 'Slayer', level: 5 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '635',
  },
  1808: {
    id: '1808',
    label: '30 Collection log slots',
    description: 'Obtain 30 unique Collection Log slots.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.COLLECTION_LOG,
    prerequisite: '1807',
  },
  1806: {
    id: '1806',
    label: '5 Collection log slots',
    description: 'Obtain 5 unique Collection Log slots.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.COLLECTION_LOG,
    prerequisite: '',
  },
  437: {
    id: '437',
    label: '75 Easy Clue Scrolls',
    description: 'Open 75 Reward caskets for completing easy clue scrolls.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.EASY,
    prerequisite: '436',
  },
  444: {
    id: '444',
    label: '75 Medium Clue Scrolls',
    description: 'Open 75 Reward caskets for completing medium clue scrolls.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.MEDIUM,
    prerequisite: '443',
  },
  317: {
    id: '317',
    label: '75 Superior Slayer Encounters',
    description: 'Defeat 75 superior foes while on a Slayer Task.',
    skillReqs: [{ skill: 'Slayer', level: 5 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '638',
  },
  501: {
    id: '501',
    label: 'Achieve Your First Level 30',
    description: 'Reach level 30 in any skill.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.MILESTONE,
    prerequisite: '500',
  },
  502: {
    id: '502',
    label: 'Achieve Your First Level 40',
    description: 'Reach level 40 in any skill.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.MILESTONE,
    prerequisite: '501',
  },
  503: {
    id: '503',
    label: 'Achieve Your First Level 50',
    description: 'Reach level 50 in any skill.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.MILESTONE,
    prerequisite: '502',
  },
  280: {
    id: '280',
    label: 'Activate Smite',
    description: 'Activate Smite in your Prayer book.',
    skillReqs: [{ skill: 'Prayer', level: 52 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.PRAYER,
    prerequisite: '',
  },
  667: {
    id: '667',
    label: 'Build a Mahogany Portal',
    description: 'Build a Mahogany Portal in a Portal Chamber in your Player Owned House.',
    skillReqs: [{ skill: 'Construction', level: 65 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  600: {
    id: '600',
    label: 'Build a Waka Canoe',
    description: 'Build a Waka Canoe.',
    skillReqs: [{ skill: 'Woodcutting', level: 57 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  668: {
    id: '668',
    label: 'Build an Oak Larder',
    description: 'Build an Oak Larder in a Kitchen in your Player Owned House.',
    skillReqs: [{ skill: 'Construction', level: 33 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  682: {
    id: '682',
    label: 'Burn 100 Willow Logs',
    description: 'Burn 100 Willow Logs.',
    skillReqs: [{ skill: 'Firemaking', level: 30 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FIREMAKING,
    prerequisite: '',
  },
  494: {
    id: '494',
    label: 'Bury Some Wyvern or Dragon Bones',
    description: 'Bury either some Wyvern Bones or some Dragon Bones.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.PRAYER,
    prerequisite: '',
  },
  742: {
    id: '742',
    label: 'Cast a Blast Spell',
    description: 'Cast any blast spell.',
    skillReqs: [{ skill: 'Magic', level: 41 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  743: {
    id: '743',
    label: 'Cast High Level Alchemy',
    description: 'Cast the High Level Alchemy spell.',
    skillReqs: [{ skill: 'Magic', level: 55 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  651: {
    id: '651',
    label: 'Catch 100 Lobsters',
    description: 'Catch 100 Raw Lobsters whilst Fishing.',
    skillReqs: [{ skill: 'Fishing', level: 40 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  653: {
    id: '653',
    label: 'Catch 50 Salmon',
    description: 'Catch 50 Raw Salmon whilst Fishing.',
    skillReqs: [{ skill: 'Fishing', level: 30 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  652: {
    id: '652',
    label: 'Catch 50 Swordfish',
    description: 'Catch 50 Raw Swordfish whilst Fishing.',
    skillReqs: [{ skill: 'Fishing', level: 50 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  690: {
    id: '690',
    label: 'Catch a Swamp Lizard or Salamander',
    description: 'Catch either a Swamp Lizard or any kind of Salamander.',
    skillReqs: [
      { skill: 'Hunter', level: 29 },
      { skill: 'Hunter', level: 47 },
      { skill: 'Hunter', level: 59 },
      { skill: 'Hunter', level: 67 },
    ],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  591: {
    id: '591',
    label: 'Check a grown Fruit Tree',
    description: "Check the health of any Fruit Tree you've grown.",
    skillReqs: [{ skill: 'Farming', level: 27 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  598: {
    id: '598',
    label: 'Chop 100 Willow Logs',
    description: 'Chop 100 Willow Logs from Willow Trees.',
    skillReqs: [{ skill: 'Woodcutting', level: 30 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  599: {
    id: '599',
    label: 'Chop Some Logs With a Rune Axe',
    description: 'Chop any kind of logs using a Rune Axe.',
    skillReqs: [{ skill: 'Woodcutting', level: 41 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  1573: {
    id: '1573',
    label: 'Chop some Rising Roots',
    description: 'Chop some Rising Roots spawned via Forestry.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  703: {
    id: '703',
    label: 'Clean a Grimy Avantoe',
    description: 'Clean a Grimy Avantoe.',
    skillReqs: [{ skill: 'Herblore', level: 48 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  697: {
    id: '697',
    label: 'Complete 50 Laps of a Rooftop Agility Course',
    description: 'Complete 50 laps of any Rooftop Agility Course.',
    skillReqs: [{ skill: 'Agility', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '696',
  },
  1563: {
    id: '1563',
    label: 'Complete 50 Mahogany homes contracts',
    description: 'Complete 50 Mahogany homes contracts.',
    skillReqs: [{ skill: 'Construction', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  1561: {
    id: '1561',
    label: 'Complete a Mahogany homes adept contract',
    description: 'Complete a Mahogany homes adept contract.',
    skillReqs: [{ skill: 'Construction', level: 50 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  1559: {
    id: '1559',
    label: 'Complete a Mahogany homes beginner contract',
    description: 'Complete a Minigame Mahogany homes beginner contract.',
    skillReqs: [{ skill: 'Construction', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  1560: {
    id: '1560',
    label: 'Complete a Mahogany homes novice contract',
    description: 'Complete a Mahogany homes novice contract.',
    skillReqs: [{ skill: 'Construction', level: 20 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  1086: {
    id: '1086',
    label: 'Complete the Easy Western Provinces Diary',
    description: 'Complete all of the easy tasks in the Diary Western Provinces Achievement Diary.',
    skillReqs: [],
    regions: ['Kandarin', 'Tirannwn'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.EASY,
    prerequisite: '',
  },
  1575: {
    id: '1575',
    label: 'Complete the Flowering Bush event',
    description: 'Complete the Flowering Bush event spawned via Forestry.',
    skillReqs: [{ skill: 'Woodcutting', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  1087: {
    id: '1087',
    label: 'Complete the Medium Western Provinces Diary',
    description: 'Complete all of the medium tasks in the Diary Western Provinces Achievement Diary.',
    skillReqs: [],
    regions: ['Kandarin', 'Tirannwn'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.MEDIUM,
    prerequisite: '',
  },
  1877: {
    id: '1877',
    label: 'Complete the Pheasant Forestry Event',
    description: 'Complete the Pheasant event spawned via Forestry.',
    skillReqs: [{ skill: 'Woodcutting', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  1876: {
    id: '1876',
    label: 'Complete the Ritual Forestry Event',
    description: 'Complete the Ritual event spawned via Forestry.',
    skillReqs: [{ skill: 'Woodcutting', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  1574: {
    id: '1574',
    label: 'Complete the Struggling Sapling event',
    description: 'Complete the Struggling Sapling event spawned via Forestry.',
    skillReqs: [{ skill: 'Woodcutting', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  606: {
    id: '606',
    label: 'Cook 100 Lobsters',
    description: 'Cook 100 Raw Lobsters.',
    skillReqs: [{ skill: 'Cooking', level: 40 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  642: {
    id: '642',
    label: 'Craft 100 Unpowered Orbs',
    description: 'Craft 100 Unpowered Orbs.',
    skillReqs: [{ skill: 'Crafting', level: 46 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '',
  },
  675: {
    id: '675',
    label: 'Craft 200 Essence Into Runes',
    description: 'Use Runecrafting Altars to craft 200 essence into runes of any type.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  676: {
    id: '676',
    label: 'Craft 4 Runes With 1 Essence',
    description: 'Use a Runecrafting Altar to craft 4 of any type of rune using a single essence.',
    skillReqs: [
      { skill: 'Runecraft', level: 57 },
      { skill: 'Runecraft', level: 33 },
    ],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  644: {
    id: '644',
    label: 'Craft a Ruby Amulet',
    description: 'Craft a Ruby Amulet.',
    skillReqs: [{ skill: 'Crafting', level: 50 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '',
  },
  643: {
    id: '643',
    label: 'Craft an Emerald Ring',
    description: 'Craft an Emerald Ring.',
    skillReqs: [{ skill: 'Crafting', level: 27 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '',
  },
  677: {
    id: '677',
    label: 'Craft Any Combination Rune',
    description: 'Use a Runecrafting Altar to craft any type of combination rune.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  1709: {
    id: '1709',
    label: "Create a green d'hide shield",
    description: "Create a green d'hide shield.",
    skillReqs: [{ skill: 'Crafting', level: 62 }],
    regions: ['Wilderness', 'Kandarin', 'Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '',
  },
  1726: {
    id: '1726',
    label: 'Create a Guthix Rest Tea',
    description: 'Create a Guthix Rest Tea.',
    skillReqs: [{ skill: 'Herblore', level: 18 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  620: {
    id: '620',
    label: 'Create a Mithril Grapple',
    description: 'Create a Mithril Grapple.',
    skillReqs: [
      { skill: 'Fletching', level: 59 },
      { skill: 'Smithing', level: 59 },
    ],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FLETCHING,
    prerequisite: '',
  },
  211: {
    id: '211',
    label: 'Defeat a Lesser Demon',
    description: 'Defeat a Lesser Demon.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  630: {
    id: '630',
    label: 'Defeat a Superior Slayer Creature',
    description: 'Defeat any Superior Slayer Creature.',
    skillReqs: [{ skill: 'Slayer', level: 5 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  201: {
    id: '201',
    label: 'Defeat an Adult Chromatic Dragon',
    description: 'Defeat either an adult Green Dragon, Blue Dragon, Red Dragon or Black Dragon.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1693: {
    id: '1693',
    label: 'Equip a frog mask',
    description: 'Equip a frog mask.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  726: {
    id: '726',
    label: 'Equip a Full Adamant Set',
    description:
      'Equip an Adamant Platebody, a Adamant Full Helm and either some Adamant Platelegs or an Adamant Plateskirt.',
    skillReqs: [{ skill: 'Defence', level: 30 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.DEFENCE,
    prerequisite: '',
  },
  731: {
    id: '731',
    label: 'Equip a Full Blue Dragonhide Set',
    description: 'Equip a Blue Dragonhide Body, some Blue Dragonhide Chaps and some Blue Dragonhide Vambraces.',
    skillReqs: [
      { skill: 'Ranged', level: 50 },
      { skill: 'Defence', level: 40 },
    ],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.RANGED,
    prerequisite: '',
  },
  730: {
    id: '730',
    label: 'Equip a Full Green Dragonhide Set',
    description: 'Equip a Green Dragonhide Body, some Green Dragonhide Chaps and some Green Dragonhide Vambraces.',
    skillReqs: [
      { skill: 'Ranged', level: 40 },
      { skill: 'Defence', level: 40 },
    ],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.RANGED,
    prerequisite: '',
  },
  732: {
    id: '732',
    label: 'Equip a Full Red Dragonhide Set',
    description: 'Equip a Red Dragonhide Body, some Red Dragonhide Chaps and some Red Dragonhide Vambraces.',
    skillReqs: [
      { skill: 'Ranged', level: 60 },
      { skill: 'Defence', level: 40 },
    ],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.RANGED,
    prerequisite: '',
  },
  727: {
    id: '727',
    label: 'Equip a Full Rune Set',
    description: 'Equip a Rune Platebody, a Rune Full Helm and either some Rune Platelegs or a Rune Plateskirt.',
    skillReqs: [{ skill: 'Defence', level: 40 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.DEFENCE,
    prerequisite: '',
  },
  910: {
    id: '910',
    label: 'Equip a Granite Shield',
    description: 'Equip a Granite Shield.',
    skillReqs: [
      { skill: 'Defence', level: 50 },
      { skill: 'Strength', level: 50 },
    ],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.DEFENCE,
    prerequisite: '',
  },
  683: {
    id: '683',
    label: 'Equip a Harpie Bug Lantern',
    description: 'Equip a Harpie Bug Lantern.',
    skillReqs: [
      { skill: 'Firemaking', level: 33 },
      { skill: 'Slayer', level: 33 },
    ],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  1341: {
    id: '1341',
    label: 'Equip a Lava Battlestaff',
    description: 'Equip a Lava Battlestaff.',
    skillReqs: [
      { skill: 'Attack', level: 30 },
      { skill: 'Magic', level: 30 },
    ],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  936: {
    id: '936',
    label: 'Equip a Leaf-Bladed Sword',
    description: 'Equip a Leaf-Bladed Sword.',
    skillReqs: [
      { skill: 'Attack', level: 50 },
      { skill: 'Slayer', level: 55 },
    ],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  258: {
    id: '258',
    label: 'Equip a Piece of a Mystic Set',
    description: 'Equip any piece of any Mystic robe set.',
    skillReqs: [
      { skill: 'Magic', level: 40 },
      { skill: 'Defence', level: 20 },
    ],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  722: {
    id: '722',
    label: 'Equip a Rune Weapon',
    description: 'Equip any Rune weapon.',
    skillReqs: [{ skill: 'Attack', level: 40 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.ATTACK,
    prerequisite: '',
  },
  19: {
    id: '19',
    label: 'Equip a Trimmed Amulet',
    description: 'Equip a Trimmed Amulet.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.GENERAL,
    prerequisite: '',
  },
  257: {
    id: '257',
    label: 'Equip a Wizard Robe and Hat',
    description: 'Equip any wizard robe along with any wizard hat.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  735: {
    id: '735',
    label: 'Equip a Yew Shortbow',
    description: 'Equip a Yew Shortbow.',
    skillReqs: [{ skill: 'Ranged', level: 40 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.RANGED,
    prerequisite: '',
  },
  721: {
    id: '721',
    label: 'Equip an Adamant Weapon',
    description: 'Equip any Adamant weapon.',
    skillReqs: [{ skill: 'Attack', level: 30 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.ATTACK,
    prerequisite: '',
  },
  741: {
    id: '741',
    label: 'Equip an Elemental Battlestaff or Mystic Staff',
    description: 'Equip either an elemental battlestaff or an elemental mystic staff.',
    skillReqs: [
      { skill: 'Magic', level: 30 },
      { skill: 'Attack', level: 30 },
    ],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  689: {
    id: '689',
    label: 'Equip full Graahk, Larupia or Kyatt',
    description: 'Equip a full set of Graahk, Larupia or Kyatt attire.',
    skillReqs: [
      { skill: 'Hunter', level: 38 },
      { skill: 'Hunter', level: 28 },
      { skill: 'Hunter', level: 52 },
    ],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  605: {
    id: '605',
    label: 'Ferment 100 Jugs of Wine',
    description: 'Successfully ferment 100 Jugs of Wine.',
    skillReqs: [{ skill: 'Cooking', level: 35 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  30: {
    id: '30',
    label: 'Fill 3 Elite Clue Collection Log Slots',
    description: 'Fill 3 slots in the Elite Clue section of the Collection Log.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.ELITE,
    prerequisite: '',
  },
  49: {
    id: '49',
    label: 'Fill 3 Hard Clue Collection Log Slots',
    description: 'Fill 3 slots in the Hard Clue section of the Collection Log.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.HARD,
    prerequisite: '',
  },
  84: {
    id: '84',
    label: 'Fill 5 Beginner Clue Collection Log Slots',
    description: 'Fill 5 slots in the Beginner Clue section of the Collection Log.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.BEGINNER,
    prerequisite: '',
  },
  76: {
    id: '76',
    label: 'Fill 5 Easy Clue Collection Log Slots',
    description: 'Fill 5 slots in the Easy Clue section of the Collection Log.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.EASY,
    prerequisite: '',
  },
  57: {
    id: '57',
    label: 'Fill 5 Medium Clue Collection Log Slots',
    description: 'Fill 5 slots in the Medium Clue section of the Collection Log.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.MEDIUM,
    prerequisite: '',
  },
  590: {
    id: '590',
    label: 'Fill a Bucket With Supercompost',
    description: 'Fill a Bucket with Supercompost from a Compost Bin.',
    skillReqs: [{ skill: 'Farming', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  1312: {
    id: '1312',
    label: 'Fill a Large Pouch',
    description: 'Fill a Large Pouch with Essence.',
    skillReqs: [{ skill: 'Runecraft', level: 50 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  669: {
    id: '669',
    label: 'Fill a Medium S.T.A.S.H. Unit',
    description: 'Build a Medium S.T.A.S.H. unit and fill it with the relevant items.',
    skillReqs: [{ skill: 'Construction', level: 42 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.GENERAL,
    prerequisite: '',
  },
  1717: {
    id: '1717',
    label: 'Fletch 100 Yew shortbows',
    description: 'Fletch 100 Yew shortbows.',
    skillReqs: [{ skill: 'Fletching', level: 65 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FLETCHING,
    prerequisite: '',
  },
  619: {
    id: '619',
    label: 'Fletch a Willow Shortbow',
    description: 'Fletch a Willow Shortbow.',
    skillReqs: [{ skill: 'Fletching', level: 35 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FLETCHING,
    prerequisite: '',
  },
  621: {
    id: '621',
    label: 'Fletch some Broad Arrows or Bolts',
    description: 'Fletch either some Broad Arrows or some Broad Bolts.',
    skillReqs: [{ skill: 'Fletching', level: 52 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FLETCHING,
    prerequisite: '',
  },
  1691: {
    id: '1691',
    label: 'Fully deplete a Blood Essence',
    description: 'Fully deplete a Blood Essence.',
    skillReqs: [{ skill: 'Runecraft', level: 77 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  148: {
    id: '148',
    label: 'Gain 10 Unique Items From Beginner Clues',
    description: 'Gain 10 unique items from Beginner Clue Scroll Reward Caskets.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.BEGINNER,
    prerequisite: '175',
  },
  139: {
    id: '139',
    label: 'Gain 10 Unique Items From Easy Clues',
    description: 'Gain 10 unique items from Easy Clue Scroll Reward Caskets.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.EASY,
    prerequisite: '165',
  },
  130: {
    id: '130',
    label: 'Gain 10 Unique Items From Medium Clues',
    description: 'Gain 10 unique items from Medium Clue Scroll Reward Caskets.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.MEDIUM,
    prerequisite: '166',
  },
  121: {
    id: '121',
    label: 'Gain 5 Unique Items From Hard Clues',
    description: 'Gain 5 unique items from Hard Clue Scroll Reward Caskets.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.HARD,
    prerequisite: '156',
  },
  156: {
    id: '156',
    label: 'Gain a Unique Item From a Hard Clue',
    description: 'Gain a unique item from a Hard Clue Scroll Reward Casket.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.HARD,
    prerequisite: '',
  },
  166: {
    id: '166',
    label: 'Gain a Unique Item From a Medium Clue',
    description: 'Gain a unique item from a Medium Clue Scroll Reward Casket.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.MEDIUM,
    prerequisite: '',
  },
  589: {
    id: '589',
    label: 'Harvest an Irit Leaf',
    description: 'Harvest an Irit Leaf from any Herb patch.',
    skillReqs: [{ skill: 'Farming', level: 44 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  1659: {
    id: '1659',
    label: 'Kill 6 cows in 10 seconds',
    description: 'Kill 6 cows in 10 seconds.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1637: {
    id: '1637',
    label: 'Kill three chickens in 6 seconds.',
    description: 'Kill three chickens in 6 seconds.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  684: {
    id: '684',
    label: 'Light a Bullseye Lantern',
    description: 'Light a Bullseye Lantern.',
    skillReqs: [{ skill: 'Firemaking', level: 49 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FIREMAKING,
    prerequisite: '',
  },
  702: {
    id: '702',
    label: 'Make 30 Prayer Potions',
    description: 'Make 30 Prayer Potions.',
    skillReqs: [{ skill: 'Herblore', level: 38 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  704: {
    id: '704',
    label: 'Make a 4 dose potion',
    description: 'Make any 4 dose potion using an Amulet of Chemistry.',
    skillReqs: [{ skill: 'Herblore', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  607: {
    id: '607',
    label: 'Make a Pineapple Pizza',
    description: 'Make a Pineapple Pizza.',
    skillReqs: [{ skill: 'Cooking', level: 65 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  612: {
    id: '612',
    label: 'Mine 50 Iron Ore',
    description: 'Mine 50 Iron Ore.',
    skillReqs: [{ skill: 'Mining', level: 15 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  613: {
    id: '613',
    label: 'Mine some Ore With a Rune Pickaxe',
    description: 'Mine any ore using a Rune Pickaxe.',
    skillReqs: [{ skill: 'Mining', level: 41 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  583: {
    id: '583',
    label: 'Obtain 800 Coins From Coin Pouches At Once',
    description: 'Open a stack of Coin Pouches and obtain at least 800 Coins.',
    skillReqs: [{ skill: 'Thieving', level: 40 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  614: {
    id: '614',
    label: 'Obtain a Clue Geode While Mining',
    description: 'Obtain any kind of clue geode whilst Mining a rock.',
    skillReqs: [{ skill: 'Mining', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  718: {
    id: '718',
    label: 'Perform a Special Attack',
    description: 'Perform any special attack.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  582: {
    id: '582',
    label: 'Pickpocket a Guard',
    description: 'Pickpocket a Guard.',
    skillReqs: [{ skill: 'Thieving', level: 40 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  281: {
    id: '281',
    label: 'Pickpocket a Master Farmer',
    description: 'Successfully pickpocket from a Master farmer.',
    skillReqs: [{ skill: 'Thieving', level: 38 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  584: {
    id: '584',
    label: 'Pickpocket a Ranarr Seed',
    description: 'Pickpocket a Ranarr Seed from a Master Farmer.',
    skillReqs: [{ skill: 'Thieving', level: 38 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  408: {
    id: '408',
    label: 'Reach a Prayer Bonus of 15',
    description: 'Equip enough item to reach a Prayer bonus of 15 or more.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.PRAYER,
    prerequisite: '',
  },
  524: {
    id: '524',
    label: 'Reach Base Level 30',
    description: 'Reach level 30 in every skill.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.BASE,
    prerequisite: '522',
  },
  525: {
    id: '525',
    label: 'Reach Base Level 40',
    description: 'Reach level 40 in every skill.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.BASE,
    prerequisite: '524',
  },
  526: {
    id: '526',
    label: 'Reach Base Level 50',
    description: 'Reach level 50 in every skill.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.BASE,
    prerequisite: '525',
  },
  711: {
    id: '711',
    label: 'Reach Combat Level 50',
    description: 'Reach Combat Level 50.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.LEVEL,
    prerequisite: '710',
  },
  712: {
    id: '712',
    label: 'Reach Combat Level 75',
    description: 'Reach Combat Level 75.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.LEVEL,
    prerequisite: '711',
  },
  513: {
    id: '513',
    label: 'Reach Total Level 1000',
    description: 'Reach a Total Level of 1000.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.TOTAL,
    prerequisite: '512',
  },
  514: {
    id: '514',
    label: 'Reach Total Level 1250',
    description: 'Reach a Total Level of 1250.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.TOTAL,
    prerequisite: '513',
  },
  512: {
    id: '512',
    label: 'Reach Total Level 750',
    description: 'Reach a Total Level of 750.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.TOTAL,
    prerequisite: '511',
  },
  1661: {
    id: '1661',
    label: 'Read a prayer book near a lectern',
    description: 'Read a Prayer book near a lectern.',
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  628: {
    id: '628',
    label: 'Slay 250 Creatures',
    description: 'Slay 250 creatures whilst on a Slayer Task.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  660: {
    id: '660',
    label: 'Smelt a Steel Bar',
    description: 'Use a Furnace to smelt a Steel Bar.',
    skillReqs: [{ skill: 'Smithing', level: 30 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  1733: {
    id: '1733',
    label: 'Smith 10 Steel bolts (unf)',
    description: 'Use an Anvil to smith 10 Steel bolts (unf).',
    skillReqs: [{ skill: 'Smithing', level: 33 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  659: {
    id: '659',
    label: 'Smith a Steel Platebody',
    description: 'Use an Anvil to smith a Steel Platebody.',
    skillReqs: [{ skill: 'Smithing', level: 48 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  740: {
    id: '740',
    label: 'Teleport Using Law Runes',
    description: 'Cast any teleport spell that uses Law Runes.',
    skillReqs: [
      { skill: 'Magic', level: 25 },
      { skill: 'Magic', level: 6 },
    ],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  695: {
    id: '695',
    label: 'Use the Protect from Melee Prayer',
    description: 'Use the Protect from Melee Prayer.',
    skillReqs: [{ skill: 'Prayer', level: 43 }],
    regions: ['General'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.PRAYER,
    prerequisite: '',
  },
  1127: {
    id: '1127',
    label: "Charge an Amulet of Glory in the Heroes' Guild",
    description: "Charge an Amulet of Glory at the Fountain of Heroes in the Heroes' Guild.",
    skillReqs: [
      { skill: 'Hunter', level: 83 },
      { skill: 'Crafting', level: 80 },
      { skill: 'Magic', level: 68 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1151: {
    id: '1151',
    label: 'Complete a Game of Intermediate Pest Control',
    description: 'Complete a game of Intermediate Minigame Pest Control or higher.',
    skillReqs: [{ skill: 'Combat', level: 70 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.PC,
    prerequisite: '',
  },
  1142: {
    id: '1142',
    label: 'Complete A Porcine of Interest',
    description: 'Complete the Quest A Porcine of Interest quest.',
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.NOVICE,
    prerequisite: '',
  },
  1769: {
    id: '1769',
    label: 'Complete Below Ice Mountain',
    description: 'Complete Quest Below Ice Mountain quest.',
    skillReqs: [],
    regions: ['Asgarnia', 'Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.NOVICE,
    prerequisite: '',
  },
  806: {
    id: '806',
    label: 'Complete Death to the Dorgeshuun',
    description: 'Complete the Quest Death to the Dorgeshuun quest.',
    skillReqs: [
      { skill: 'Agility', level: 23 },
      { skill: 'Thieving', level: 23 },
    ],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.INTERMEDIATE,
    prerequisite: '',
  },
  1138: {
    id: '1138',
    label: 'Complete the Easy Falador Diary',
    description: 'Complete all of the easy tasks in the Diary Falador Achievement Diary.',
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.EASY,
    prerequisite: '',
  },
  1091: {
    id: '1091',
    label: 'Complete the Falador Agility Course',
    description: 'Complete a lap of the Falador Rooftop Agility Course.',
    skillReqs: [{ skill: 'Agility', level: 50 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  804: {
    id: '804',
    label: 'Complete The Lost Tribe',
    description: 'Complete Quest The Lost Tribe quest.',
    skillReqs: [
      { skill: 'Agility', level: 13 },
      { skill: 'Thieving', level: 13 },
      { skill: 'Mining', level: 17 },
    ],
    regions: ['Asgarnia', 'Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.INTERMEDIATE,
    prerequisite: '',
  },
  1139: {
    id: '1139',
    label: 'Complete the Medium Falador Diary',
    description: 'Complete all of the medium tasks in the Diary Falador Achievement Diary.',
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.MEDIUM,
    prerequisite: '',
  },
  1098: {
    id: '1098',
    label: 'Craft a Body Rune',
    description: 'Craft a Essence at the Body Altar.',
    skillReqs: [{ skill: 'Runecraft', level: 20 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  1409: {
    id: '1409',
    label: 'Craft a Chaos Rune using a Chaos Core',
    description: 'Craft a Chaos Rune using a Chaos Core.',
    skillReqs: [{ skill: 'Runecraft', level: 35 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  1129: {
    id: '1129',
    label: 'Defeat 30 Black Dragons in Asgarnia',
    description: 'Defeat 30 Black Dragons in Asgarnia.',
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1110: {
    id: '1110',
    label: 'Defeat a Skeletal Wyvern',
    description: 'Defeat a Skeletal Wyvern in the Asgarnian Ice Dungeon.',
    skillReqs: [{ skill: 'Slayer', level: 72 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  1148: {
    id: '1148',
    label: 'Defeat Some Animated Rune Armour',
    description: "Defeat Some Animated Rune Armour in the Warriors' Guild.",
    skillReqs: [
      { skill: 'Attack', level: 99 },
      { skill: 'Strength', level: 99 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1136: {
    id: '1136',
    label: 'Defeat the Giant Mole 150 Times',
    description: 'Defeat the Giant Mole beneath Falador 150 times.',
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.MOLE,
    prerequisite: '1135',
  },
  1135: {
    id: '1135',
    label: 'Defeat the Giant Mole 50 Times',
    description: 'Defeat the Giant Mole beneath Falador 50 times.',
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.MOLE,
    prerequisite: '1105',
  },
  1105: {
    id: '1105',
    label: 'Defeat the Giant Mole',
    description: 'Defeat the Giant Mole beneath Falador.',
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.MOLE,
    prerequisite: '',
  },
  1096: {
    id: '1096',
    label: 'Enter the Crafting Guild',
    description: 'Enter the Crafting Guild.',
    skillReqs: [{ skill: 'Crafting', level: 40 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '',
  },
  1147: {
    id: '1147',
    label: "Enter the Warriors' Guild",
    description: "Enter the Warriors' Guild.",
    skillReqs: [
      { skill: 'Attack', level: 99 },
      { skill: 'Strength', level: 99 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  788: {
    id: '788',
    label: 'Equip a Dorgeshuun Crossbow',
    description: 'Equip a Dorgeshuun Crossbow.',
    skillReqs: [{ skill: 'Ranged', level: 28 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.RANGED,
    prerequisite: '',
  },
  1607: {
    id: '1607',
    label: "Equip Amy's Saw",
    description: "Obtain and Equip Amy's Saw from Mahogany Homes.",
    skillReqs: [{ skill: 'Construction', level: 1 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  1115: {
    id: '1115',
    label: 'Equip Some Granite Legs',
    description: 'Equip some Granite Legs.',
    skillReqs: [
      { skill: 'Defence', level: 50 },
      { skill: 'Strength', level: 50 },
      { skill: 'Slayer', level: 72 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  1102: {
    id: '1102',
    label: 'Harvest Any Herb at the Troll Stronghold',
    description: "Harvest any herb you've grown at the Troll Stronghold.",
    skillReqs: [{ skill: 'Farming', level: 9 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  1101: {
    id: '1101',
    label: 'Make 20 Stamina Potions',
    description: 'Make 20 Stamina Potions.',
    skillReqs: [{ skill: 'Herblore', level: 77 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  1696: {
    id: '1696',
    label: 'Make 50 Ancient Brews',
    description: 'Make 50 Ancient Brews.',
    skillReqs: [{ skill: 'Herblore', level: 85 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  1094: {
    id: '1094',
    label: 'Obtain 20 Golden Nuggets',
    description: 'Obtain 20 Golden Nuggets from the Motherlode Mine beneath Falador.',
    skillReqs: [{ skill: 'Mining', level: 30 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  1608: {
    id: '1608',
    label: 'Obtain the Plank Sack',
    description: 'Obtain the Plank Sack from Minigame Mahogany Homes.',
    skillReqs: [{ skill: 'Construction', level: 1 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  1153: {
    id: '1153',
    label: 'Open the Crystal Chest',
    description: 'Open the Crystal Chest in Taverley.',
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  767: {
    id: '767',
    label: 'Pickpocket a Bullseye Lantern From a Cave Goblin',
    description: 'Pickpocket a Bullseye Lantern from a Cave Goblin.',
    skillReqs: [{ skill: 'Thieving', level: 36 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1145: {
    id: '1145',
    label: 'Turn in 100 Mole Claws to Wyson the Gardener',
    description: 'Turn in 100 Mole Claws to Wyson the Gardener in Falador.',
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.MOLE,
    prerequisite: '',
  },
  1425: {
    id: '1425',
    label: '50 Tempoross Kills',
    description: 'Help the Spirit Anglers subdue the Tempoross 50 times.',
    skillReqs: [{ skill: 'Fishing', level: 35 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.TEMPOROSS,
    prerequisite: '1424',
  },
  1206: {
    id: '1206',
    label: 'Cast Bones to Peaches',
    description: 'Cast the Bones to Peaches spell.',
    skillReqs: [{ skill: 'Magic', level: 60 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  1191: {
    id: '1191',
    label: 'Cast Ice Rush',
    description: 'Cast the Ice Rush spell.',
    skillReqs: [{ skill: 'Magic', level: 58 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  1182: {
    id: '1182',
    label: 'Catch 30 Orange Salamanders',
    description: 'Catch 30 Orange Salamanders at Uzer.',
    skillReqs: [{ skill: 'Hunter', level: 47 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1180: {
    id: '1180',
    label: 'Check a grown Cactus',
    description: "Check the health of a Cactus you've grown in Al Kharid.",
    skillReqs: [{ skill: 'Farming', level: 55 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  1220: {
    id: '1220',
    label: 'Complete Shadow of the Storm',
    description: 'Complete the Quest Shadow of the Storm quest.',
    skillReqs: [{ skill: 'Crafting', level: 30 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.INTERMEDIATE,
    prerequisite: '',
  },
  1765: {
    id: '1765',
    label: 'Complete Sleeping Giants',
    description: 'Complete Quest Sleeping Giants quest.',
    skillReqs: [{ skill: 'Smithing', level: 15 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.INTERMEDIATE,
    prerequisite: '',
  },
  1210: {
    id: '1210',
    label: 'Complete Spirits of the Elid',
    description: 'Complete the Quest Spirits of the Elid quest.',
    skillReqs: [
      { skill: 'Magic', level: 33 },
      { skill: 'Ranged', level: 37 },
      { skill: 'Mining', level: 37 },
      { skill: 'Thieving', level: 37 },
    ],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.INTERMEDIATE,
    prerequisite: '',
  },
  1201: {
    id: '1201',
    label: 'Complete the Easy Desert Diary',
    description: 'Complete all of the easy tasks in the Diary Desert Achievement Diary.',
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.EASY,
    prerequisite: '',
  },
  1202: {
    id: '1202',
    label: 'Complete the Medium Desert Diary',
    description: 'Complete all of the medium tasks in the Diary Desert Achievement Diary.',
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.MEDIUM,
    prerequisite: '',
  },
  1178: {
    id: '1178',
    label: 'Craft a Lava Rune at the Fire Altar',
    description: 'Craft a Lava Rune at the Fire Altar.',
    skillReqs: [{ skill: 'Runecraft', level: 23 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  1593: {
    id: '1593',
    label: 'Create the Divine Rune pouch',
    description: 'Create the Divine Rune pouch.',
    skillReqs: [{ skill: 'Crafting', level: 75 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOA,
    prerequisite: '',
  },
  1374: {
    id: '1374',
    label: 'Defeat a Crocodile',
    description: 'Defeat a Crocodile in the Kharidian Desert.',
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1186: {
    id: '1186',
    label: 'Defeat a Dust Devil in the Kharidian Desert',
    description: 'Defeat a Dust Devil in the Kharidian Desert.',
    skillReqs: [{ skill: 'Slayer', level: 65 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  1188: {
    id: '1188',
    label: 'Defeat a Kalphite Guardian',
    description: 'Defeat a Kalphite Guardian in the Kharidian Desert.',
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1185: {
    id: '1185',
    label: 'Defeat a Scarab Mage',
    description: 'Defeat a Scarab Mage.',
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1595: {
    id: '1595',
    label: 'Equip the Amulet of the eye under the Portal',
    description:
      'Obtain and Equip the Amulet of the eye from Guardians of the Rift whilst underneath the portal in the Desert.',
    skillReqs: [{ skill: 'Runecraft', level: 10 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  1603: {
    id: '1603',
    label: 'Equip the Colossal Blade',
    description: "Obtain and Equip the Colossal Blade from Giant's Foundry.",
    skillReqs: [
      { skill: 'Smithing', level: 15 },
      { skill: 'Attack', level: 60 },
    ],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  1599: {
    id: '1599',
    label: 'Equip the Ring of the elements',
    description: 'Obtain and Equip the Ring of the elements from Guardians of the Rift.',
    skillReqs: [{ skill: 'Runecraft', level: 10 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  1606: {
    id: '1606',
    label: 'Equip the Tome of Water',
    description: 'Obtain and Equip the Tome of Water from Tempoross.',
    skillReqs: [
      { skill: 'Fishing', level: 35 },
      { skill: 'Magic', level: 50 },
    ],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.TEMPOROSS,
    prerequisite: '',
  },
  1568: {
    id: '1568',
    label: "Giants' Foundry 100 quality sword",
    description: "Hand in a sword with at least 100 quality in Giants' Foundry.",
    skillReqs: [{ skill: 'Smithing', level: 50 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '1567',
  },
  1570: {
    id: '1570',
    label: "Giants' Foundry 25 hand-ins",
    description: 'Hand in 25 successful swords to Kovac.',
    skillReqs: [{ skill: 'Smithing', level: 15 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  1567: {
    id: '1567',
    label: "Giants' Foundry 50 quality sword",
    description: "Hand in a sword with at least 50 quality in Giants' Foundry.",
    skillReqs: [{ skill: 'Smithing', level: 15 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '1570',
  },
  1546: {
    id: '1546',
    label: 'Guardians of the Rift 1 Rift closed',
    description: 'Close the rift in the temple of the eye.',
    skillReqs: [{ skill: 'Runecraft', level: 10 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  1576: {
    id: '1576',
    label: 'Kill a Kalphite with the Keris Partisan',
    description: 'Kill a Kalphite with the Keris Partisan.',
    skillReqs: [{ skill: 'Attack', level: 65 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1376: {
    id: '1376',
    label: 'Make a Combat Potion',
    description: 'Make a Combat Potion.',
    skillReqs: [{ skill: 'Herblore', level: 36 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  1183: {
    id: '1183',
    label: 'Mine 30 Chunks of Granite',
    description: 'Mine 30 chunks of Quarry.',
    skillReqs: [{ skill: 'Mining', level: 45 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  1373: {
    id: '1373',
    label: 'Move Your House to Pollnivneach',
    description: 'Move your Player Owned House to Pollnivneach.',
    skillReqs: [{ skill: 'Construction', level: 20 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  1605: {
    id: '1605',
    label: 'Obtain the Big Harpoonfish',
    description: 'Obtain the Big Harpoonfish from Tempoross.',
    skillReqs: [{ skill: 'Fishing', level: 35 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.TEMPOROSS,
    prerequisite: '',
  },
  1167: {
    id: '1167',
    label: "Pick a Autumn Sq'irk",
    description: "Pick a Autumn Sq'irk in the Sorceress's Garden.",
    skillReqs: [{ skill: 'Thieving', level: 45 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1166: {
    id: '1166',
    label: "Pick a Spring Sq'irk",
    description: "Pick a Spring Sq'irk in the Sorceress's Garden.",
    skillReqs: [{ skill: 'Thieving', level: 25 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1173: {
    id: '1173',
    label: 'Pickpocket a Bandit in the Bandit Camp',
    description: "Pickpocket a Bandit in the Kharidian Desert's Bandit Camp.",
    skillReqs: [{ skill: 'Thieving', level: 53 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1214: {
    id: '1214',
    label: 'Pray at the Elidinis Statuette',
    description: 'Pray at the Elidinis Statuette in Nardah.',
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1157: {
    id: '1157',
    label: 'Room 4 of Pyramid Plunder',
    description: 'Search the Golden Chest in Room 4 of Pyramid Plunder in Sophanem.',
    skillReqs: [{ skill: 'Thieving', level: 51 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1158: {
    id: '1158',
    label: 'Room 5 of Pyramid Plunder',
    description: 'Search the Golden Chest in Room 5 of Pyramid Plunder in Sophanem.',
    skillReqs: [{ skill: 'Thieving', level: 61 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1159: {
    id: '1159',
    label: 'Room 6 of Pyramid Plunder',
    description: 'Search the Golden Chest in Room 6 of Pyramid Plunder in Sophanem.',
    skillReqs: [{ skill: 'Thieving', level: 71 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  658: {
    id: '658',
    label: 'Smith 1,000 Mithril Dart Tips',
    description: 'Use an Anvil to smith 1,000 Mithril Dart Tips.',
    skillReqs: [{ skill: 'Smithing', level: 54 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  1170: {
    id: '1170',
    label: "Turn in 10 Spring Sq'irkjuices to Osman",
    description: "Turn in 10 Spring Sq'irkjuices to Osman in Al Kharid in one go.",
    skillReqs: [{ skill: 'Thieving', level: 25 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1176: {
    id: '1176',
    label: 'Turn in a Pyramid Top to Simon Templeton',
    description: 'Turn in a Pyramid Top to Simon Templeton at the Agility Pyramid.',
    skillReqs: [{ skill: 'Agility', level: 30 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  883: {
    id: '883',
    label: 'Cast Moonclan Teleport',
    description: 'Cast the Moonclan Teleport spell.',
    skillReqs: [{ skill: 'Magic', level: 69 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  894: {
    id: '894',
    label: 'Catch a Snowy Knight',
    description: 'Catch a Snowy Knight.',
    skillReqs: [{ skill: 'Hunter', level: 35 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  955: {
    id: '955',
    label: "Complete Olaf's Quest",
    description: "Complete Quest Olaf's Quest.",
    skillReqs: [
      { skill: 'Firemaking', level: 40 },
      { skill: 'Woodcutting', level: 50 },
    ],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.INTERMEDIATE,
    prerequisite: '',
  },
  953: {
    id: '953',
    label: 'Complete Royal Trouble',
    description: 'Complete the Quest Royal Trouble quest.',
    skillReqs: [
      { skill: 'Agility', level: 40 },
      { skill: 'Slayer', level: 40 },
    ],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.EXPERIENCED,
    prerequisite: '',
  },
  943: {
    id: '943',
    label: 'Complete the Easy Fremennik Diary',
    description: 'Complete all of the easy tasks in the Diary Fremennik Achievement Diary.',
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.EASY,
    prerequisite: '',
  },
  944: {
    id: '944',
    label: 'Complete the Medium Fremennik Diary',
    description: 'Complete all of the medium tasks in the Diary Fremennik Achievement Diary.',
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.MEDIUM,
    prerequisite: '',
  },
  891: {
    id: '891',
    label: 'Complete the Penguin Agility Course',
    description: 'Complete a lap of the Penguin Agility Course.',
    skillReqs: [{ skill: 'Agility', level: 30 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  948: {
    id: '948',
    label: 'Complete Throne of Miscellania',
    description: 'Complete the Quest Throne of Miscellania quest.',
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.EXPERIENCED,
    prerequisite: '',
  },
  890: {
    id: '890',
    label: 'Craft 50 Astral Runes',
    description: 'Craft 50 Astral Runes from Essence at the Astral Altar.',
    skillReqs: [{ skill: 'Runecraft', level: 40 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  901: {
    id: '901',
    label: 'Defeat a Basilisk Knight',
    description: "Defeat a Basilisk Knight in the Jormungand's Prison.",
    skillReqs: [{ skill: 'Slayer', level: 60 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  918: {
    id: '918',
    label: 'Defeat a Dagannoth in the Fremennik Province',
    description: 'Defeat a Dagannoth in the Fremennik Province.',
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  931: {
    id: '931',
    label: 'Defeat a Jelly in the Fremennik Province',
    description: 'Defeat a Jelly in the Fremennik Province.',
    skillReqs: [{ skill: 'Slayer', level: 52 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  906: {
    id: '906',
    label: 'Defeat a Kurask in the Fremennik Province',
    description: 'Defeat a Kurask in the Fremennik Province.',
    skillReqs: [{ skill: 'Slayer', level: 70 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  926: {
    id: '926',
    label: 'Defeat a Turoth in the Fremennik Province.',
    description: 'Defeat a Turoth in the Fremennik Province.',
    skillReqs: [{ skill: 'Slayer', level: 55 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  915: {
    id: '915',
    label: 'Equip a Brine Sabre',
    description: 'Equip a Brine Sabre.',
    skillReqs: [
      { skill: 'Attack', level: 40 },
      { skill: 'Slayer', level: 47 },
    ],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  1648: {
    id: '1648',
    label: 'Equip a full set of Yakhide Armour',
    description: 'Equip a full set of Yakhide Armour.',
    skillReqs: [
      { skill: 'Crafting', level: 46 },
      { skill: 'Defence', level: 20 },
    ],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '',
  },
  923: {
    id: '923',
    label: 'Equip a Helm of Neitiznot',
    description: 'Equip a Helm of Neitiznot.',
    skillReqs: [{ skill: 'Defence', level: 55 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1650: {
    id: '1650',
    label: 'Kill 8 penguins within 5 seconds',
    description: 'Kill 8 penguins within 5 seconds.',
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  886: {
    id: '886',
    label: 'Move Your House to Rellekka',
    description: 'Move your Player Owned House to Rellekka.',
    skillReqs: [{ skill: 'Construction', level: 30 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  1686: {
    id: '1686',
    label: 'Reach the lighthouse via stepping stones',
    description: 'Reach the lighthouse via stepping stones.',
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  896: {
    id: '896',
    label: 'Unlock Free Use of the Blast Furnace',
    description: 'Unlock free use of the Keldagrim Blast Furnace by speaking with the Foreman.',
    skillReqs: [{ skill: 'Smithing', level: 60 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  897: {
    id: '897',
    label: 'Use Some Icy Basalt to Teleport to Weiss',
    description: 'Use some Icy Basalt to teleport to Weiss.',
    skillReqs: [{ skill: 'Mining', level: 72 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1019: {
    id: '1019',
    label: 'Catch a Monkfish',
    description: 'Catch a Monkfish at the Piscatoris Fishing Colony.',
    skillReqs: [{ skill: 'Fishing', level: 62 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  1017: {
    id: '1017',
    label: 'Catch a Red Salamander',
    description: 'Catch a Red Salamander outside the Ourania Altar.',
    skillReqs: [{ skill: 'Hunter', level: 59 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1025: {
    id: '1025',
    label: 'Check a grown Papaya Tree in the Gnome Stronghold',
    description: "Check the health of a Papaya Tree you've grown in the Tree Gnome Stronghold.",
    skillReqs: [{ skill: 'Farming', level: 57 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  1065: {
    id: '1065',
    label: 'Complete Elemental Workshop I',
    description: 'Complete the Quest Elemental Workshop I quest.',
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.NOVICE,
    prerequisite: '',
  },
  1067: {
    id: '1067',
    label: 'Complete Elemental Workshop II',
    description: 'Complete the Quest Elemental Workshop II quest.',
    skillReqs: [
      { skill: 'Magic', level: 20 },
      { skill: 'Smithing', level: 30 },
    ],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.INTERMEDIATE,
    prerequisite: '',
  },
  1010: {
    id: '1010',
    label: 'Complete the Barbarian Outpost Agility Course',
    description: 'Complete a lap of the Barbarian Outpost Agility Course.',
    skillReqs: [{ skill: 'Agility', level: 35 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  1383: {
    id: '1383',
    label: 'Complete the Easy Ardougne Diary',
    description: 'Complete all of the easy tasks in the Diary Ardougne Achievement Diary.',
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.EASY,
    prerequisite: '',
  },
  1082: {
    id: '1082',
    label: 'Complete the Easy Kandarin Diary',
    description: 'Complete all of the easy tasks in the Diary Kandarin Achievement Diary.',
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.EASY,
    prerequisite: '',
  },
  1384: {
    id: '1384',
    label: 'Complete the Medium Ardougne Diary',
    description: 'Complete all of the medium tasks in the Diary Ardougne Achievement Diary.',
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.MEDIUM,
    prerequisite: '',
  },
  1083: {
    id: '1083',
    label: 'Complete the Medium Kandarin Diary',
    description: 'Complete all of the medium tasks in the Diary Kandarin Achievement Diary.',
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.MEDIUM,
    prerequisite: '',
  },
  1069: {
    id: '1069',
    label: 'Complete the Observatory Quest',
    description: 'Complete the Quest Observatory Quest.',
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.INTERMEDIATE,
    prerequisite: '',
  },
  1066: {
    id: '1066',
    label: 'Complete Tower of Life',
    description: 'Complete the Quest Tower of Life quest.',
    skillReqs: [{ skill: 'Construction', level: 10 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.NOVICE,
    prerequisite: '',
  },
  1028: {
    id: '1028',
    label: 'Defeat a Mithril Dragon',
    description: 'Defeat a Mithril Dragon in the Ancient Cavern.',
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1031: {
    id: '1031',
    label: 'Defeat a Tortoise With Riders in Kandarin',
    description: 'Defeat a Tortoise with riders in Kandarin.',
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1071: {
    id: '1071',
    label: 'Defeat the Penance Queen',
    description: 'Defeat the Penance Queen in Barbarian Assault.',
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.BA,
    prerequisite: '',
  },
  1042: {
    id: '1042',
    label: 'Defeat the Thermonuclear Smoke Devil',
    description: 'Defeat the Thermonuclear Smoke Devil in the Smoke Devil Dungeon.',
    skillReqs: [{ skill: 'Slayer', level: 93 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.THERMY,
    prerequisite: '',
  },
  1018: {
    id: '1018',
    label: 'Enter the Fishing Guild',
    description: 'Enter the Fishing Guild.',
    skillReqs: [{ skill: 'Fishing', level: 68 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  1037: {
    id: '1037',
    label: 'Enter the Ranging Guild',
    description: 'Enter the Ranging Guild.',
    skillReqs: [{ skill: 'Ranged', level: 40 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.RANGED,
    prerequisite: '',
  },
  1040: {
    id: '1040',
    label: "Enter the Wizards' Guild",
    description: "Enter the Wizards' Guild in Yanille.",
    skillReqs: [{ skill: 'Magic', level: 66 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  1032: {
    id: '1032',
    label: 'Equip a Dragon Scimitar',
    description: 'Equip a Dragon Scimitar.',
    skillReqs: [{ skill: 'Attack', level: 60 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.ATTACK,
    prerequisite: '',
  },
  1077: {
    id: '1077',
    label: 'Equip a Monkey Backpack',
    description: 'Equip a Monkey Backpack.',
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1016: {
    id: '1016',
    label: 'Equip a Spottier Cape',
    description: 'Equip a Spottier Cape.',
    skillReqs: [{ skill: 'Hunter', level: 69 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1058: {
    id: '1058',
    label: "Equip an Iban's Staff (u)",
    description: "Equip an Iban's Staff (u).",
    skillReqs: [
      { skill: 'Magic', level: 50 },
      { skill: 'Attack', level: 50 },
    ],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  1073: {
    id: '1073',
    label: 'Equip an Ogre Forester Chompy Hat',
    description: 'Equip an Ogre Forester Chompy Hat.',
    skillReqs: [
      { skill: 'Fletching', level: 5 },
      { skill: 'Cooking', level: 30 },
      { skill: 'Ranged', level: 30 },
      { skill: 'Crafting', level: 5 },
    ],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.CHOMPIES,
    prerequisite: '1072',
  },
  1799: {
    id: '1799',
    label: 'Kill a Swordchick',
    description: 'Kill a Swordchick underneath the Tower of Life.',
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1024: {
    id: '1024',
    label: 'Move Your House to Yanille',
    description: 'Move your Player Owned House to Yanille.',
    skillReqs: [{ skill: 'Construction', level: 50 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  1007: {
    id: '1007',
    label: 'Pickpocket a Knight of Ardougne 50 Times',
    description: 'Pickpocket a Knight of Ardougne 50 times.',
    skillReqs: [{ skill: 'Thieving', level: 55 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1804: {
    id: '1804',
    label: 'Score a Goal in Gnomeball',
    description: 'Score a Goal in Gnomeball.',
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1802: {
    id: '1802',
    label: 'Sell 20 Iron Sheets',
    description: 'Sell 20 Iron Sheets to Franklin Caranos.',
    skillReqs: [{ skill: 'Smithing', level: 45 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  1034: {
    id: '1034',
    label: 'Set Up a Dwarf Cannon',
    description: 'Set Up a Dwarf Cannon.',
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.RANGED,
    prerequisite: '',
  },
  1801: {
    id: '1801',
    label: 'Spawn a Skeletal Mage',
    description: 'Use bone seeds to spawn a Skeletal Mage.',
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1660: {
    id: '1660',
    label: 'Trade in a cat for death runes',
    description: 'Trade in a cat for death runes in Ardougne.',
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1014: {
    id: '1014',
    label: 'Trap a Spined Larupia in the Feldip Hills',
    description: 'Trap a Spined Larupia in the Feldip Hills.',
    skillReqs: [{ skill: 'Hunter', level: 31 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1049: {
    id: '1049',
    label: 'Use the Chivalry Prayer',
    description: 'Use the Chivalry Prayer.',
    skillReqs: [
      { skill: 'Prayer', level: 60 },
      { skill: 'Defence', level: 65 },
    ],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.PRAYER,
    prerequisite: '',
  },
  1061: {
    id: '1061',
    label: 'Win a Game of Castle Wars',
    description: 'Win a game of Castle Wars.',
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.CWARS,
    prerequisite: '',
  },
  959: {
    id: '959',
    label: 'Buy a Snapdragon From Pirate Jackie the Fruit',
    description: 'Buy a Snapdragon From Pirate Jackie the Fruit in Brimhaven.',
    skillReqs: [{ skill: 'Agility', level: 1 }],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  966: {
    id: '966',
    label: 'Catch 50 Karambwan',
    description: 'Catch 50 Karambwan on Karamja.',
    skillReqs: [{ skill: 'Fishing', level: 65 }],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  965: {
    id: '965',
    label: 'Catch a Salmon on Karamja',
    description: 'Catch a Salmon on Karamja.',
    skillReqs: [{ skill: 'Fishing', level: 30 }],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  994: {
    id: '994',
    label: 'Complete the Easy Karamja Diary',
    description: 'Complete all of the easy tasks in the Diary Karamja Achievement Diary.',
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.EASY,
    prerequisite: '',
  },
  995: {
    id: '995',
    label: 'Complete the Medium Karamja Diary',
    description: 'Complete all of the medium tasks in the Diary Karamja Achievement Diary.',
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.MEDIUM,
    prerequisite: '',
  },
  968: {
    id: '968',
    label: 'Craft 50 Nature Runes',
    description: 'Craft 50 Nature Runes from Essence at the Nature Altar.',
    skillReqs: [{ skill: 'Runecraft', level: 44 }],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  993: {
    id: '993',
    label: 'Defeat a Steel Dragon on Karamja',
    description: 'Defeat a Steel Dragon on Karamja.',
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  999: {
    id: '999',
    label: 'Enter the Tai Bwo Wannai Hardwood Grove',
    description: 'Enter the Hardwood Grove in Tai Bwo Wannai.',
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1005: {
    id: '1005',
    label: 'Equip a Full Tai Bwo Wannai Villager Set',
    description: 'Equip a full set of Tai Bwo Wannai villager clothing.',
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1003: {
    id: '1003',
    label: 'Equip a Matching Tribal Mask and Broodoo Shield',
    description: 'Equip a matching Tribal Mask and Broodoo Shield.',
    skillReqs: [
      { skill: 'Crafting', level: 35 },
      { skill: 'Defence', level: 25 },
      { skill: 'Magic', level: 25 },
    ],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '',
  },
  998: {
    id: '998',
    label: 'Fill a Crate With Bananas',
    description: 'Fill a crate with Bananas for Luthas at Musa Point.',
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1662: {
    id: '1662',
    label: 'Mine 5 gem rocks in a row without failing',
    description: 'Mine 5 gem rocks in a row without failing in Shilo Village.',
    skillReqs: [{ skill: 'Mining', level: 40 }],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  322: {
    id: '322',
    label: '250 Lizardmen Shaman Kills',
    description: 'Help the Shayzien House by killing 250 Lizardmen shamans.',
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  329: {
    id: '329',
    label: '50 Sarachnis Kills',
    description: 'Kill Sarachnis in the Forthos Dungeon 50 times.',
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SARACHNIS,
    prerequisite: '328',
  },
  234: {
    id: '234',
    label: '50 Wintertodt Kills',
    description: 'Help the Pyromancers subdue the Wintertodt 50 times.',
    skillReqs: [{ skill: 'Firemaking', level: 50 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WINTERTODT,
    prerequisite: '233',
  },
  483: {
    id: '483',
    label: 'Cast Kourend Castle Teleport',
    description: 'Cast the spell Kourend Castle Teleport after unlocking it by reading transportation incantations.',
    skillReqs: [{ skill: 'Magic', level: 69 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  286: {
    id: '286',
    label: 'Cast Resurrect Crops',
    description: 'Cast the spell Resurrect Crops from the Arceuus spellbook.',
    skillReqs: [{ skill: 'Magic', level: 78 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  285: {
    id: '285',
    label: 'Chop a Magic Log at the Forsaken Tower',
    description: 'Chop a log from the Magic trees around the Forsaken Tower.',
    skillReqs: [{ skill: 'Woodcutting', level: 75 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  1767: {
    id: '1767',
    label: 'Complete Getting Ahead',
    description: 'Complete Quest Getting Ahead quest.',
    skillReqs: [
      { skill: 'Crafting', level: 30 },
      { skill: 'Construction', level: 26 },
    ],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.NOVICE,
    prerequisite: '',
  },
  1388: {
    id: '1388',
    label: 'Defeat a Drake',
    description: 'Defeat a Drake in the Mount Karuulm Slayer Dungeon.',
    skillReqs: [{ skill: 'Slayer', level: 84 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  276: {
    id: '276',
    label: "Enter the Farming Guild's Mid Tier",
    description: 'Enter the mid tier of the Farming Guild in the Kebos Lowlands.',
    skillReqs: [{ skill: 'Farming', level: 65 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  282: {
    id: '282',
    label: 'Enter the Woodcutting Guild',
    description: 'Enter the Woodcutting Guild in Hosidius.',
    skillReqs: [{ skill: 'Woodcutting', level: 60 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  480: {
    id: '480',
    label: 'Equip Tier 5 Shayzien Armour',
    description: 'Equip a full set of tier 5 Shayzien armour.',
    skillReqs: [{ skill: 'Defence', level: 20 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  482: {
    id: '482',
    label: "Equip Xeric's Talisman",
    description: "Obtain and equip Xeric's talisman.",
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  268: {
    id: '268',
    label: 'Friend of House Arceuus',
    description: 'Achieve 100% favour with the Arceuus House.',
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.FAVOUR,
    prerequisite: '',
  },
  266: {
    id: '266',
    label: 'Friend of House Hosidius',
    description: 'Achieve 100% favour with the Hosidius House.',
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.FAVOUR,
    prerequisite: '',
  },
  270: {
    id: '270',
    label: 'Friend of House Piscarilius',
    description: 'Achieve 100% favour with the Piscarilius House.',
    skillReqs: [{ skill: 'Crafting', level: 30 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.FAVOUR,
    prerequisite: '',
  },
  267: {
    id: '267',
    label: 'Friend of House Shayzien',
    description: 'Achieve 100% favour with the Shayzien House.',
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.FAVOUR,
    prerequisite: '',
  },
  1639: {
    id: '1639',
    label: "Headbang with Ket'Sal K'uk",
    description: "Headbang with Ket'Sal K'uk.",
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1475: {
    id: '1475',
    label: 'Inferior Demonbane Demon Kill',
    description: 'Kill a demon using the Inferior Demonbane spell.',
    skillReqs: [{ skill: 'Magic', level: 44 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  1641: {
    id: '1641',
    label: 'Kill a creature in Mount Quidamortem',
    description: 'Enter Mount Quidamortem and kill a creature without wearing boots for protection.',
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  410: {
    id: '410',
    label: 'Kourend and Kebos Easy Diary Tasks',
    description: 'Complete all tasks in the easy tier of the Diary Kourend and Kebos achievement diary.',
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.EASY,
    prerequisite: '',
  },
  411: {
    id: '411',
    label: 'Kourend and Kebos Medium Diary Tasks',
    description: 'Complete all tasks in the medium tier of the Diary Kourend and Kebos achievement diary.',
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.MEDIUM,
    prerequisite: '',
  },
  283: {
    id: '283',
    label: 'Obtain Adamantite Ore at the Blast Mine',
    description: 'Obtain a piece of Adamantite ore while taking part in the Blast Mine.',
    skillReqs: [{ skill: 'Mining', level: 60 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  416: {
    id: '416',
    label: 'Open 30 Grubby Chests',
    description: 'Open the grubby chest in the Forthos Dungeon 30 times.',
    skillReqs: [{ skill: 'Thieving', level: 57 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '415',
  },
  272: {
    id: '272',
    label: 'Plant 100 Golovanova Seeds',
    description: 'Plant 100 Golovanova seeds in the Tithe Farm minigame.',
    skillReqs: [{ skill: 'Farming', level: 34 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  486: {
    id: '486',
    label: 'Purchase a Seed Box',
    description: 'Purchase a Seed box from the Tithe Farm minigame.',
    skillReqs: [{ skill: 'Farming', level: 34 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  1642: {
    id: '1642',
    label: 'Smelt a mithril bar in a volcanic fissure',
    description: 'Smelt a Mithril bar in a volcanic Fissure.',
    skillReqs: [{ skill: 'Smithing', level: 50 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  422: {
    id: '422',
    label: 'Steal 15 Artefacts',
    description: 'Steal 10 artefacts for Captain Khaled in Piscarilius.',
    skillReqs: [{ skill: 'Thieving', level: 49 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '421',
  },
  423: {
    id: '423',
    label: 'Steal 30 Artefacts',
    description: 'Steal 30 artefacts for Captain Khaled in Piscarilius.',
    skillReqs: [{ skill: 'Thieving', level: 49 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '422',
  },
  381: {
    id: '381',
    label: "Teleport to Xeric's Honour",
    description: "Teleport to Xeric's Honour using Xeric's talisman.",
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  429: {
    id: '429',
    label: 'Turn in 25 Library Books',
    description: 'Find and turn in 25 books in the Arceuus Library.',
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.OTHER,
    prerequisite: '428',
  },
  430: {
    id: '430',
    label: 'Turn in 50 Library Books',
    description: 'Find and turn in 50 books in the Arceuus Library.',
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.OTHER,
    prerequisite: '429',
  },
  754: {
    id: '754',
    label: 'Catch 50 Implings in Puro-Puro',
    description: 'Catch 50 Implings in Puro-Puro.',
    skillReqs: [{ skill: 'Hunter', level: 17 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  774: {
    id: '774',
    label: 'Chop a Sulliuscep Cap',
    description: 'Chop a Sulliuscep Cap on Fossil Island.',
    skillReqs: [{ skill: 'Woodcutting', level: 65 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  769: {
    id: '769',
    label: 'Complete 10 Laps of the Draynor Agility Course',
    description: 'Complete 10 laps of the Draynor Rooftop Agility Course.',
    skillReqs: [{ skill: 'Agility', level: 1 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  1881: {
    id: '1881',
    label: 'Complete 10 Laps of the Varrock Agility Course',
    description: 'Complete 10 laps of the Varrock Rooftop Agility Course.',
    skillReqs: [{ skill: 'Agility', level: 30 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  820: {
    id: '820',
    label: "Complete a Soul's Bane",
    description: "Complete the Quest A Soul's Bane quest.",
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.INTERMEDIATE,
    prerequisite: '',
  },
  1631: {
    id: '1631',
    label: 'Complete a task for the Wise Old Man',
    description: 'Complete a task for the Wise Old Man in Draynor.',
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  756: {
    id: '756',
    label: 'Complete a Volcanic Mine Game',
    description: 'Complete a Volcanic Mine game on Fossil Island.',
    skillReqs: [{ skill: 'Mining', level: 50 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  808: {
    id: '808',
    label: 'Complete Demon Slayer',
    description: 'Complete the Quest Demon Slayer quest.',
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.NOVICE,
    prerequisite: '',
  },
  801: {
    id: '801',
    label: 'Complete Rune Mysteries',
    description: 'Complete the Quest Rune Mysteries quest.',
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.NOVICE,
    prerequisite: '',
  },
  797: {
    id: '797',
    label: 'Complete the Easy Lumbridge & Draynor Diary',
    description: 'Complete all of the easy tasks in the Diary Lumbridge & Draynor Achievement Diary.',
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.EASY,
    prerequisite: '',
  },
  1377: {
    id: '1377',
    label: 'Complete the Easy Varrock Diary',
    description: 'Complete all of the easy tasks in the Diary Varrock Achievement Diary.',
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.EASY,
    prerequisite: '',
  },
  798: {
    id: '798',
    label: 'Complete the Medium Lumbridge & Draynor Diary',
    description: 'Complete all of the medium tasks in the Diary Lumbridge & Draynor Achievement Diary.',
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.MEDIUM,
    prerequisite: '',
  },
  1378: {
    id: '1378',
    label: 'Complete the Medium Varrock Diary',
    description: 'Complete all of the medium tasks in the Diary Varrock Achievement Diary.',
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.MEDIUM,
    prerequisite: '',
  },
  772: {
    id: '772',
    label: 'Craft 50 Cosmic Runes',
    description: 'Craft 50 Cosmic Runes from Essence at the Cosmic Altar from Essence.',
    skillReqs: [{ skill: 'Runecraft', level: 27 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  776: {
    id: '776',
    label: 'Defeat a Fossil Island Wyvern',
    description: 'Defeat any type of Wyvern on Fossil Island.',
    skillReqs: [{ skill: 'Slayer', level: 66 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  780: {
    id: '780',
    label: 'Defeat a Wall Beast',
    description: 'Defeat a Wall Beast in the Lumbridge Swamp Caves.',
    skillReqs: [{ skill: 'Slayer', level: 35 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  791: {
    id: '791',
    label: 'Defeat an Ancient Wyvern',
    description: 'Defeat an Ancient Wyvern on Fossil Island.',
    skillReqs: [{ skill: 'Slayer', level: 82 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  785: {
    id: '785',
    label: 'Defeat Bryophyta',
    description: 'Defeat Bryophyta in Varrock Sewers.',
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.BRYOPHYTA,
    prerequisite: '',
  },
  781: {
    id: '781',
    label: 'Defeat Obor',
    description: 'Defeat Obor in Edgeville Dungeon.',
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.OBOR,
    prerequisite: '',
  },
  747: {
    id: '747',
    label: 'Dismantle 20 Filled Bird Houses',
    description: 'Dismantle 20 filled Bird Houses on Fossil Island.',
    skillReqs: [
      { skill: 'Hunter', level: 5 },
      { skill: 'Crafting', level: 5 },
    ],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  810: {
    id: '810',
    label: 'Drink From the Tears of Guthix',
    description: 'Drink from the Tears of Guthix in the Lumbridge Swamp Caves.',
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  760: {
    id: '760',
    label: "Enter the Cooks' Guild",
    description: "Enter the Cooks' Guild west of Varrock.",
    skillReqs: [{ skill: 'Cooking', level: 32 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  802: {
    id: '802',
    label: 'Equip Some Mithril Gloves',
    description: 'Equip a pair of Mithril Gloves.',
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1610: {
    id: '1610',
    label: 'Equip the Forestry Basket',
    description: 'Obtain and Equip the Forestry Basket.',
    skillReqs: [{ skill: 'Woodcutting', level: 1 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  1666: {
    id: '1666',
    label: 'Have the wise Old Man get angry with you.',
    description: 'Have the Wise Old Man get angry with you in Draynor Village.',
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1728: {
    id: '1728',
    label: 'Obtain a pufferfish from Drift Net Fishing',
    description: 'Obtain a pufferfish from Drift Net Fishing.',
    skillReqs: [
      { skill: 'Hunter', level: 44 },
      { skill: 'Fishing', level: 47 },
    ],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  1687: {
    id: '1687',
    label: 'Open the Dark Chest',
    description: 'Open the Dark Chest.',
    skillReqs: [{ skill: 'Thieving', level: 28 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1685: {
    id: '1685',
    label: 'Pan for an Uncut Jade',
    description: 'Pan for an Uncut Jade.',
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  749: {
    id: '749',
    label: 'Set up a Yew Bird House',
    description: 'Set up a Yew Bird House on Fossil Island.',
    skillReqs: [
      { skill: 'Hunter', level: 59 },
      { skill: 'Crafting', level: 60 },
    ],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  818: {
    id: '818',
    label: 'Use a Digsite Pendant to Teleport to Fossil Island',
    description: 'Use a Digsite Pendant to teleport to Fossil Island.',
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  773: {
    id: '773',
    label: 'Use Enriched Bones on the Strange Machine',
    description: 'Use Enriched Bones on the Strange Machine on Fossil Island.',
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.PRAYER,
    prerequisite: '',
  },
  1481: {
    id: '1481',
    label: 'Win a Game of Soul Wars',
    description: 'Win a game of Soul Wars.',
    skillReqs: [
      { skill: 'Combat', level: 40 },
      { skill: 'Overall', level: 40 },
    ],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.OTHER,
    prerequisite: '',
  },
  1294: {
    id: '1294',
    label: "Achieve 100% Shades of Mort'Ton Sanctity",
    description: "Achieve 100% Sanctity during the Shades of Mort'ton minigame.",
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.SHADES,
    prerequisite: '',
  },
  1241: {
    id: '1241',
    label: 'Assemble a Slayer Helm',
    description: 'Assemble a Slayer Helm.',
    skillReqs: [
      { skill: 'Slayer', level: 58 },
      { skill: 'Crafting', level: 55 },
    ],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  1712: {
    id: '1712',
    label: 'Burn 20 Pyre Logs',
    description: 'Burn 20 Pyre Logs.',
    skillReqs: [{ skill: 'Firemaking', level: 5 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.SHADES,
    prerequisite: '',
  },
  1236: {
    id: '1236',
    label: 'Catch a Swamp Lizard in Morytania',
    description: 'Catch a Swamp Lizard in Morytania.',
    skillReqs: [{ skill: 'Hunter', level: 29 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1285: {
    id: '1285',
    label: 'Complete a Medium Temple Trek',
    description: 'Complete a medium Minigame Temple Trek.',
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.OTHER,
    prerequisite: '',
  },
  1292: {
    id: '1292',
    label: 'Complete A Taste of Hope',
    description: 'Complete the Quest A Taste of Hope quest.',
    skillReqs: [
      { skill: 'Crafting', level: 48 },
      { skill: 'Agility', level: 45 },
      { skill: 'Attack', level: 40 },
      { skill: 'Herblore', level: 40 },
      { skill: 'Slayer', level: 38 },
    ],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.EXPERIENCED,
    prerequisite: '',
  },
  1290: {
    id: '1290',
    label: 'Complete Ghosts Ahoy',
    description: 'Complete the Quest Ghosts Ahoy quest.',
    skillReqs: [
      { skill: 'Agility', level: 25 },
      { skill: 'Cooking', level: 20 },
    ],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.INTERMEDIATE,
    prerequisite: '',
  },
  1288: {
    id: '1288',
    label: 'Complete Haunted Mine',
    description: 'Complete the Quest Haunted Mine quest.',
    skillReqs: [{ skill: 'Crafting', level: 35 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.EXPERIENCED,
    prerequisite: '',
  },
  1232: {
    id: '1232',
    label: 'Complete the Canifis Agility Course',
    description: 'Complete a lap of the Canifis Rooftop Agility Course.',
    skillReqs: [{ skill: 'Agility', level: 40 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  1280: {
    id: '1280',
    label: 'Complete the Easy Morytania Diary',
    description: 'Complete all of the easy tasks in the Diary Morytania Achievement Diary.',
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.EASY,
    prerequisite: '',
  },
  1281: {
    id: '1281',
    label: 'Complete the Medium Morytania Diary',
    description: 'Complete all of the medium tasks in the Diary Morytania Achievement Diary.',
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.MEDIUM,
    prerequisite: '',
  },
  1233: {
    id: '1233',
    label: 'Complete the Werewolf Agility Course',
    description: 'Complete a lap of the Werewolf Agility Course.',
    skillReqs: [{ skill: 'Agility', level: 60 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  1238: {
    id: '1238',
    label: 'Convert 2,000 Daeyalt Shards Into Essence',
    description: 'Convert 2,000 Daeyalt Shards into Daeyalt Essence in Darkmeyer in one go.',
    skillReqs: [{ skill: 'Mining', level: 60 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  1239: {
    id: '1239',
    label: 'Craft a Rune Using Daeyalt Essence',
    description: 'Craft any rune using Daeyalt Essence.',
    skillReqs: [{ skill: 'Mining', level: 60 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  1245: {
    id: '1245',
    label: 'Defeat a Fiyr Shade',
    description: 'Defeat a Fiyr Shade.',
    skillReqs: [{ skill: 'Firemaking', level: 65 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.SHADES,
    prerequisite: '',
  },
  1242: {
    id: '1242',
    label: 'Defeat a Gargoyle in Morytania',
    description: 'Defeat a Gargoyle in Morytania.',
    skillReqs: [{ skill: 'Slayer', level: 75 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  1249: {
    id: '1249',
    label: 'Defeat a Vyrewatch Sentinel',
    description: 'Defeat a Vyrewatch Sentinel in Darkmeyer.',
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1250: {
    id: '1250',
    label: 'Defeat an Abyssal Demon in Morytania',
    description: 'Defeat an Abyssal Demon in Morytania.',
    skillReqs: [{ skill: 'Slayer', level: 85 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  1253: {
    id: '1253',
    label: 'Equip a Piece of any Barrows Armour Set',
    description: 'Equip any piece of any Barrows armour set.',
    skillReqs: [
      { skill: 'Defence', level: 70 },
      { skill: 'Attack', level: 70 },
      { skill: 'Ranged', level: 70 },
    ],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.BARROWS,
    prerequisite: '',
  },
  1296: {
    id: '1296',
    label: 'Equip a Salve Amulet (e)',
    description: 'Equip a Salve Amulet (e).',
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1684: {
    id: '1684',
    label: 'Feed a ghast some sweets',
    description: 'Feed a ghast some sweets.',
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1222: {
    id: '1222',
    label: 'Floor 1 of the Hallowed Sepulchre',
    description: 'Complete floor 1 of the Hallowed Sepulchre in Darkmeyer.',
    skillReqs: [{ skill: 'Agility', level: 52 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  1223: {
    id: '1223',
    label: 'Floor 2 of the Hallowed Sepulchre',
    description: 'Complete floor 2 of the Hallowed Sepulchre in Darkmeyer.',
    skillReqs: [{ skill: 'Agility', level: 62 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  1664: {
    id: '1664',
    label: 'Have the Taxidermist stuff something for you',
    description: 'Have the Taxidermist stuff something for you in Canifis.',
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1256: {
    id: '1256',
    label: 'Loot a Barrows Chest',
    description: 'Loot a Barrows Chest once.',
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.BARROWS,
    prerequisite: '',
  },
  1479: {
    id: '1479',
    label: 'Open a Black Chest',
    description: "Open a Shades of Mort'ton.",
    skillReqs: [{ skill: 'Firemaking', level: 35 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.SHADES,
    prerequisite: '',
  },
  1291: {
    id: '1291',
    label: 'Pet Frank',
    description: "Pet Darkmeyer's goodest boy.",
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1678: {
    id: '1678',
    label: 'Telegrab a Bloody bracer',
    description: 'Telegrab a Bloody bracer in Slepe.',
    skillReqs: [{ skill: 'Magic', level: 33 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  826: {
    id: '826',
    label: 'Check a grown Papaya Tree in Lletya',
    description: "Check the health of a Papaya Tree you've grown in Lletya.",
    skillReqs: [{ skill: 'Farming', level: 57 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  834: {
    id: '834',
    label: 'Craft 50 Death Runes',
    description: 'Craft 50 Death Runes from Essence at the Death Altar.',
    skillReqs: [{ skill: 'Runecraft', level: 65 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  842: {
    id: '842',
    label: 'Defeat a Bloodveld in Tirannwn',
    description: 'Defeat a Bloodveld in Tirannwn.',
    skillReqs: [{ skill: 'Slayer', level: 50 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  846: {
    id: '846',
    label: 'Defeat a Kurask in Tirannwn',
    description: 'Defeat a Kurask in Tirannwn.',
    skillReqs: [{ skill: 'Slayer', level: 70 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  838: {
    id: '838',
    label: 'Defeat an Elf in Tirannwn',
    description: 'Defeat an Elf in Tirannwn.',
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1744: {
    id: '1744',
    label: 'Kill a Black Dragon in Tirannwn',
    description: 'Kill a Black Dragon in Tirannwn.',
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  836: {
    id: '836',
    label: 'Move Your House to Prifddinas',
    description: 'Move your Player Owned House to Prifddinas.',
    skillReqs: [{ skill: 'Construction', level: 70 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  1658: {
    id: '1658',
    label: 'Ring all of the Prifddinas bells',
    description: 'Ring all of the Prifddinas bells.',
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  874: {
    id: '874',
    label: 'Use an Elven Teleport Crystal',
    description: 'Use an Elven Teleport Crystal.',
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1300: {
    id: '1300',
    label: 'Bury Some Lava Dragon Bones',
    description: 'Bury some Lava Dragon Bones.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.PRAYER,
    prerequisite: '',
  },
  1354: {
    id: '1354',
    label: 'Cast Claws of Guthix',
    description: 'Cast the Claws of Guthix spell outside of the Mage Arena.',
    skillReqs: [{ skill: 'Magic', level: 60 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  1355: {
    id: '1355',
    label: 'Cast Flames of Zamorak',
    description: 'Cast the Flames of Zamorak spell outside of the Mage Arena.',
    skillReqs: [{ skill: 'Magic', level: 60 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  1353: {
    id: '1353',
    label: 'Cast Saradomin Strike',
    description: 'Cast the Saradomin Strike spell outside of the Mage Arena.',
    skillReqs: [{ skill: 'Magic', level: 60 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  1306: {
    id: '1306',
    label: 'Catch a Black Chinchompa',
    description: 'Catch a Black Chinchompa in the Wilderness.',
    skillReqs: [{ skill: 'Hunter', level: 73 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1305: {
    id: '1305',
    label: 'Catch a Black Salamander',
    description: 'Catch a Black Salamander in the Wilderness.',
    skillReqs: [{ skill: 'Hunter', level: 67 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1356: {
    id: '1356',
    label: 'Complete the Easy Wilderness Diary',
    description: 'Complete all of the easy tasks in the Diary Wilderness Achievement Diary.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.EASY,
    prerequisite: '',
  },
  1357: {
    id: '1357',
    label: 'Complete the Medium Wilderness Diary',
    description: 'Complete all of the medium tasks in the Diary Wilderness Achievement Diary.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.MEDIUM,
    prerequisite: '',
  },
  1304: {
    id: '1304',
    label: 'Complete the Wilderness Agility Course',
    description: 'Complete a lap of the Wilderness Agility Course.',
    skillReqs: [{ skill: 'Agility', level: 52 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  1327: {
    id: '1327',
    label: 'Defeat a Green Dragon in the Wilderness',
    description: 'Defeat a Green Dragon in the Wilderness.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1315: {
    id: '1315',
    label: 'Defeat a Lava Dragon in the Wilderness',
    description: 'Defeat a Lava Dragon in the Wilderness.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1324: {
    id: '1324',
    label: 'Defeat the Chaos Fanatic',
    description: 'Defeat the Chaos Fanatic in the Wilderness.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  1316: {
    id: '1316',
    label: 'Defeat the Crazy Archaeologist',
    description: 'Defeat the Crazy Archaeologist in the Wilderness.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  1653: {
    id: '1653',
    label: 'Destroy the one ring to rule them all',
    description: 'Destroy the one ring to rule them all.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1366: {
    id: '1366',
    label: 'Enter the Deep Wilderness Dungeon',
    description: 'Enter the Deep Wilderness Dungeon.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1343: {
    id: '1343',
    label: 'Equip a Bracelet of Ethereum',
    description: 'Equip a Bracelet of Ethereum.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1330: {
    id: '1330',
    label: 'Equip a Fedora',
    description: 'Equip a Fedora.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  1351: {
    id: '1351',
    label: 'Equip a God Cape',
    description: 'Equip either a Saradomin Cape, a Guthix Cape or a Zamorak Cape.',
    skillReqs: [{ skill: 'Magic', level: 60 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  1340: {
    id: '1340',
    label: 'Obtain an Ecumenical Key',
    description: 'Obtain an Ecumenical Key as a drop in the Wilderness God Wars Dungeon.',
    skillReqs: [
      { skill: 'Strength', level: 60 },
      { skill: 'Agility', level: 60 },
    ],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1701: {
    id: '1701',
    label: 'Open 1 Muddy Chest',
    description: 'Open 1 Muddy Chest.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1699: {
    id: '1699',
    label: "Open 15 Larran's Chests",
    description: "Open 15 of either Larran's Small Chest or Larran's Big Chest.",
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '1369',
  },
  1362: {
    id: '1362',
    label: 'Open a Looting Bag',
    description: 'Open a Looting Bag.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1369: {
    id: '1369',
    label: "Open One of Larran's Chests",
    description: "Open either Larran's Small Chest or Larran's Big Chest.",
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  1298: {
    id: '1298',
    label: 'Sacrifice Some Bones at the Chaos Temple',
    description: 'Sacrifice some Bones at the Western Chaos Temple altar.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.PRAYER,
    prerequisite: '',
  },
  1311: {
    id: '1311',
    label: 'Use the Abyss',
    description: 'Use the Runecrafting Altar.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  463: {
    id: '463',
    label: '1 Master Clue Scroll',
    description: 'Open a Reward casket for completing a master clue scroll.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.MASTER,
    prerequisite: '',
  },
  1810: {
    id: '1810',
    label: '100 Collection log slots',
    description: 'Obtain 100 unique Collection Log slots.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.COLLECTION_LOG,
    prerequisite: '1809',
  },
  1443: {
    id: '1443',
    label: '100 Combat Achievement Points',
    description: 'Obtain 100 Combat Achievement Points.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.GENERAL,
    prerequisite: '1442',
  },
  459: {
    id: '459',
    label: '150 Elite Clue Scrolls',
    description: 'Open 150 Reward caskets for completing elite clue scrolls.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.ELITE,
    prerequisite: '458',
  },
  452: {
    id: '452',
    label: '150 Hard Clue Scrolls',
    description: 'Open 150 Reward caskets for completing hard clue scrolls.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.HARD,
    prerequisite: '451',
  },
  1441: {
    id: '1441',
    label: '25 Combat Achievement Points',
    description: 'Obtain 25 Combat Achievement Points.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.GENERAL,
    prerequisite: '',
  },
  1458: {
    id: '1458',
    label: '25 Combat Achievements',
    description: 'Complete 25 Combat Achievements',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.GENERAL,
    prerequisite: '1457',
  },
  457: {
    id: '457',
    label: '25 Elite Clue Scrolls',
    description: 'Open 25 Reward caskets for completing elite clue scrolls.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.ELITE,
    prerequisite: '456',
  },
  464: {
    id: '464',
    label: '25 Master Clue Scrolls',
    description: 'Open 25 Reward caskets for completing master clue scrolls.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.MASTER,
    prerequisite: '463',
  },
  1809: {
    id: '1809',
    label: '50 Collection log slots',
    description: 'Obtain 50 unique Collection Log slots.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.COLLECTION_LOG,
    prerequisite: '1808',
  },
  1442: {
    id: '1442',
    label: '50 Combat Achievement Points',
    description: 'Obtain 50 Combat Achievement Points.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.GENERAL,
    prerequisite: '1441',
  },
  1459: {
    id: '1459',
    label: '50 Combat Achievements',
    description: 'Complete 50 Combat Achievements',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.GENERAL,
    prerequisite: '1458',
  },
  458: {
    id: '458',
    label: '75 Elite Clue Scrolls',
    description: 'Open 75 Reward caskets for completing elite clue scrolls.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.ELITE,
    prerequisite: '457',
  },
  451: {
    id: '451',
    label: '75 Hard Clue Scrolls',
    description: 'Open 75 Reward caskets for completing hard clue scrolls.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.HARD,
    prerequisite: '450',
  },
  504: {
    id: '504',
    label: 'Achieve Your First Level 60',
    description: 'Reach level 60 in any skill.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.MILESTONE,
    prerequisite: '503',
  },
  505: {
    id: '505',
    label: 'Achieve Your First Level 70',
    description: 'Reach level 70 in any skill.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.MILESTONE,
    prerequisite: '504',
  },
  506: {
    id: '506',
    label: 'Achieve Your First Level 80',
    description: 'Reach level 80 in any skill.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.MILESTONE,
    prerequisite: '505',
  },
  1703: {
    id: '1703',
    label: 'Build a Marble Lectern',
    description: 'Build a Marble Lectern.',
    skillReqs: [{ skill: 'Construction', level: 77 }],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  671: {
    id: '671',
    label: 'Build an Achievement Gallery',
    description: 'Build an Achievement Gallery in your Player Owned House.',
    skillReqs: [{ skill: 'Construction', level: 80 }],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  685: {
    id: '685',
    label: 'Burn 100 Yew Logs',
    description: 'Burn 100 Yew Logs.',
    skillReqs: [{ skill: 'Firemaking', level: 60 }],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FIREMAKING,
    prerequisite: '',
  },
  686: {
    id: '686',
    label: 'Burn Some Magic Logs',
    description: 'Burn some Magic Logs.',
    skillReqs: [{ skill: 'Firemaking', level: 75 }],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FIREMAKING,
    prerequisite: '',
  },
  746: {
    id: '746',
    label: 'Cast a Surge Spell',
    description: 'Cast any surge spell.',
    skillReqs: [{ skill: 'Magic', level: 81 }],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  745: {
    id: '745',
    label: 'Cast a Wave Spell',
    description: 'Cast any wave spell.',
    skillReqs: [{ skill: 'Magic', level: 62 }],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  654: {
    id: '654',
    label: 'Catch 100 Shark',
    description: 'Catch 100 Raw Shark whilst Fishing.',
    skillReqs: [{ skill: 'Fishing', level: 76 }],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  692: {
    id: '692',
    label: 'Catch a Dragon Impling',
    description: 'Catch a Dragon Impling.',
    skillReqs: [{ skill: 'Hunter', level: 83 }],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  592: {
    id: '592',
    label: 'Check a grown Palm Tree',
    description: "Check the health of a Palm Tree you've grown.",
    skillReqs: [{ skill: 'Farming', level: 68 }],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  601: {
    id: '601',
    label: 'Chop 75 Magic Logs',
    description: 'Chop 75 Magic Logs from Magic Trees.',
    skillReqs: [{ skill: 'Woodcutting', level: 75 }],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  706: {
    id: '706',
    label: 'Clean 100 Grimy Dwarf Weed',
    description: 'Clean 100 Grimy Dwarf Weed.',
    skillReqs: [{ skill: 'Herblore', level: 70 }],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  1771: {
    id: '1771',
    label: 'Complete 1 Speed Task',
    description: 'Complete a Combat Achievement Speed task.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.GENERAL,
    prerequisite: '',
  },
  1564: {
    id: '1564',
    label: 'Complete 100 Mahogany homes contracts',
    description: 'Complete 100 Mahogany homes contracts.',
    skillReqs: [{ skill: 'Construction', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '1563',
  },
  633: {
    id: '633',
    label: 'Complete 100 Slayer Tasks',
    description: 'Complete 100 Slayer Tasks.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '632',
  },
  1565: {
    id: '1565',
    label: 'Complete 200 Mahogany homes contracts',
    description: 'Complete 200 Mahogany homes contracts.',
    skillReqs: [{ skill: 'Construction', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '1564',
  },
  698: {
    id: '698',
    label: 'Complete 250 Laps of a Rooftop Agility Course',
    description: 'Complete 250 laps of any Rooftop Agility Course.',
    skillReqs: [{ skill: 'Agility', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '697',
  },
  1772: {
    id: '1772',
    label: 'Complete 5 Speed Tasks',
    description: 'Complete 5 Combat Achievement Speed tasks.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.GENERAL,
    prerequisite: '1771',
  },
  632: {
    id: '632',
    label: 'Complete 50 Slayer Tasks',
    description: 'Complete 50 Slayer Tasks.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '1882',
  },
  1562: {
    id: '1562',
    label: 'Complete a Mahogany homes expert contract',
    description: 'Complete a Mahogany homes expert contract.',
    skillReqs: [{ skill: 'Construction', level: 70 }],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  1088: {
    id: '1088',
    label: 'Complete the Hard Western Provinces Diary',
    description: 'Complete all of the hard tasks in the Diary Western Provinces Achievement Diary.',
    skillReqs: [],
    regions: ['Kandarin', 'Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.HARD,
    prerequisite: '',
  },
  1705: {
    id: '1705',
    label: 'Cook 100 pies.',
    description: 'Cook 100 pies.',
    skillReqs: [{ skill: 'Cooking', level: 10 }],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  608: {
    id: '608',
    label: 'Cook 100 Sharks',
    description: 'Cook 100 Raw Sharks.',
    skillReqs: [{ skill: 'Cooking', level: 80 }],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  1706: {
    id: '1706',
    label: 'Cook 20 Redberry Pies.',
    description: 'Cook 20 Redberry Pies.',
    skillReqs: [{ skill: 'Cooking', level: 10 }],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  678: {
    id: '678',
    label: 'Craft 2500 Essence Into Runes',
    description: 'Use Runecrafting Altars to craft 2500 essence into runes of any type.',
    skillReqs: [{ skill: 'Runecraft', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  646: {
    id: '646',
    label: 'Craft 30 Blue Dragonhide Bodies',
    description: 'Craft 30 Blue Dragonhide Bodies.',
    skillReqs: [{ skill: 'Crafting', level: 71 }],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '',
  },
  645: {
    id: '645',
    label: 'Craft a Dragonstone Amulet',
    description: 'Craft a Dragonstone Amulet.',
    skillReqs: [{ skill: 'Crafting', level: 80 }],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '',
  },
  1710: {
    id: '1710',
    label: "Create a black d'hide shield",
    description: "Create a black d'hide shield.",
    skillReqs: [{ skill: 'Crafting', level: 83 }],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '',
  },
  634: {
    id: '634',
    label: 'Defeat 10 Superior Slayer Creatures',
    description: 'Defeat 10 Superior Slayer Creatures.',
    skillReqs: [{ skill: 'Slayer', level: 5 }],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '630',
  },
  635: {
    id: '635',
    label: 'Defeat 20 Superior Slayer Creatures',
    description: 'Defeat 20 Superior Slayer Creatures.',
    skillReqs: [{ skill: 'Slayer', level: 5 }],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '634',
  },
  631: {
    id: '631',
    label: 'Defeat a Slayer Boss',
    description: 'Defeat any Slayer Boss.',
    skillReqs: [
      { skill: 'Slayer', level: 85 },
      { skill: 'Slayer', level: 75 },
    ],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  593: {
    id: '593',
    label: 'Dig Up Some Magic Roots',
    description: "Dig up some Magic Tree you've grown.",
    skillReqs: [{ skill: 'Farming', level: 75 }],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  744: {
    id: '744',
    label: 'Equip a Combination Battlestaff or Mystic Staff',
    description: 'Equip either a Combination Battlestaff or a Combination Mystic Staff.',
    skillReqs: [
      { skill: 'Magic', level: 30 },
      { skill: 'Attack', level: 30 },
    ],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  723: {
    id: '723',
    label: 'Equip a Dragon Weapon',
    description: 'Equip any Dragon weapon.',
    skillReqs: [{ skill: 'Attack', level: 60 }],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.ATTACK,
    prerequisite: '',
  },
  733: {
    id: '733',
    label: 'Equip a Full Black Dragonhide Set',
    description: 'Equip a Black Dragonhide Body, some Black Dragonhide Chaps and some Black Dragonhide Vambraces.',
    skillReqs: [
      { skill: 'Ranged', level: 70 },
      { skill: 'Defence', level: 40 },
    ],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.RANGED,
    prerequisite: '',
  },
  12: {
    id: '12',
    label: 'Equip a Full Gilded or Trimmed Wizard Set',
    description: 'Equip either a full set of Gilded wizard robes or a full set of Trimmed wizard robes.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.EASY,
    prerequisite: '',
  },
  16: {
    id: '16',
    label: 'Equip a Full Gilded Set',
    description: 'Equip either a full set of Gilded Rune Armour or a full set of Gilded Dragonhide Armour.',
    skillReqs: [{ skill: 'Defence', level: 40 }],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.GENERAL,
    prerequisite: '',
  },
  20: {
    id: '20',
    label: 'Equip a Full God Dragonhide Set',
    description: 'Equip a Blessed Coif, Body and Chaps with all pieces aligned to the same god.',
    skillReqs: [
      { skill: 'Ranged', level: 70 },
      { skill: 'Defence', level: 40 },
    ],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.HARD,
    prerequisite: '',
  },
  17: {
    id: '17',
    label: 'Equip a Full God Rune Set',
    description: 'Equip a full set of God Rune Armour with all pieces aligned to the same god.',
    skillReqs: [{ skill: 'Defence', level: 40 }],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.HARD,
    prerequisite: '',
  },
  13: {
    id: '13',
    label: 'Equip a Full Vestment Set',
    description: 'Equip a full set of Vestment robes with all pieces aligned to the same god.',
    skillReqs: [
      { skill: 'Prayer', level: 60 },
      { skill: 'Magic', level: 40 },
    ],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.GENERAL,
    prerequisite: '',
  },
  736: {
    id: '736',
    label: 'Equip a Magic Shortbow',
    description: 'Equip a Magic Shortbow.',
    skillReqs: [{ skill: 'Ranged', level: 50 }],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.RANGED,
    prerequisite: '',
  },
  386: {
    id: '386',
    label: 'Equip a Mist Battlestaff',
    description: 'Obtain and equip a Mist battlestaff.',
    skillReqs: [
      { skill: 'Slayer', level: 5 },
      { skill: 'Attack', level: 30 },
      { skill: 'Magic', level: 30 },
    ],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  737: {
    id: '737',
    label: 'Equip a Rune Crossbow',
    description: 'Equip a Rune Crossbow.',
    skillReqs: [{ skill: 'Ranged', level: 61 }],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.RANGED,
    prerequisite: '',
  },
  1878: {
    id: '1878',
    label: 'Equip a two-handed Axe.',
    description: 'Obtain and Equip a two-handed woodcutting axe from Forestry.',
    skillReqs: [{ skill: 'Woodcutting', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  22: {
    id: '22',
    label: 'Equip an Ornament Kit Item',
    description: 'Equip any item that has an Ornament Kit attached to it.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.GENERAL,
    prerequisite: '',
  },
  24: {
    id: '24',
    label: 'Equip Some Ranger Boots',
    description: 'Equip a pair of Ranger Boots.',
    skillReqs: [{ skill: 'Ranged', level: 40 }],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.MEDIUM,
    prerequisite: '',
  },
  85: {
    id: '85',
    label: 'Fill 10 Beginner Clue Collection Log Slots',
    description: 'Fill 10 slots in the Beginner Clue section of the Collection Log.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.BEGINNER,
    prerequisite: '84',
  },
  31: {
    id: '31',
    label: 'Fill 10 Elite Clue Collection Log Slots',
    description: 'Fill 10 slots in the Elite Clue section of the Collection Log.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.ELITE,
    prerequisite: '30',
  },
  39: {
    id: '39',
    label: 'Fill 15 Hard Clue Collection Log Slots',
    description: 'Fill 15 slots in the Hard Clue section of the Collection Log.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.HARD,
    prerequisite: '49',
  },
  66: {
    id: '66',
    label: 'Fill 20 Easy Clue Collection Log Slots',
    description: 'Fill 20 slots in the Easy Clue section of the Collection Log.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.EASY,
    prerequisite: '76',
  },
  58: {
    id: '58',
    label: 'Fill 20 Medium Clue Collection Log Slots',
    description: 'Fill 20 slots in the Medium Clue section of the Collection Log.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.MEDIUM,
    prerequisite: '57',
  },
  9: {
    id: '9',
    label: 'Fill 5 Master Clue Collection Log Slots',
    description: 'Fill 5 slots in the Master Clue section of the Collection Log.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.MASTER,
    prerequisite: '',
  },
  1313: {
    id: '1313',
    label: 'Fill a Giant Pouch',
    description: 'Fill a Giant Pouch with Essence.',
    skillReqs: [{ skill: 'Runecraft', level: 75 }],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  670: {
    id: '670',
    label: 'Fill a Hard S.T.A.S.H. Unit',
    description: 'Build a Hard S.T.A.S.H. unit and fill it with the relevant items.',
    skillReqs: [{ skill: 'Construction', level: 55 }],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.GENERAL,
    prerequisite: '',
  },
  1718: {
    id: '1718',
    label: 'Fletch 200 Magic Longbow (u)',
    description: 'Fletch 200 Magic Longbow (u).',
    skillReqs: [{ skill: 'Fletching', level: 85 }],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FLETCHING,
    prerequisite: '',
  },
  1719: {
    id: '1719',
    label: 'Fletch a Magic Shield',
    description: 'Fletch a Magic Shield.',
    skillReqs: [{ skill: 'Fletching', level: 87 }],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FLETCHING,
    prerequisite: '',
  },
  622: {
    id: '622',
    label: 'Fletch a Magic Shortbow',
    description: 'Fletch a Magic Shortbow.',
    skillReqs: [{ skill: 'Fletching', level: 80 }],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FLETCHING,
    prerequisite: '',
  },
  623: {
    id: '623',
    label: 'Fletch a Rune Crossbow',
    description: 'Fletch a Rune Crossbow.',
    skillReqs: [{ skill: 'Fletching', level: 69 }],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FLETCHING,
    prerequisite: '',
  },
  102: {
    id: '102',
    label: 'Gain 10 Unique Items From Elite Clues',
    description: 'Gain 10 unique items from Elite Clue Scroll Reward Caskets.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.ELITE,
    prerequisite: '157',
  },
  93: {
    id: '93',
    label: 'Gain 10 Unique Items From Master Clues',
    description: 'Gain 10 unique items from Master Clue Scroll Reward Caskets.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.MASTER,
    prerequisite: '147',
  },
  111: {
    id: '111',
    label: 'Gain 20 Unique Items From Hard Clues',
    description: 'Gain 20 unique items from Hard Clue Scroll Reward Caskets.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.HARD,
    prerequisite: '121',
  },
  120: {
    id: '120',
    label: 'Gain 25 Unique Items From Medium Clues',
    description: 'Gain 25 unique items from Medium Clue Scroll Reward Caskets.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.MEDIUM,
    prerequisite: '130',
  },
  138: {
    id: '138',
    label: 'Gain 35 Unique Items From Beginner Clues',
    description: 'Gain 35 unique items from Beginner Clue Scroll Reward Caskets.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.BEGINNER,
    prerequisite: '148',
  },
  129: {
    id: '129',
    label: 'Gain 35 Unique Items From Easy Clues',
    description: 'Gain 35 unique items from Easy Clue Scroll Reward Caskets.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.EASY,
    prerequisite: '139',
  },
  147: {
    id: '147',
    label: 'Gain a Unique Item From a Master Clue',
    description: 'Gain a unique item from a Master Clue Scroll Reward Casket.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.MASTER,
    prerequisite: '',
  },
  157: {
    id: '157',
    label: 'Gain a Unique Item From an Elite Clue',
    description: 'Gain a unique item from an Elite Clue Scroll Reward Casket.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.ELITE,
    prerequisite: '',
  },
  1100: {
    id: '1100',
    label: 'Make 20 Ranging Potions',
    description: 'Make 20 Ranging Potions.',
    skillReqs: [{ skill: 'Herblore', level: 72 }],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  705: {
    id: '705',
    label: 'Make a Saradomin Brew',
    description: 'Make a Saradomin Brew.',
    skillReqs: [{ skill: 'Herblore', level: 81 }],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  615: {
    id: '615',
    label: 'Mine 50 Adamantite Ore',
    description: 'Mine 50 Adamantite Ore.',
    skillReqs: [{ skill: 'Mining', level: 70 }],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  1566: {
    id: '1566',
    label: 'Mine a shooting star',
    description: 'Mine a shooting star.',
    skillReqs: [{ skill: 'Mining', level: 10 }],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  1748: {
    id: '1748',
    label: 'Obtain a Magic seed from a birds nest',
    description: 'Obtain a Magic seed from a birds nest.',
    skillReqs: [{ skill: 'Woodcutting', level: 1 }],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  229: {
    id: '229',
    label: 'Reach a Prayer Bonus of 30',
    description: 'Equip enough items to reach a Prayer Bonus of 30 or more.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.PRAYER,
    prerequisite: '',
  },
  527: {
    id: '527',
    label: 'Reach Base Level 60',
    description: 'Reach level 60 in every skill.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.BASE,
    prerequisite: '526',
  },
  528: {
    id: '528',
    label: 'Reach Base Level 70',
    description: 'Reach level 70 in every skill.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.BASE,
    prerequisite: '527',
  },
  713: {
    id: '713',
    label: 'Reach Combat Level 100',
    description: 'Reach Combat Level 100.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.LEVEL,
    prerequisite: '712',
  },
  714: {
    id: '714',
    label: 'Reach Combat Level 110',
    description: 'Reach Combat Level 110.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.LEVEL,
    prerequisite: '713',
  },
  515: {
    id: '515',
    label: 'Reach Total Level 1500',
    description: 'Reach a Total Level of 1500.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.TOTAL,
    prerequisite: '514',
  },
  516: {
    id: '516',
    label: 'Reach Total Level 1750',
    description: 'Reach a Total Level of 1750.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.TOTAL,
    prerequisite: '515',
  },
  228: {
    id: '228',
    label: 'Restore 75 Prayer Points at an Altar',
    description: 'Restore 75 or more Prayer Points at any altar.',
    skillReqs: [{ skill: 'Prayer', level: 75 }],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.PRAYER,
    prerequisite: '',
  },
  662: {
    id: '662',
    label: 'Smelt a Runite Bar',
    description: 'Use a Furnace to smelt a Runite Bar.',
    skillReqs: [{ skill: 'Smithing', level: 85 }],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  1621: {
    id: '1621',
    label: 'Smith a Rune Item',
    description: 'Smith a Runite Item.',
    skillReqs: [{ skill: 'Smithing', level: 85 }],
    regions: ['General'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  585: {
    id: '585',
    label: 'Steal From a Gem Stall',
    description: 'Steal from a Gem Stall.',
    skillReqs: [{ skill: 'Thieving', level: 75 }],
    regions: ['Kandarin', 'Fremennik', 'Tirannwn', 'Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1152: {
    id: '1152',
    label: 'Complete a Game of Veteran Pest Control',
    description: 'Complete a game of Veteran Minigame Pest Control.',
    skillReqs: [{ skill: 'Combat', level: 100 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.PC,
    prerequisite: '',
  },
  1768: {
    id: '1768',
    label: 'Complete Land of the Goblins',
    description: 'Complete Quest Land of the Goblins quest.',
    skillReqs: [
      { skill: 'Agility', level: 38 },
      { skill: 'Fishing', level: 40 },
      { skill: 'Thieving', level: 45 },
      { skill: 'Herblore', level: 48 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.EXPERIENCED,
    prerequisite: '',
  },
  1140: {
    id: '1140',
    label: 'Complete the Hard Falador Diary',
    description: 'Complete all of the hard tasks in the Diary Falador Achievement Diary.',
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.HARD,
    prerequisite: '',
  },
  1099: {
    id: '1099',
    label: 'Craft 50 Law Runes',
    description: 'Craft 50 Essence at the Law Altar.',
    skillReqs: [{ skill: 'Runecraft', level: 54 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  1132: {
    id: '1132',
    label: 'Defeat Any God Wars Dungeon Boss 100 Times',
    description: 'Defeat any one of the God Wars Dungeon bosses 100 times',
    skillReqs: [
      { skill: 'Hitpoints', level: 70 },
      { skill: 'Agility', level: 70 },
      { skill: 'Ranged', level: 70 },
      { skill: 'Strength', level: 70 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '',
  },
  1133: {
    id: '1133',
    label: 'Defeat Any God Wars Dungeon Boss 250 Times',
    description: 'Defeat any one of the God Wars Dungeon bosses 250 times',
    skillReqs: [
      { skill: 'Hitpoints', level: 70 },
      { skill: 'Agility', level: 70 },
      { skill: 'Ranged', level: 70 },
      { skill: 'Strength', level: 70 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '1132',
  },
  1750: {
    id: '1750',
    label: 'Defeat Cerberus 150 times',
    description: 'Defeat Cerberus 150 times.',
    skillReqs: [{ skill: 'Slayer', level: 91 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.CERBERUS,
    prerequisite: '1749',
  },
  1749: {
    id: '1749',
    label: 'Defeat Cerberus 50 times',
    description: 'Defeat Cerberus 50 times.',
    skillReqs: [{ skill: 'Slayer', level: 91 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.CERBERUS,
    prerequisite: '1130',
  },
  1130: {
    id: '1130',
    label: 'Defeat Cerberus',
    description: 'Defeat Cerberus in the Taverley Dungeon.',
    skillReqs: [{ skill: 'Slayer', level: 91 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.CERBERUS,
    prerequisite: '',
  },
  1124: {
    id: '1124',
    label: 'Defeat Commander Zilyana',
    description: 'Defeat Commander Zilyana in the God Wars Dungeon.',
    skillReqs: [{ skill: 'Agility', level: 70 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '',
  },
  1116: {
    id: '1116',
    label: 'Defeat General Graardor',
    description: 'Defeat General Graardor in the God Wars Dungeon.',
    skillReqs: [{ skill: 'Strength', level: 70 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '',
  },
  1111: {
    id: '1111',
    label: "Defeat K'ril Tsutsaroth",
    description: "Defeat K'ril Tsutsaroth in the God Wars Dungeon.",
    skillReqs: [{ skill: 'Hitpoints', level: 70 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '',
  },
  1120: {
    id: '1120',
    label: "Defeat Kree'arra",
    description: "Defeat Kree'arra in the God Wars Dungeon.",
    skillReqs: [{ skill: 'Ranged', level: 70 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '',
  },
  1512: {
    id: '1512',
    label: 'Defeat Nex 150 Times',
    description: 'Defeat Nex in the God Wars Dungeon 150 times.',
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '1511',
  },
  1511: {
    id: '1511',
    label: 'Defeat Nex 50 Times',
    description: 'Defeat Nex in the God Wars Dungeon 50 times.',
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '1510',
  },
  1510: {
    id: '1510',
    label: 'Defeat Nex',
    description: 'Defeat Nex in the God Wars Dungeon.',
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '',
  },
  1137: {
    id: '1137',
    label: 'Defeat the Giant Mole 300 Times',
    description: 'Defeat the Giant Mole beneath Falador 300 times.',
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.MOLE,
    prerequisite: '1136',
  },
  1529: {
    id: '1529',
    label: 'Defeat Whisperer 150 times',
    description: 'Defeat Whisperer 150 times.',
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '1528',
  },
  1528: {
    id: '1528',
    label: 'Defeat Whisperer 50 times',
    description: 'Defeat Whisperer 50 times.',
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '1527',
  },
  1527: {
    id: '1527',
    label: 'Defeat Whisperer',
    description: 'Defeat Whisperer.',
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '',
  },
  1106: {
    id: '1106',
    label: 'Equip a Dragon Defender',
    description: 'Equip a Dragon Defender.',
    skillReqs: [{ skill: 'Defence', level: 60 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1095: {
    id: '1095',
    label: 'Equip a Full Prospector Outfit',
    description: 'Equip a full set of Prospector gear.',
    skillReqs: [{ skill: 'Mining', level: 30 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  1093: {
    id: '1093',
    label: 'Equip a Full Rogue Outfit',
    description: 'Equip a full set of Rogue gear.',
    skillReqs: [
      { skill: 'Thieving', level: 50 },
      { skill: 'Agility', level: 50 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1143: {
    id: '1143',
    label: 'Equip a Full Void Knight Set',
    description: 'Equip a full set of Void Knight equipment.',
    skillReqs: [
      { skill: 'Attack', level: 42 },
      { skill: 'Strength', level: 42 },
      { skill: 'Defence', level: 42 },
      { skill: 'Hitpoints', level: 42 },
      { skill: 'Magic', level: 42 },
      { skill: 'Ranged', level: 42 },
      { skill: 'Prayer', level: 22 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.PC,
    prerequisite: '',
  },
  1131: {
    id: '1131',
    label: 'Equip a Pair of Dragon Boots',
    description: 'Equip a pair of Dragon Boots.',
    skillReqs: [
      { skill: 'Defence', level: 60 },
      { skill: 'Slayer', level: 83 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  1488: {
    id: '1488',
    label: 'Equip a Piece of the Golden Prospector set',
    description:
      'Equip either a Golden prospector helmet, a Golden prospector jacket, some Golden prospector legs or Golden prospector boots.',
    skillReqs: [{ skill: 'Mining', level: 30 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  1117: {
    id: '1117',
    label: 'Equip a Saradomin Sword',
    description: 'Equip a Saradomin Sword.',
    skillReqs: [
      { skill: 'Attack', level: 70 },
      { skill: 'Agility', level: 70 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '',
  },
  1521: {
    id: '1521',
    label: 'Equip some Zaryte Vambraces',
    description: 'Obtain and equip some Zaryte Vambraces.',
    skillReqs: [
      { skill: 'Ranged', level: 80 },
      { skill: 'Defence', level: 45 },
      { skill: 'Agility', level: 70 },
      { skill: 'Hitpoints', level: 70 },
      { skill: 'Strength', level: 70 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '',
  },
  1149: {
    id: '1149',
    label: 'Keep the Veteran Void Knight Above 150 Hitpoints',
    description:
      'Complete a game of Veteran Minigame Pest Control without letting the Void Knight fall below 150 Hitpoints.',
    skillReqs: [{ skill: 'Combat', level: 100 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.PC,
    prerequisite: '',
  },
  1486: {
    id: '1486',
    label: 'Purchase a Celestial ring',
    description: "Purchase a Celestial ring from Dusuri's Star Shop.",
    skillReqs: [{ skill: 'Mining', level: 10 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  765: {
    id: '765',
    label: 'Steal a Diamond in Dorgesh-Kaan',
    description: 'Steal a chest in Dorgesh-Kaan.',
    skillReqs: [{ skill: 'Thieving', level: 78 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1522: {
    id: '1522',
    label: 'The Frozen Door',
    description: "Complete the mini-quest Quest 'The Frozen Door'.",
    skillReqs: [
      { skill: 'Agility', level: 70 },
      { skill: 'Hitpoints', level: 70 },
      { skill: 'Ranged', level: 70 },
      { skill: 'Strength', level: 70 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.MASTER,
    prerequisite: '',
  },
  1426: {
    id: '1426',
    label: '100 Tempoross Kills',
    description: 'Help the Spirit Anglers subdue the Tempoross 100 times.',
    skillReqs: [{ skill: 'Fishing', level: 35 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.TEMPOROSS,
    prerequisite: '1425',
  },
  1427: {
    id: '1427',
    label: '200 Tempoross Kills',
    description: 'Help the Spirit Anglers subdue the Tempoross 200 times.',
    skillReqs: [{ skill: 'Fishing', level: 35 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.TEMPOROSS,
    prerequisite: '1426',
  },
  1192: {
    id: '1192',
    label: 'Cast Ice Blitz',
    description: 'Cast the Ice Blitz spell.',
    skillReqs: [{ skill: 'Magic', level: 82 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  1189: {
    id: '1189',
    label: 'Cast Ice Burst',
    description: 'Cast the Ice Burst spell.',
    skillReqs: [{ skill: 'Magic', level: 70 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  1211: {
    id: '1211',
    label: 'Cast Paddewwa Teleport',
    description: 'Cast the Paddewwa Teleport spell.',
    skillReqs: [{ skill: 'Magic', level: 54 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  1181: {
    id: '1181',
    label: 'Check a grown Potato Cactus',
    description: "Check the health of a Potato Cactus you've grown in Al Kharid.",
    skillReqs: [{ skill: 'Farming', level: 64 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  1677: {
    id: '1677',
    label: "Commune a Pharoah's Sceptre to the Necropolis",
    description: "Commune a Pharoah's Sceptre to the Necropolis.",
    skillReqs: [{ skill: 'Thieving', level: 21 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1766: {
    id: '1766',
    label: 'Complete Beneath Cursed Sands',
    description: 'Complete Quest Beneath Cursed Sands quest.',
    skillReqs: [
      { skill: 'Agility', level: 62 },
      { skill: 'Crafting', level: 55 },
      { skill: 'Firemaking', level: 55 },
    ],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.MASTER,
    prerequisite: '',
  },
  1203: {
    id: '1203',
    label: 'Complete the Hard Desert Diary',
    description: 'Complete all of the hard tasks in the Diary Desert Achievement Diary.',
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.HARD,
    prerequisite: '',
  },
  1543: {
    id: '1543',
    label: 'Complete Tombs of Amascut 50 times',
    description: 'Complete Tombs of Amascut on normal or Expert 50 times.',
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOA,
    prerequisite: '1542',
  },
  1542: {
    id: '1542',
    label: 'Complete Tombs of Amascut',
    description: 'Complete Tombs of Amascut on normal or Expert.',
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOA,
    prerequisite: '',
  },
  1596: {
    id: '1596',
    label: 'Create the Colossal Rune Pouch',
    description: 'Create the Colossal Rune Pouch from Guardians of the Rift.',
    skillReqs: [
      { skill: 'Runecraft', level: 85 },
      { skill: 'Crafting', level: 56 },
    ],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  1534: {
    id: '1534',
    label: 'Defeat Leviathan 150 times',
    description: 'Defeat Leviathan 150 times.',
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '1533',
  },
  1533: {
    id: '1533',
    label: 'Defeat Leviathan 50 times',
    description: 'Defeat Leviathan 50 times.',
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '1532',
  },
  1197: {
    id: '1197',
    label: 'Defeat the Kalphite Queen 50 Times',
    description: 'Defeat the Kalphite Queen in the Kalphite Lair 50 times.',
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.KQ,
    prerequisite: '1187',
  },
  1198: {
    id: '1198',
    label: 'Defeat the Kalphite Queen 150 Times',
    description: 'Defeat the Kalphite Queen in the Kalphite Lair 150 times.',
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.KQ,
    prerequisite: '1197',
  },
  1187: {
    id: '1187',
    label: 'Defeat the Kalphite Queen',
    description: 'Defeat the Kalphite Queen in the Kalphite Lair.',
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.KQ,
    prerequisite: '',
  },
  1532: {
    id: '1532',
    label: 'Defeat the Leviathan',
    description: 'Defeat the Leviathan.',
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '',
  },
  1200: {
    id: '1200',
    label: 'Equip a Dragon 2-Handed Sword',
    description: 'Equip a Dragon 2-Handed Sword in the Kharidian Desert.',
    skillReqs: [{ skill: 'Attack', level: 60 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.KQ,
    prerequisite: '',
  },
  1602: {
    id: '1602',
    label: "Equip a full set of the Smith's outfit",
    description: "Equip a full set of the Smith's outfit, purchased from the Giant's Foundry minigame.",
    skillReqs: [{ skill: 'Smithing', level: 15 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  1216: {
    id: '1216',
    label: "Equip a Mage's Book",
    description: "Equip a Mage's Book.",
    skillReqs: [{ skill: 'Magic', level: 60 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.MTA,
    prerequisite: '',
  },
  1212: {
    id: '1212',
    label: 'Equip a Master Wand',
    description: 'Equip a Master Wand.',
    skillReqs: [{ skill: 'Magic', level: 60 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.MTA,
    prerequisite: '',
  },
  1597: {
    id: '1597',
    label: 'Equip the Abyssal Lantern',
    description: 'Obtain and Equip the Abyssal Lantern.',
    skillReqs: [{ skill: 'Runecraft', level: 10 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  1591: {
    id: '1591',
    label: 'Equip the Lightbearer',
    description: 'Obtain and Equip the Lightbearer.',
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOA,
    prerequisite: '',
  },
  1572: {
    id: '1572',
    label: "Giants' Foundry 100 hand-ins",
    description: 'Hand in 100 successful swords to Kovac.',
    skillReqs: [{ skill: 'Smithing', level: 15 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '1571',
  },
  1569: {
    id: '1569',
    label: "Giants' Foundry 150 quality sword",
    description: "Hand in a sword with at least 150 quality in Giants' Foundry.",
    skillReqs: [{ skill: 'Smithing', level: 70 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  1571: {
    id: '1571',
    label: "Giants' Foundry 50 hand-ins",
    description: 'Hand in 50 successful swords to Kovac.',
    skillReqs: [{ skill: 'Smithing', level: 15 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '1570',
  },
  1548: {
    id: '1548',
    label: 'Guardians of the Rift 100 Rifts closed',
    description: 'Close the rift in the temple of the eye 100 times.',
    skillReqs: [{ skill: 'Runecraft', level: 10 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '1547',
  },
  1547: {
    id: '1547',
    label: 'Guardians of the Rift 50 Rifts closed',
    description: 'Close the rift in the temple of the eye 50 times.',
    skillReqs: [{ skill: 'Runecraft', level: 10 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '1546',
  },
  1215: {
    id: '1215',
    label: 'Have Zahur Make 1,000 Unfinished Potions',
    description: 'Have Zahur make 1,000 Unfinished Potions.',
    skillReqs: [{ skill: 'Herblore', level: 1 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  1179: {
    id: '1179',
    label: 'Make 20 Magic Potions',
    description: 'Make 20 Magic Potions.',
    skillReqs: [{ skill: 'Herblore', level: 76 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  1698: {
    id: '1698',
    label: 'Make 50 Menaphite Remedies',
    description: 'Make 50 Menaphite Remedies.',
    skillReqs: [{ skill: 'Herblore', level: 88 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  1604: {
    id: '1604',
    label: 'Obtain the Fish Barrel',
    description: 'Obtain the Fish Barrel from Tempoross.',
    skillReqs: [{ skill: 'Fishing', level: 35 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.TEMPOROSS,
    prerequisite: '',
  },
  1168: {
    id: '1168',
    label: "Pick a Summer Sq'irk",
    description: "Pick a Summer Sq'irk in the Sorceress's Garden.",
    skillReqs: [{ skill: 'Thieving', level: 65 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1174: {
    id: '1174',
    label: 'Pickpocket a Menaphite Thug 50 Times',
    description: 'Knock out and then pickpocket a Menaphite Thug 50 times.',
    skillReqs: [{ skill: 'Thieving', level: 65 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1160: {
    id: '1160',
    label: 'Room 7 of Pyramid Plunder',
    description: 'Search the Golden Chest in Room 7 of Pyramid Plunder in Sophanem.',
    skillReqs: [{ skill: 'Thieving', level: 81 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1162: {
    id: '1162',
    label: 'Room 8 of Pyramid Plunder 25 Times',
    description: 'Search the Golden Chest in Room 8 of Pyramid Plunder in Sophanem 25 times.',
    skillReqs: [{ skill: 'Thieving', level: 91 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '1161',
  },
  1161: {
    id: '1161',
    label: 'Room 8 of Pyramid Plunder',
    description: 'Search the Golden Chest in Room 8 of Pyramid Plunder in Sophanem.',
    skillReqs: [{ skill: 'Thieving', level: 91 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  661: {
    id: '661',
    label: 'Smith 1,000 Adamant Dart Tips',
    description: 'Use an Anvil to smith 1,000 Adamant Dart Tips.',
    skillReqs: [{ skill: 'Smithing', level: 74 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  1171: {
    id: '1171',
    label: "Turn in 25 Autumn Sq'irkjuices to Osman",
    description: "Turn in 25 Autumn Sq'irkjuices to Osman in Al Kharid in one go.",
    skillReqs: [{ skill: 'Thieving', level: 45 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  887: {
    id: '887',
    label: 'Build a Gilded Altar',
    description: 'Build a Gilded Altar in a Chapel in your Player Owned House.',
    skillReqs: [{ skill: 'Construction', level: 75 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  884: {
    id: '884',
    label: 'Cast Fertile Soil',
    description: 'Cast the Fertile Soil spell.',
    skillReqs: [{ skill: 'Magic', level: 83 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  949: {
    id: '949',
    label: 'Collect Miscellania Resources at Full Approval',
    description: 'Collect some resources on Miscellania with an approval rating of 100%.',
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  945: {
    id: '945',
    label: 'Complete the Hard Fremennik Diary',
    description: 'Complete all of the hard tasks in the Diary Fremennik Achievement Diary.',
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.HARD,
    prerequisite: '',
  },
  892: {
    id: '892',
    label: 'Complete the Rellekka Agility Course',
    description: 'Complete a lap of the Rellekka Rooftop Agility Course.',
    skillReqs: [{ skill: 'Agility', level: 80 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  777: {
    id: '777',
    label: 'Defeat a Rune Dragon',
    description: 'Defeat a Rune Dragon in the Lithkren Vault.',
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1539: {
    id: '1539',
    label: 'Defeat Duke Sucellus 150 times',
    description: 'Defeat Duke Sucellus 150 times.',
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '1539',
  },
  1538: {
    id: '1538',
    label: 'Defeat Duke Sucellus 50 times',
    description: 'Defeat Duke Sucellus 50 times.',
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '1537',
  },
  1537: {
    id: '1537',
    label: 'Defeat Duke Sucellus',
    description: 'Defeat Duke Sucellus.',
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '',
  },
  934: {
    id: '934',
    label: 'Defeat Each Dagannoth King 150 Times',
    description: 'Defeat all three of the Dagannoth Kings in the Waterbirth Island Dungeon 150 times.',
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DKS,
    prerequisite: '933',
  },
  933: {
    id: '933',
    label: 'Defeat Each Dagannoth King 50 Times',
    description: 'Defeat all three of the Dagannoth Kings in the Waterbirth Island Dungeon 50 times.',
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DKS,
    prerequisite: '',
  },
  1551: {
    id: '1551',
    label: 'Defeat Phantom Muspah 150 times',
    description: 'Defeat Phantom Muspah 150 times.',
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.MUSPAH,
    prerequisite: '1550',
  },
  1550: {
    id: '1550',
    label: 'Defeat Phantom Muspah 50 times',
    description: 'Defeat Phantom Muspah 50 times.',
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.MUSPAH,
    prerequisite: '1549',
  },
  1549: {
    id: '1549',
    label: 'Defeat Phantom Muspah',
    description: 'Defeat Phantom Muspah.',
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.MUSPAH,
    prerequisite: '',
  },
  916: {
    id: '916',
    label: 'Defeat the Dagannoth Kings Without Leaving',
    description: 'Defeat all three of the Dagannoth Kings in the Waterbirth Island Dungeon without leaving their area.',
    skillReqs: [{ skill: 'Ranged', level: 40 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DKS,
    prerequisite: '',
  },
  929: {
    id: '929',
    label: 'Defeat Vorkath 150 Times',
    description: 'Defeat Vorkath on Ungael 150 times.',
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.VORKATH,
    prerequisite: '928',
  },
  928: {
    id: '928',
    label: 'Defeat Vorkath 50 Times',
    description: 'Defeat Vorkath on Ungael 50 times.',
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.VORKATH,
    prerequisite: '902',
  },
  902: {
    id: '902',
    label: 'Defeat Vorkath',
    description: 'Defeat Vorkath on Ungael.',
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.VORKATH,
    prerequisite: '',
  },
  919: {
    id: '919',
    label: 'Equip a Berserker Ring',
    description: 'Equip a Berserker Ring.',
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DKS,
    prerequisite: '',
  },
  942: {
    id: '942',
    label: 'Equip a Full Rockshell Armour Set',
    description: 'Equip a full set of Rockshell armour.',
    skillReqs: [{ skill: 'Defence', level: 40 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.DEFENCE,
    prerequisite: '',
  },
  940: {
    id: '940',
    label: 'Equip a Full Skeletal Armour Set',
    description: 'Equip a full set of Skeletal armour.',
    skillReqs: [
      { skill: 'Defence', level: 40 },
      { skill: 'Magic', level: 40 },
    ],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  941: {
    id: '941',
    label: 'Equip a Full Spined Armour Set',
    description: 'Equip a full set of Spined armour.',
    skillReqs: [
      { skill: 'Defence', level: 40 },
      { skill: 'Ranged', level: 40 },
    ],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.RANGED,
    prerequisite: '',
  },
  939: {
    id: '939',
    label: 'Equip a Leaf-Bladed Battleaxe',
    description: 'Equip a Leaf-Bladed Battleaxe in The Fremennik Provinces.',
    skillReqs: [
      { skill: 'Attack', level: 65 },
      { skill: 'Slayer', level: 55 },
    ],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  937: {
    id: '937',
    label: 'Equip a Mud Battlestaff',
    description: 'Equip a Mud Battlestaff.',
    skillReqs: [
      { skill: 'Magic', level: 30 },
      { skill: 'Attack', level: 30 },
    ],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  924: {
    id: '924',
    label: "Equip a Seer's Ring",
    description: "Equip a Seer's Ring.",
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DKS,
    prerequisite: '',
  },
  938: {
    id: '938',
    label: 'Equip a Seercull',
    description: 'Equip a Seercull.',
    skillReqs: [{ skill: 'Ranged', level: 50 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DKS,
    prerequisite: '',
  },
  921: {
    id: '921',
    label: 'Equip a Warrior Ring',
    description: 'Equip a Warrior Ring.',
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DKS,
    prerequisite: '',
  },
  927: {
    id: '927',
    label: "Equip an Archer's Ring",
    description: "Equip an Archer's Ring.",
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DKS,
    prerequisite: '',
  },
  907: {
    id: '907',
    label: "Equip an Ava's Assembler",
    description: "Equip an Ava's Assembler.",
    skillReqs: [{ skill: 'Ranged', level: 70 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.VORKATH,
    prerequisite: '',
  },
  1583: {
    id: '1583',
    label: 'Equip the Ancient Sceptre',
    description: 'Obtain and Equip the Ancient Sceptre.',
    skillReqs: [
      { skill: 'Magic', level: 70 },
      { skill: 'Attack', level: 50 },
      { skill: 'Strength', level: 60 },
    ],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.MUSPAH,
    prerequisite: '',
  },
  893: {
    id: '893',
    label: 'Harvest a Snapdragon in Weiss',
    description: "Harvest a Snapdragon you've grown in Weiss.",
    skillReqs: [{ skill: 'Farming', level: 62 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  895: {
    id: '895',
    label: 'Trap a Sabre-toothed Kyatt',
    description: 'Trap a Sabre-toothed Kyatt.',
    skillReqs: [{ skill: 'Hunter', level: 55 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  932: {
    id: '932',
    label: 'Use the Special Attack of a Dragon Axe',
    description: 'Use the special attack of a Dragon Axe.',
    skillReqs: [{ skill: 'Attack', level: 60 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DKS,
    prerequisite: '',
  },
  898: {
    id: '898',
    label: 'Use Your Portal Nexus to Teleport to Weiss',
    description: 'Use a Portal Nexus in your Player Owned House to teleport to Weiss.',
    skillReqs: [
      { skill: 'Mining', level: 72 },
      { skill: 'Construction', level: 72 },
    ],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  1874: {
    id: '1874',
    label: 'Complete Path of Glouphrie',
    description: 'Complete the Quest Path of Glouphrie quest.',
    skillReqs: [
      { skill: 'Strength', level: 60 },
      { skill: 'Slayer', level: 56 },
      { skill: 'Thieving', level: 56 },
      { skill: 'Ranged', level: 47 },
      { skill: 'Agility', level: 45 },
    ],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.EXPERIENCED,
    prerequisite: '',
  },
  1385: {
    id: '1385',
    label: 'Complete the Hard Ardougne Diary',
    description: 'Complete all of the hard tasks in the Diary Ardougne Achievement Diary.',
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.HARD,
    prerequisite: '',
  },
  1084: {
    id: '1084',
    label: 'Complete the Hard Kandarin Diary',
    description: 'Complete all of the hard tasks in the Diary Kandarin Achievement Diary.',
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.HARD,
    prerequisite: '',
  },
  1011: {
    id: '1011',
    label: "Complete the Seers' Village Agility Course",
    description: "Complete a lap of the Seers' Village Rooftop Agility Course.",
    skillReqs: [{ skill: 'Agility', level: 60 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  1054: {
    id: '1054',
    label: 'Defeat 150 Demonic Gorillas',
    description: 'Defeat 150 Demonic Gorillas in the Crash Site Cavern.',
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1041: {
    id: '1041',
    label: 'Defeat a Cave Kraken',
    description: 'Defeat a Cave Kraken in Kraken Cove.',
    skillReqs: [{ skill: 'Slayer', level: 87 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  1029: {
    id: '1029',
    label: 'Defeat a Demonic Gorilla',
    description: 'Defeat a Demonic Gorilla in the Crash Site Cavern.',
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1030: {
    id: '1030',
    label: 'Defeat a Smoke Devil',
    description: 'Defeat a Smoke Devil in the Smoke Devil Dungeon.',
    skillReqs: [{ skill: 'Slayer', level: 93 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  1052: {
    id: '1052',
    label: 'Defeat the Kraken Boss 150 Times',
    description: 'Defeat the Kraken boss in Kraken Cove 150 times.',
    skillReqs: [{ skill: 'Slayer', level: 87 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.KRAKEN,
    prerequisite: '1051',
  },
  1051: {
    id: '1051',
    label: 'Defeat the Kraken Boss 50 Times',
    description: 'Defeat the Kraken boss in Kraken Cove 50 times.',
    skillReqs: [{ skill: 'Slayer', level: 87 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.KRAKEN,
    prerequisite: '',
  },
  1059: {
    id: '1059',
    label: "Enter the Myths' Guild",
    description: "Enter the Myths' Guild.",
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.GRANDMASTER,
    prerequisite: '',
  },
  1038: {
    id: '1038',
    label: 'Equip a Fighter Torso',
    description: 'Equip a Fighter Torso.',
    skillReqs: [{ skill: 'Defence', level: 40 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.BA,
    prerequisite: '',
  },
  1022: {
    id: '1022',
    label: "Equip a Full Angler's Outfit",
    description: 'Equip a full set of Angler gear.',
    skillReqs: [{ skill: 'Fishing', level: 34 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  1078: {
    id: '1078',
    label: 'Equip a Karamja Monkey Backpack',
    description: 'Equip a Karamja Monkey Backpack.',
    skillReqs: [{ skill: 'Agility', level: 48 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  1045: {
    id: '1045',
    label: 'Equip a Trident of the Seas',
    description: 'Equip a Trident of the Seas.',
    skillReqs: [
      { skill: 'Magic', level: 75 },
      { skill: 'Slayer', level: 87 },
    ],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  1873: {
    id: '1873',
    label: 'Equip a Warped Sceptre',
    description: 'Obtain and Equip a Warped Sceptre.',
    skillReqs: [
      { skill: 'Magic', level: 62 },
      { skill: 'Slayer', level: 56 },
    ],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  1074: {
    id: '1074',
    label: 'Equip an Ogre Expert Chompy Hat',
    description: 'Equip an Ogre Expert Chompy Hat.',
    skillReqs: [
      { skill: 'Fletching', level: 5 },
      { skill: 'Cooking', level: 30 },
      { skill: 'Ranged', level: 30 },
      { skill: 'Crafting', level: 5 },
    ],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.CHOMPIES,
    prerequisite: '1073',
  },
  1875: {
    id: '1875',
    label: 'Feed Longramble',
    description: 'Deliver some Tangled Toads Legs to Longramble.',
    skillReqs: [{ skill: 'Cooking', level: 40 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  1023: {
    id: '1023',
    label: 'Fletch 100 Maple Longbow (u) in Kandarin',
    description: 'Fletch 100 Maple Longbow (u) in Kandarin.',
    skillReqs: [{ skill: 'Fletching', level: 55 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FLETCHING,
    prerequisite: '',
  },
  1797: {
    id: '1797',
    label: 'Kill a Frogeel',
    description: 'Kill a Frogeel underneath the Tower of Life.',
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1798: {
    id: '1798',
    label: 'Kill a Jubster',
    description: 'Kill a Jubster underneath the Tower of Life.',
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1008: {
    id: '1008',
    label: 'Pickpocket a Hero',
    description: 'Pickpocket any Hero.',
    skillReqs: [{ skill: 'Thieving', level: 80 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1062: {
    id: '1062',
    label: 'Reach Level 5 in Any Barbarian Assault Role',
    description: 'Reach level 5 in any Barbarian Assault Role.',
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.BA,
    prerequisite: '',
  },
  1050: {
    id: '1050',
    label: 'Use the Piety Prayer',
    description: 'Use the Piety Prayer.',
    skillReqs: [
      { skill: 'Prayer', level: 70 },
      { skill: 'Defence', level: 70 },
    ],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.PRAYER,
    prerequisite: '',
  },
  967: {
    id: '967',
    label: 'Check a grown Calquat Tree',
    description: "Check the health of a Calquat Tree you've grown on Karamja.",
    skillReqs: [{ skill: 'Farming', level: 72 }],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  996: {
    id: '996',
    label: 'Complete the Hard Karamja Diary',
    description: 'Complete all of the hard tasks in the Diary Karamja Achievement Diary.',
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.HARD,
    prerequisite: '',
  },
  1553: {
    id: '1553',
    label: 'Complete Tzhaar-Ket-Raks first challenge',
    description: 'Complete Tzhaar-Ket-Raks first challenge.',
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GENERAL,
    prerequisite: '',
  },
  1554: {
    id: '1554',
    label: 'Complete Tzhaar-Ket-Raks second challenge',
    description: 'Complete Tzhaar-Ket-Raks second challenge.',
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GENERAL,
    prerequisite: '',
  },
  1752: {
    id: '1752',
    label: 'Cook 20 Karambwans in a row',
    description: 'Cook 20 Karambwans in a row without burning them.',
    skillReqs: [{ skill: 'Cooking', level: 30 }],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  972: {
    id: '972',
    label: 'Equip a Full Obsidian Armour Set',
    description: 'Equip a full set of Obsidian armour.',
    skillReqs: [{ skill: 'Defence', level: 60 }],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.DEFENCE,
    prerequisite: '',
  },
  1000: {
    id: '1000',
    label: 'Equip a Red Topaz Machete',
    description: 'Equip a Red Topaz Machete.',
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  976: {
    id: '976',
    label: 'Equip a Toktz-Ket-Xil',
    description: 'Equip a Toktz-Ket-Xil.',
    skillReqs: [{ skill: 'Defence', level: 60 }],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.DEFENCE,
    prerequisite: '',
  },
  985: {
    id: '985',
    label: 'Equip a Toktz-Xil-Ak',
    description: 'Equip a Toktz-Xil-Ak.',
    skillReqs: [{ skill: 'Attack', level: 60 }],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.ATTACK,
    prerequisite: '',
  },
  986: {
    id: '986',
    label: 'Equip a Toktz-Xil-Ek',
    description: 'Equip a Toktz-Xil-Ek.',
    skillReqs: [{ skill: 'Attack', level: 60 }],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.ATTACK,
    prerequisite: '',
  },
  981: {
    id: '981',
    label: 'Equip a TzHaar-Ket-Om',
    description: 'Equip a TzHaar-Ket-Om.',
    skillReqs: [{ skill: 'Strength', level: 60 }],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.STRENGTH,
    prerequisite: '',
  },
  971: {
    id: '971',
    label: 'Equip an Obsidian Cape',
    description: 'Equip an Obsidian Cape.',
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  982: {
    id: '982',
    label: 'Equip Some Dragon Platelegs or a Dragon Plateskirt',
    description: 'Equip either some Dragon Platelegs or a Dragon Plateskirt.',
    skillReqs: [{ skill: 'Defence', level: 60 }],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.DEFENCE,
    prerequisite: '',
  },
  1004: {
    id: '1004',
    label: 'Find a Gout Tuber',
    description: 'Find a Gout Tuber in Tai Bwo Wannai.',
    skillReqs: [{ skill: 'Woodcutting', level: 35 }],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  961: {
    id: '961',
    label: 'Receive 30 Agility Arena Tickets With No Mistakes',
    description: 'Receive 30 Agility Arena Tickets without missing any pillars.',
    skillReqs: [{ skill: 'Agility', level: 1 }],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  977: {
    id: '977',
    label: 'Survive a Hit From TzTok-Jad Without Prayer',
    description:
      'Survive a Magic or Ranged hit from TzTok-Jad in the TzHaar Fight Cave without using a protection Prayer.',
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.JAD,
    prerequisite: '',
  },
  960: {
    id: '960',
    label: 'Take a Shortcut Across the Shilo Village River',
    description: 'Take a shortcut across the Shilo Village river.',
    skillReqs: [{ skill: 'Agility', level: 77 }],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  348: {
    id: '348',
    label: '1 Alchemical Hydra Kill',
    description: 'Kill the Alchemical Hydra in Mount Karuulm.',
    skillReqs: [{ skill: 'Slayer', level: 95 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.HYDRA,
    prerequisite: '',
  },
  359: {
    id: '359',
    label: '1 Chambers of Xeric',
    description: 'Complete the Chambers of Xeric.',
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.COX,
    prerequisite: '',
  },
  470: {
    id: '470',
    label: '1 Mimic Kill',
    description: 'Kill the Mimic as part of a Treasure Trail.',
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.MIMIC,
    prerequisite: '',
  },
  339: {
    id: '339',
    label: '1 Skotizo Kill',
    description: 'Kill Skotizo beneath the Catacombs of Kourend.',
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SKOTIZO,
    prerequisite: '',
  },
  340: {
    id: '340',
    label: '10 Skotizo Kills',
    description: 'Kill Skotizo beneath the Catacombs of Kourend 10 times.',
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SKOTIZO,
    prerequisite: '339',
  },
  235: {
    id: '235',
    label: '100 Wintertodt Kills',
    description: 'Help the Pyromancers subdue the Wintertodt 100 times.',
    skillReqs: [{ skill: 'Firemaking', level: 50 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WINTERTODT,
    prerequisite: '234',
  },
  350: {
    id: '350',
    label: '150 Alchemical Hydra Kills',
    description: 'Kill the Alchemical Hydra in Mount Karuulm 150 times.',
    skillReqs: [{ skill: 'Slayer', level: 95 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.HYDRA,
    prerequisite: '349',
  },
  361: {
    id: '361',
    label: '150 Chambers of Xeric',
    description: 'Complete the Chambers of Xeric Normal or Challenge Mode 150 times.',
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.COX,
    prerequisite: '360',
  },
  330: {
    id: '330',
    label: '150 Sarachnis Kills',
    description: 'Kill Sarachnis in the Forthos Dungeon 150 times.',
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SARACHNIS,
    prerequisite: '329',
  },
  236: {
    id: '236',
    label: '200 Wintertodt Kills',
    description: 'Help the Pyromancers subdue the Wintertodt 200 times.',
    skillReqs: [{ skill: 'Firemaking', level: 50 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WINTERTODT,
    prerequisite: '235',
  },
  341: {
    id: '341',
    label: '25 Skotizo Kills',
    description: 'Kill Skotizo beneath the Catacombs of Kourend 25 times.',
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SKOTIZO,
    prerequisite: '340',
  },
  331: {
    id: '331',
    label: '300 Sarachnis Kills',
    description: 'Kill Sarachnis in the Forthos Dungeon 300 times.',
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SARACHNIS,
    prerequisite: '330',
  },
  471: {
    id: '471',
    label: '5 Mimic Kills',
    description: 'Kill the Mimic as part of a Treasure Trail 5 times.',
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.MIMIC,
    prerequisite: '470',
  },
  349: {
    id: '349',
    label: '50 Alchemical Hydra Kills',
    description: 'Kill the Alchemical Hydra in Mount Karuulm 50 times.',
    skillReqs: [{ skill: 'Slayer', level: 95 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.HYDRA,
    prerequisite: '348',
  },
  360: {
    id: '360',
    label: '50 Chambers of Xeric',
    description: 'Complete the Chambers of Xeric Normal or Challenge Mode 50 times.',
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.COX,
    prerequisite: '359',
  },
  323: {
    id: '323',
    label: '750 Lizardmen Shaman Kills',
    description: 'Help the Shayzien House by killing 750 Lizardmen shamans.',
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '322',
  },
  1392: {
    id: '1392',
    label: 'Activate an Arcane or Dexterous Prayer Scroll',
    description: 'Activate an Arcane or Dexterous Prayer scroll.',
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.COX,
    prerequisite: '',
  },
  1473: {
    id: '1473',
    label: 'Cast Full Sinister Offering',
    description: 'Offer 3 bones from your inventory at the same time using the Sinister Offering spell.',
    skillReqs: [{ skill: 'Magic', level: 92 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  1623: {
    id: '1623',
    label: 'Chop 200 Redwood Logs',
    description: 'Chop 200 Redwood Logs from Redwood Trees.',
    skillReqs: [{ skill: 'Woodcutting', level: 90 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  247: {
    id: '247',
    label: 'Complete 150 Farming Contracts',
    description: 'Complete 150 Farming Contracts for Guildmaster Jane in the Farming Guild.',
    skillReqs: [{ skill: 'Farming', level: 45 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '246',
  },
  246: {
    id: '246',
    label: 'Complete 50 Farming Contracts',
    description: 'Complete 50 Farming Contracts for Guildmaster Jane in the Farming Guild.',
    skillReqs: [{ skill: 'Farming', level: 45 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '245',
  },
  1389: {
    id: '1389',
    label: 'Defeat a Hydra',
    description: 'Defeat a regular Hydra in the Mount Karuulm Slayer Dungeon.',
    skillReqs: [{ skill: 'Slayer', level: 95 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  1524: {
    id: '1524',
    label: 'Defeat Vardorvis 150 times',
    description: 'Defeat Vardorvis 150 times.',
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '1523',
  },
  1523: {
    id: '1523',
    label: 'Defeat Vardorvis 50 times',
    description: 'Defeat Vardorvis 50 times.',
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '90000',
  },
  // TODO this had the same ID as The Frozen Door (1522), using temporary hardcoded ID
  90000: {
    id: '90000',
    label: 'Defeat Vardorvis',
    description: 'Defeat Vardorvis.',
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '',
  },
  277: {
    id: '277',
    label: "Enter the Farming Guild's High Tier",
    description: 'Enter the high tier of the Farming Guild in the Kebos Lowlands.',
    skillReqs: [{ skill: 'Farming', level: 85 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  391: {
    id: '391',
    label: 'Equip a Brimstone Ring',
    description: 'Obtain and equip a Brimstone ring.',
    skillReqs: [{ skill: 'Slayer', level: 95 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.HYDRA,
    prerequisite: '',
  },
  385: {
    id: '385',
    label: 'Equip a Dust Battlestaff',
    description: 'Obtain and equip a Dust battlestaff.',
    skillReqs: [
      { skill: 'Slayer', level: 5 },
      { skill: 'Attack', level: 30 },
      { skill: 'Magic', level: 30 },
    ],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  491: {
    id: '491',
    label: 'Equip a Pearl Barbarian Rod',
    description: 'Equip a Pearl barbarian rod, purchased from Alry on Molch Island.',
    skillReqs: [
      { skill: 'Hunter', level: 35 },
      { skill: 'Fishing', level: 43 },
    ],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  490: {
    id: '490',
    label: 'Equip a Pearl Fishing Rod',
    description: 'Equip a Pearl fishing rod, purchased from Alry on Molch Island.',
    skillReqs: [
      { skill: 'Hunter', level: 35 },
      { skill: 'Fishing', level: 43 },
    ],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  1398: {
    id: '1398',
    label: 'Equip a Sarachnis Cudgel',
    description: 'Equip a Sarachnis Cudgel.',
    skillReqs: [{ skill: 'Attack', level: 65 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SARACHNIS,
    prerequisite: '',
  },
  384: {
    id: '384',
    label: 'Equip Boots of Brimstone',
    description: 'Obtain and equip a pair of Boots of brimstone.',
    skillReqs: [
      { skill: 'Slayer', level: 84 },
      { skill: 'Defence', level: 70 },
      { skill: 'Magic', level: 70 },
      { skill: 'Ranged', level: 70 },
    ],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  389: {
    id: '389',
    label: 'Equip Ferocious Gloves',
    description: 'Obtain and equip a pair of Ferocious gloves.',
    skillReqs: [
      { skill: 'Slayer', level: 95 },
      { skill: 'Attack', level: 80 },
      { skill: 'Defence', level: 80 },
    ],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.HYDRA,
    prerequisite: '',
  },
  488: {
    id: '488',
    label: "Equip the Angler's Outfit",
    description: "Equip a full set of the Angler's outfit, purchased from Alry on Molch Island.",
    skillReqs: [
      { skill: 'Fishing', level: 43 },
      { skill: 'Hunter', level: 35 },
    ],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  487: {
    id: '487',
    label: "Equip the Farmer's Outfit",
    description: "Equip a full set of the Farmer's outfit, purchased from the Tithe Farm minigame.",
    skillReqs: [{ skill: 'Farming', level: 34 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  489: {
    id: '489',
    label: "Equip the Pyromancer's Garb",
    description: "Equip a full set of Pyromancer's garb, rewarded from the Wintertodt.",
    skillReqs: [{ skill: 'Firemaking', level: 50 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WINTERTODT,
    prerequisite: '',
  },
  1720: {
    id: '1720',
    label: 'Fletch a Redwood Shield',
    description: 'Fletch a Redwood Shield.',
    skillReqs: [
      { skill: 'Fletching', level: 92 },
      { skill: 'Woodcutting', level: 90 },
    ],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FLETCHING,
    prerequisite: '',
  },
  269: {
    id: '269',
    label: 'Friend of House Lovakengj',
    description: 'Achieve 100% favour with the Lovakengj House.',
    skillReqs: [{ skill: 'Mining', level: 42 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.FAVOUR,
    prerequisite: '',
  },
  412: {
    id: '412',
    label: 'Kourend and Kebos Hard Diary Tasks',
    description: 'Complete all tasks in the hard tier of the Diary Kourend and Kebos achievement diary.',
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.HARD,
    prerequisite: '',
  },
  273: {
    id: '273',
    label: 'Plant 100 Bologano Seeds',
    description: 'Plant 100 Bologano seeds in the Tithe Farm minigame.',
    skillReqs: [{ skill: 'Farming', level: 54 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  274: {
    id: '274',
    label: 'Plant 100 Logavano Seeds',
    description: 'Plant 100 Logavano seeds in the Tithe Farm minigame.',
    skillReqs: [{ skill: 'Farming', level: 74 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  1619: {
    id: '1619',
    label: 'Plant an Anima Seed',
    description: 'Plant an Anima Seed in the Farming Guild.',
    skillReqs: [{ skill: 'Farming', level: 76 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  750: {
    id: '750',
    label: 'Catch 200 Implings in Puro-Puro',
    description: 'Catch 200 Implings in Puro-Puro.',
    skillReqs: [{ skill: 'Hunter', level: 17 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '754',
  },
  751: {
    id: '751',
    label: 'Catch a Herbiboar 50 Times',
    description: 'Catch a Herbiboar on Fossil Island 50 times.',
    skillReqs: [
      { skill: 'Hunter', level: 80 },
      { skill: 'Herblore', level: 31 },
    ],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  761: {
    id: '761',
    label: 'Check a grown Magic Tree in Lumbridge',
    description: "Check the health of a Magic Tree you've grown in Lumbridge.",
    skillReqs: [{ skill: 'Farming', level: 75 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  762: {
    id: '762',
    label: 'Check a grown Mahogany Tree',
    description: "Check the health of a Mahogany Tree you've grown on Fossil Island.",
    skillReqs: [{ skill: 'Farming', level: 55 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  799: {
    id: '799',
    label: 'Complete the Hard Lumbridge & Draynor Diary',
    description: 'Complete all of the hard tasks in the Diary Lumbridge & Draynor Achievement Diary.',
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.HARD,
    prerequisite: '',
  },
  1379: {
    id: '1379',
    label: 'Complete the Hard Varrock Diary',
    description: 'Complete all of the hard tasks in the Diary Varrock Achievement Diary.',
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.HARD,
    prerequisite: '',
  },
  789: {
    id: '789',
    label: 'Defeat the Abyssal Sire',
    description: 'Defeat the Abyssal Sire in the Abyssal Nexus.',
    skillReqs: [{ skill: 'Slayer', level: 85 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SIRE,
    prerequisite: '',
  },
  782: {
    id: '782',
    label: 'Equip a Hill Giant Club',
    description: 'Equip a Hill Giant Club.',
    skillReqs: [{ skill: 'Attack', level: 40 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.OBOR,
    prerequisite: '',
  },
  786: {
    id: '786',
    label: "Equip Bryophyta's Staff",
    description: "Equip Bryophyta's Staff.",
    skillReqs: [
      { skill: 'Magic', level: 30 },
      { skill: 'Attack', level: 30 },
      { skill: 'Crafting', level: 62 },
    ],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.BRYOPHYTA,
    prerequisite: '',
  },
  793: {
    id: '793',
    label: 'Offer an Unsired to the Font of Consumption',
    description: 'Offer an Unsired to the Font of Consumption in the Abyssal Nexus.',
    skillReqs: [{ skill: 'Slayer', level: 85 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SIRE,
    prerequisite: '',
  },
  1482: {
    id: '1482',
    label: 'Purchase an Ectoplasmator',
    description: 'Purchase an Ectoplasmator from Nomad on the Isle of Souls.',
    skillReqs: [
      { skill: 'Combat', level: 40 },
      { skill: 'Overall', level: 40 },
    ],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.OTHER,
    prerequisite: '',
  },
  748: {
    id: '748',
    label: 'Set up a Magic Bird House',
    description: 'Set up a Magic Bird House on Fossil Island.',
    skillReqs: [
      { skill: 'Hunter', level: 74 },
      { skill: 'Crafting', level: 75 },
    ],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  770: {
    id: '770',
    label: 'Take the Advanced Shortcut to the Cosmic Altar',
    description: 'Use the Advanced Narrow Walkway Agility Shortcut near the Cosmic Altar.',
    skillReqs: [{ skill: 'Agility', level: 66 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  1683: {
    id: '1683',
    label: 'Try and feed a stray dog something exotic',
    description: 'Try and feed a stray dog some exotic bones.',
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1713: {
    id: '1713',
    label: 'Burn 20 Blisterwood Logs',
    description: 'Burn 20 Blisterwood Logs.',
    skillReqs: [
      { skill: 'Firemaking', level: 62 },
      { skill: 'Woodcutting', level: 62 },
    ],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FIREMAKING,
    prerequisite: '',
  },
  1286: {
    id: '1286',
    label: 'Complete a Hard Temple Trek',
    description: 'Complete a hard Minigame Temple Trek.',
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.OTHER,
    prerequisite: '',
  },
  1289: {
    id: '1289',
    label: 'Complete Sins of the Father',
    description: 'Complete the Quest Sins of the Father quest.',
    skillReqs: [
      { skill: 'Woodcutting', level: 62 },
      { skill: 'Fletching', level: 60 },
      { skill: 'Crafting', level: 56 },
      { skill: 'Agility', level: 52 },
      { skill: 'Attack', level: 50 },
      { skill: 'Slayer', level: 50 },
      { skill: 'Magic', level: 49 },
    ],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.MASTER,
    prerequisite: '',
  },
  1282: {
    id: '1282',
    label: 'Complete the Hard Morytania Diary',
    description: 'Complete all of the hard tasks in the Diary Morytania Achievement Diary.',
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.HARD,
    prerequisite: '',
  },
  1247: {
    id: '1247',
    label: 'Complete the Theatre of Blood With No Deaths',
    description: 'Complete the Theatre of Blood on Normal or Hard Mode without any party member dying.',
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOB,
    prerequisite: '',
  },
  1618: {
    id: '1618',
    label: 'Create the long rope shortcut in Darkmeyer',
    description: 'Create the long rope agility shortcut in Darkmeyer.',
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1617: {
    id: '1617',
    label: 'Defeat a Urium Shade',
    description: 'Defeat a Urium Shade.',
    skillReqs: [{ skill: 'Firemaking', level: 80 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.SHADES,
    prerequisite: '',
  },
  1410: {
    id: '1410',
    label: "Defeat Phosani's Nightmare",
    description: "Defeat Phosani's Nightmare in the Sisterhood Sanctuary.",
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.NIGHTMARE,
    prerequisite: '',
  },
  1279: {
    id: '1279',
    label: 'Defeat the Grotesque Guardians 150 Times',
    description: 'Defeat the Grotesque Guardians at the Slayer Tower 150 times.',
    skillReqs: [{ skill: 'Slayer', level: 75 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GGS,
    prerequisite: '1278',
  },
  1278: {
    id: '1278',
    label: 'Defeat the Grotesque Guardians 50 Times',
    description: 'Defeat the Grotesque Guardians at the Slayer Tower 50 times.',
    skillReqs: [{ skill: 'Slayer', level: 75 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GGS,
    prerequisite: '1277',
  },
  1277: {
    id: '1277',
    label: 'Defeat the Grotesque Guardians',
    description: 'Defeat the Grotesque Guardians at the Slayer Tower.',
    skillReqs: [{ skill: 'Slayer', level: 75 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GGS,
    prerequisite: '',
  },
  1411: {
    id: '1411',
    label: 'Defeat The Nightmare 50 times',
    description: "Defeat The Nightmare or Phosani's Nightmare 50 times combined.",
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.NIGHTMARE,
    prerequisite: '',
  },
  1243: {
    id: '1243',
    label: 'Defeat The Nightmare',
    description: 'Defeat The Nightmare in the Sisterhood Sanctuary.',
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.NIGHTMARE,
    prerequisite: '',
  },
  1490: {
    id: '1490',
    label: 'Equip a Full set of Swampbark armour',
    description:
      'Equip a Swampbark helm, a Swampbark body, some Swampbark legs, some Swampbark gauntlets and Swampbark boots.',
    skillReqs: [
      { skill: 'Magic', level: 50 },
      { skill: 'Defence', level: 50 },
      { skill: 'Firemaking', level: 35 },
    ],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.SHADES,
    prerequisite: '',
  },
  1477: {
    id: '1477',
    label: "Equip a Full Set of Zealot's Robes",
    description: "Equip a Zealot's Helm, a Zealot's Robe Top, a Zealot's Robe Bottom and a Zealot's Boots.",
    skillReqs: [{ skill: 'Firemaking', level: 80 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.SHADES,
    prerequisite: '',
  },
  1258: {
    id: '1258',
    label: 'Equip a Granite Hammer or Granite Ring',
    description: 'Equip either a Granite Hammer or a Granite Ring.',
    skillReqs: [
      { skill: 'Slayer', level: 75 },
      { skill: 'Strength', level: 50 },
      { skill: 'Attack', level: 50 },
      { skill: 'Defence', level: 50 },
    ],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GGS,
    prerequisite: '',
  },
  1476: {
    id: '1476',
    label: "Equip a Piece of Zealot's Robes",
    description: "Equip either a Zealot's helm, a Zealot's robe top, a Zealot's robe bottom or a Zealot's boots.",
    skillReqs: [{ skill: 'Firemaking', level: 80 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.SHADES,
    prerequisite: '',
  },
  1254: {
    id: '1254',
    label: 'Equip any Full Barrows Armour Set',
    description: 'Equip a full set of any Barrows armour + weapon.',
    skillReqs: [
      { skill: 'Defence', level: 70 },
      { skill: 'Attack', level: 70 },
      { skill: 'Ranged', level: 70 },
    ],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.BARROWS,
    prerequisite: '',
  },
  1864: {
    id: '1864',
    label: 'Equip Full Ahrims Armour Set',
    description: 'Equip a full set of Ahrims items.',
    skillReqs: [
      { skill: 'Magic', level: 70 },
      { skill: 'Defence', level: 70 },
      { skill: 'Attack', level: 70 },
    ],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.BARROWS,
    prerequisite: '',
  },
  1866: {
    id: '1866',
    label: 'Equip Full Dharoks Armour Set',
    description: 'Equip a full set of Dharoks items.',
    skillReqs: [
      { skill: 'Strength', level: 70 },
      { skill: 'Defence', level: 70 },
      { skill: 'Attack', level: 70 },
    ],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.BARROWS,
    prerequisite: '',
  },
  1865: {
    id: '1865',
    label: 'Equip Full Guthans Armour Set',
    description: 'Equip a full set of Guthans items.',
    skillReqs: [
      { skill: 'Defence', level: 70 },
      { skill: 'Attack', level: 70 },
    ],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.BARROWS,
    prerequisite: '',
  },
  1869: {
    id: '1869',
    label: 'Equip Full Karils Armour Set',
    description: 'Equip a full set of Karils items.',
    skillReqs: [
      { skill: 'Ranged', level: 70 },
      { skill: 'Defence', level: 70 },
    ],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.BARROWS,
    prerequisite: '',
  },
  1868: {
    id: '1868',
    label: 'Equip Full Torags Armour Set',
    description: 'Equip a full set of Torags items.',
    skillReqs: [
      { skill: 'Strength', level: 70 },
      { skill: 'Defence', level: 70 },
      { skill: 'Attack', level: 70 },
    ],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.BARROWS,
    prerequisite: '',
  },
  1867: {
    id: '1867',
    label: 'Equip Full Veracs Armour Set',
    description: 'Equip a full set of Veracs items.',
    skillReqs: [
      { skill: 'Defence', level: 70 },
      { skill: 'Attack', level: 70 },
    ],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.BARROWS,
    prerequisite: '',
  },
  1224: {
    id: '1224',
    label: 'Floor 3 of the Hallowed Sepulchre',
    description: 'Complete floor 3 of the Hallowed Sepulchre in Darkmeyer.',
    skillReqs: [{ skill: 'Agility', level: 72 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  1225: {
    id: '1225',
    label: 'Floor 4 of the Hallowed Sepulchre',
    description: 'Complete floor 4 of the Hallowed Sepulchre in Darkmeyer.',
    skillReqs: [{ skill: 'Agility', level: 82 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  1231: {
    id: '1231',
    label: 'Obtain Every Hallowed Tool',
    description: 'Obtain all four Hallowed Tools from the Hallowed Sepulchre.',
    skillReqs: [{ skill: 'Agility', level: 52 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  1480: {
    id: '1480',
    label: 'Open a Gold Chest',
    description: "Open a Shades of Mort'ton.",
    skillReqs: [{ skill: 'Firemaking', level: 80 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.SHADES,
    prerequisite: '',
  },
  1235: {
    id: '1235',
    label: 'Pickpocket a Vyre 50 Times',
    description: 'Pickpocket a Vyre 50 times.',
    skillReqs: [{ skill: 'Thieving', level: 82 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1230: {
    id: '1230',
    label: 'Purchase a Reward From the Hallowed Sepulchre',
    description: 'Purchase a reward from the Hallowed Sepulchre.',
    skillReqs: [{ skill: 'Agility', level: 52 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  1680: {
    id: '1680',
    label: 'Unlock permanent boat travel with Andras',
    description: 'Unlock permanent boat travel with Andras in Morytania.',
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  828: {
    id: '828',
    label: 'Catch a Crystal Impling',
    description: 'Catch a Crystal Impling in Prifddinas.',
    skillReqs: [{ skill: 'Hunter', level: 80 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  827: {
    id: '827',
    label: 'Check a grown Crystal Tree',
    description: "Check the health of a Crystal Tree you've grown in Prifddinas.",
    skillReqs: [{ skill: 'Farming', level: 74 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  1742: {
    id: '1742',
    label: 'Chop 100 Teak Logs in Prifddinas',
    description: 'Chop 100 Teak Logs in Prifddinas.',
    skillReqs: [{ skill: 'Woodcutting', level: 35 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  1741: {
    id: '1741',
    label: 'Complete 50 laps of the Prifddinas Agility Course',
    description: 'Complete 50 laps of the Prifddinas Agility Course.',
    skillReqs: [{ skill: 'Agility', level: 75 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  844: {
    id: '844',
    label: 'Complete the Corrupted Gauntlet',
    description: 'Complete the Corrupted Gauntlet in Prifddinas.',
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GAUNTLET,
    prerequisite: '',
  },
  832: {
    id: '832',
    label: 'Complete the Prifddinas Agility Course in 1:10',
    description: 'Complete a lap of the Prifddinas Agility Course in 1:10 or less.',
    skillReqs: [{ skill: 'Agility', level: 66 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  831: {
    id: '831',
    label: 'Complete the Prifddinas Agility Course',
    description: 'Complete a lap of the Prifddinas Agility Course.',
    skillReqs: [{ skill: 'Agility', level: 75 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  839: {
    id: '839',
    label: 'Craft a Piece of Crystal Armour',
    description: 'Use a Singing Bowl to craft any piece of Crystal armour.',
    skillReqs: [
      { skill: 'Smithing', level: 70 },
      { skill: 'Crafting', level: 70 },
    ],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '',
  },
  875: {
    id: '875',
    label: 'Craft an Eternal Teleport Crystal',
    description: 'Use a Singing Bowl to craft an Eternal Teleport Crystal.',
    skillReqs: [
      { skill: 'Smithing', level: 80 },
      { skill: 'Crafting', level: 80 },
    ],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '',
  },
  851: {
    id: '851',
    label: 'Defeat a Dark Beast in Tirannwn',
    description: 'Defeat a Dark Beast in Tirannwn.',
    skillReqs: [{ skill: 'Slayer', level: 90 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  872: {
    id: '872',
    label: 'Defeat a Nechryael in Tirannwn',
    description: 'Defeat a Nechryael in Tirannwn.',
    skillReqs: [{ skill: 'Slayer', level: 80 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  867: {
    id: '867',
    label: 'Defeat Zalcano 50 Times',
    description: 'Defeat Zalcano in Prifddinas 50 times.',
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.ZALCANO,
    prerequisite: '847',
  },
  847: {
    id: '847',
    label: 'Defeat Zalcano',
    description: 'Defeat Zalcano in Prifddinas.',
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.ZALCANO,
    prerequisite: '',
  },
  870: {
    id: '870',
    label: 'Defeat Zulrah 50 Times',
    description: 'Defeat Zulrah at the Poison Waste 50 times.',
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.ZULRAH,
    prerequisite: '849',
  },
  849: {
    id: '849',
    label: 'Defeat Zulrah',
    description: 'Defeat Zulrah at the Poison Waste.',
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.ZULRAH,
    prerequisite: '',
  },
  863: {
    id: '863',
    label: 'Equip a Crystal Bow',
    description: 'Equip a Crystal Bow.',
    skillReqs: [
      { skill: 'Agility', level: 50 },
      { skill: 'Ranged', level: 70 },
    ],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.RANGED,
    prerequisite: '',
  },
  869: {
    id: '869',
    label: 'Equip a Crystal Halberd',
    description: 'Equip a Crystal Halberd.',
    skillReqs: [
      { skill: 'Agility', level: 50 },
      { skill: 'Strength', level: 35 },
      { skill: 'Attack', level: 70 },
    ],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  866: {
    id: '866',
    label: 'Equip a Crystal Shield',
    description: 'Equip a Crystal Shield.',
    skillReqs: [
      { skill: 'Agility', level: 50 },
      { skill: 'Defence', level: 70 },
    ],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.DEFENCE,
    prerequisite: '',
  },
  853: {
    id: '853',
    label: 'Equip a Dark Bow in Tirannwn',
    description: 'Equip a Dark Bow in Tirannwn.',
    skillReqs: [
      { skill: 'Ranged', level: 60 },
      { skill: 'Slayer', level: 90 },
    ],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  1870: {
    id: '1870',
    label: 'Equip any piece of Crystal Armour',
    description: 'Equip either the Body or Legs.',
    skillReqs: [{ skill: 'Defence', level: 70 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GAUNTLET,
    prerequisite: '',
  },
  878: {
    id: '878',
    label: 'Find Every Memoriam Crystal',
    description: 'Find every Memoriam Crystal in Prifddinas and add them to the Memoriam Device.',
    skillReqs: [{ skill: 'Agility', level: 75 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  1745: {
    id: '1745',
    label: 'Kill 50 Elves in Tirannwn',
    description: 'Kill 50 Elves in Tirannwn.',
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1743: {
    id: '1743',
    label: 'Make 50 Teak Planks in Prifddinas',
    description: 'Make 50 Teak Planks in Prifddinas.',
    skillReqs: [{ skill: 'Construction', level: 1 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  1736: {
    id: '1736',
    label: 'Mine 200 Soft Clay in Tirannwn',
    description: 'Mine 200 Soft Clay in Tirannwn.',
    skillReqs: [{ skill: 'Mining', level: 70 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  879: {
    id: '879',
    label: 'Open the Enhanced Crystal Chest',
    description: 'Open the Enhanced Crystal Chest in Prifddinas.',
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  835: {
    id: '835',
    label: 'Pickpocket an Elf 50 Times',
    description: 'Pickpocket an Elf 50 times.',
    skillReqs: [{ skill: 'Thieving', level: 85 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1871: {
    id: '1871',
    label: 'Receive a Dragonstone Amulet from an Impling',
    description: 'Receive a Dragonstone Amulet from a Crystal Impling.',
    skillReqs: [{ skill: 'Hunter', level: 80 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1746: {
    id: '1746',
    label: 'Use a prayer altar to restore 90 prayer in Prifddinas',
    description: 'Use a prayer altar to restore 90 prayer points in Prifddinas.',
    skillReqs: [{ skill: 'Prayer', level: 90 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.PRAYER,
    prerequisite: '',
  },
  1301: {
    id: '1301',
    label: 'Catch 100 Dark Crabs',
    description: 'Catch 100 Dark Crabs in the Wilderness.',
    skillReqs: [{ skill: 'Fishing', level: 85 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  1358: {
    id: '1358',
    label: 'Complete the Hard Wilderness Diary',
    description: 'Complete all of the hard tasks in the Diary Wilderness Achievement Diary.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.HARD,
    prerequisite: '',
  },
  1310: {
    id: '1310',
    label: 'Craft an Air Battlestaff in the Wilderness',
    description: 'Craft an Air Battlestaff in the Wilderness.',
    skillReqs: [{ skill: 'Crafting', level: 66 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '',
  },
  1763: {
    id: '1763',
    label: 'Defeat Callisto 150 times',
    description: 'Defeat Callisto or Artio 150 times.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '1762',
  },
  1762: {
    id: '1762',
    label: 'Defeat Callisto 50 times',
    description: 'Defeat Callisto or Artio 50 times.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '1321',
  },
  1321: {
    id: '1321',
    label: 'Defeat Callisto',
    description: 'Defeat Callisto in the Wilderness.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  1320: {
    id: '1320',
    label: 'Defeat Scorpia',
    description: 'Defeat Scorpia in the Wilderness.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  1328: {
    id: '1328',
    label: 'Defeat the Chaos Elemental',
    description: 'Defeat the Chaos Elemental in the Wilderness.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  1334: {
    id: '1334',
    label: 'Defeat the Corporeal Beast 50 Times',
    description: 'Defeat the Corporeal Beast in the Wilderness 50 times.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '1333',
  },
  1333: {
    id: '1333',
    label: 'Defeat the Corporeal Beast',
    description: 'Defeat the Corporeal Beast in the Wilderness.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  1329: {
    id: '1329',
    label: 'Defeat the King Black Dragon',
    description: 'Defeat the King Black Dragon in the Wilderness.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  1757: {
    id: '1757',
    label: 'Defeat Venenatis 150 times',
    description: 'Defeat Venenatis or Spindel 150 times.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '1756',
  },
  1756: {
    id: '1756',
    label: 'Defeat Venenatis 50 times',
    description: 'Defeat Venenatis or Spindel 50 times.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '1317',
  },
  1317: {
    id: '1317',
    label: 'Defeat Venenatis',
    description: 'Defeat Venenatis in the Wilderness.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  1760: {
    id: '1760',
    label: "Defeat Vet'ion 150 times",
    description: "Defeat Vet'ion or Calvar'ion 150 times.",
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '1759',
  },
  1759: {
    id: '1759',
    label: "Defeat Vet'ion 50 times",
    description: "Defeat Vet'ion or Calvar'ion 50 times.",
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '1325',
  },
  1325: {
    id: '1325',
    label: "Defeat Vet'ion",
    description: "Defeat Vet'ion in the Wilderness.",
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  1307: {
    id: '1307',
    label: 'Equip 100 Black Chinchompas',
    description: 'Equip a stack of at least 100 Black Chinchompas.',
    skillReqs: [
      { skill: 'Hunter', level: 73 },
      { skill: 'Ranged', level: 65 },
    ],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1332: {
    id: '1332',
    label: 'Equip a Dragon 2-Handed Sword in the Wilderness',
    description: 'Equip a Dragon 2-Handed Sword in the Wilderness.',
    skillReqs: [{ skill: 'Attack', level: 60 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  1331: {
    id: '1331',
    label: 'Equip a Dragon Pickaxe',
    description: 'Equip a Dragon Pickaxe.',
    skillReqs: [{ skill: 'Attack', level: 60 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  1342: {
    id: '1342',
    label: 'Equip an Enchanted Slayer Staff',
    description: 'Equip an Enchanted Slayer Staff.',
    skillReqs: [
      { skill: 'Magic', level: 75 },
      { skill: 'Slayer', level: 55 },
    ],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  1352: {
    id: '1352',
    label: 'Imbue a God Cape',
    description: 'Imbue either a Saradomin Cape, a Guthix Cape or a Zamorak Cape.',
    skillReqs: [{ skill: 'Magic', level: 75 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  1651: {
    id: '1651',
    label: 'Loot a dragonstone from the Rogues Castle',
    description: "Loot a dragonstone from the chest in Rogue's Castle.",
    skillReqs: [{ skill: 'Thieving', level: 84 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1702: {
    id: '1702',
    label: 'Open 10 Muddy Chests',
    description: 'Open 10 Muddy Chests.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1700: {
    id: '1700',
    label: "Open 50 Larran's Chests",
    description: "Open 50 of either Larran's Small Chest or Larran's Big Chest.",
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '1699',
  },
  1299: {
    id: '1299',
    label: 'Sacrifice Some Dragon Bones at the Chaos Temple',
    description: 'Sacrifice some Western Chaos Temple altar.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.PRAYER,
    prerequisite: '',
  },
  1460: {
    id: '1460',
    label: '100 Combat Achievements',
    description: 'Complete 100 Combat Achievements',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.GENERAL,
    prerequisite: '1459',
  },
  1452: {
    id: '1452',
    label: '1000 Combat Achievement Points',
    description: 'Obtain 1000 Combat Achievement Points.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.GENERAL,
    prerequisite: '1451',
  },
  1461: {
    id: '1461',
    label: '150 Combat Achievements',
    description: 'Complete 150 Combat Achievements',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.GENERAL,
    prerequisite: '1460',
  },
  466: {
    id: '466',
    label: '150 Master Clue Scrolls',
    description: 'Open 150 Reward caskets for completing master clue scrolls.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.MASTER,
    prerequisite: '465',
  },
  1811: {
    id: '1811',
    label: '200 Collection log slots',
    description: 'Obtain 200 unique Collection Log slots.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.COLLECTION_LOG,
    prerequisite: '1810',
  },
  1444: {
    id: '1444',
    label: '200 Combat Achievement Points',
    description: 'Obtain 200 Combat Achievement Points.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.GENERAL,
    prerequisite: '1443',
  },
  1462: {
    id: '1462',
    label: '200 Combat Achievements',
    description: 'Complete 200 Combat Achievements',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.GENERAL,
    prerequisite: '1461',
  },
  1463: {
    id: '1463',
    label: '250 Combat Achievements',
    description: 'Complete 250 Combat Achievements',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.GENERAL,
    prerequisite: '1462',
  },
  1445: {
    id: '1445',
    label: '300 Combat Achievement Points',
    description: 'Obtain 300 Combat Achievement Points.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.GENERAL,
    prerequisite: '1444',
  },
  1464: {
    id: '1464',
    label: '300 Combat Achievements',
    description: 'Complete 300 Combat Achievements',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.GENERAL,
    prerequisite: '1463',
  },
  1812: {
    id: '1812',
    label: '350 Collection log slots',
    description: 'Obtain 350 unique Collection Log slots.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.COLLECTION_LOG,
    prerequisite: '1811',
  },
  1446: {
    id: '1446',
    label: '400 Combat Achievement Points',
    description: 'Obtain 400 Combat Achievement Points.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.GENERAL,
    prerequisite: '1445',
  },
  1813: {
    id: '1813',
    label: '500 Collection log slots',
    description: 'Obtain 500 unique Collection Log slots.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.COLLECTION_LOG,
    prerequisite: '1812',
  },
  1447: {
    id: '1447',
    label: '500 Combat Achievement Points',
    description: 'Obtain 500 Combat Achievement Points.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.GENERAL,
    prerequisite: '1446',
  },
  1448: {
    id: '1448',
    label: '600 Combat Achievement Points',
    description: 'Obtain 600 Combat Achievement Points.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.GENERAL,
    prerequisite: '1447',
  },
  1449: {
    id: '1449',
    label: '700 Combat Achievement Points',
    description: 'Obtain 700 Combat Achievement Points.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.GENERAL,
    prerequisite: '1448',
  },
  465: {
    id: '465',
    label: '75 Master Clue Scrolls',
    description: 'Open 75 Reward caskets for completing master clue scrolls.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.MASTER,
    prerequisite: '464',
  },
  1450: {
    id: '1450',
    label: '800 Combat Achievement Points',
    description: 'Obtain 800 Combat Achievement Points.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.GENERAL,
    prerequisite: '1449',
  },
  1451: {
    id: '1451',
    label: '900 Combat Achievement Points',
    description: 'Obtain 900 Combat Achievement Points.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.GENERAL,
    prerequisite: '1450',
  },
  507: {
    id: '507',
    label: 'Achieve Your First Level 90',
    description: 'Reach level 90 in any skill.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.MILESTONE,
    prerequisite: '506',
  },
  508: {
    id: '508',
    label: 'Achieve Your First Level 95',
    description: 'Reach level 95 in any skill.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.MILESTONE,
    prerequisite: '507',
  },
  388: {
    id: '388',
    label: 'Activate an Imbued Heart',
    description: 'Obtain and activate an Imbued heart.',
    skillReqs: [{ skill: 'Slayer', level: 5 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  719: {
    id: '719',
    label: 'Add a Jar to a Display Case',
    description: 'Add a Jar to a Display Case in your Player Owned House Achievement Gallery.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GENERAL,
    prerequisite: '',
  },
  1711: {
    id: '1711',
    label: 'Blow 100 Light Orbs',
    description: 'Blow 100 Light Orbs.',
    skillReqs: [{ skill: 'Crafting', level: 87 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '',
  },
  1704: {
    id: '1704',
    label: 'Build a Spirit tree & fairy ring.',
    description: 'Build a Spirit tree & fairy ring in your Player Owned House.',
    skillReqs: [
      { skill: 'Construction', level: 95 },
      { skill: 'Farming', level: 83 },
    ],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  693: {
    id: '693',
    label: 'Catch a Lucky Impling Bare-Handed',
    description: 'Catch a Lucky Impling bare-handed.',
    skillReqs: [{ skill: 'Hunter', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1467: {
    id: '1467',
    label: 'Combat Achievements Easy Tier',
    description: 'Obtain enough points to unlock the easy tier of combat achievements',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.EASY,
    prerequisite: '',
  },
  1468: {
    id: '1468',
    label: 'Combat Achievements Medium Tier',
    description: 'Obtain enough points to unlock the medium tier of combat achievements',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.MEDIUM,
    prerequisite: '1467',
  },
  1773: {
    id: '1773',
    label: 'Complete 10 Speed Tasks',
    description: 'Complete 10 Combat Achievement Speed tasks.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.GENERAL,
    prerequisite: '1772',
  },
  1774: {
    id: '1774',
    label: 'Complete 20 Speed Tasks',
    description: 'Complete 20 Combat Achievement Speed tasks.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.GENERAL,
    prerequisite: '1773',
  },
  637: {
    id: '637',
    label: 'Complete 200 Slayer Tasks',
    description: 'Complete 200 Slayer Tasks.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '633',
  },
  1775: {
    id: '1775',
    label: 'Complete 30 Speed Tasks',
    description: 'Complete 30 Combat Achievement Speed tasks.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.GENERAL,
    prerequisite: '1774',
  },
  1089: {
    id: '1089',
    label: 'Complete the Elite Western Provinces Diary',
    description: 'Complete all of the elite tasks in the Diary Western Provinces Achievement Diary.',
    skillReqs: [],
    regions: ['Kandarin', 'Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.ELITE,
    prerequisite: '',
  },
  647: {
    id: '647',
    label: 'Craft an Onyx Amulet',
    description: 'Craft an Onyx Amulet.',
    skillReqs: [{ skill: 'Crafting', level: 90 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '',
  },
  638: {
    id: '638',
    label: 'Defeat 50 Superior Slayer Creatures',
    description: 'Defeat 50 Superior Slayer Creatures.',
    skillReqs: [{ skill: 'Slayer', level: 5 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '315',
  },
  728: {
    id: '728',
    label: 'Equip a Full Dragon Set',
    description:
      'Equip a Dragon Platebody, a Dragon Full Helm and either some Dragon Platelegs or a Dragon Plateskirt.',
    skillReqs: [
      { skill: 'Defence', level: 60 },
      { skill: 'Smithing', level: 90 },
    ],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  21: {
    id: '21',
    label: 'Equip a Heavy Casket',
    description: 'Equip a Heavy Casket.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.ELITE,
    prerequisite: '',
  },
  1817: {
    id: '1817',
    label: 'Equip a piece of Virtus',
    description: 'Equip either the Robe top, or Robe Bottoms.',
    skillReqs: [
      { skill: 'Magic', level: 78 },
      { skill: 'Defence', level: 75 },
    ],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '',
  },
  23: {
    id: '23',
    label: 'Equip a Scroll Sack',
    description: 'Equip a Scroll Sack.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.MASTER,
    prerequisite: '',
  },
  724: {
    id: '724',
    label: 'Equip an Abyssal Whip',
    description: 'Equip an Abyssal Whip.',
    skillReqs: [
      { skill: 'Attack', level: 75 },
      { skill: 'Slayer', level: 85 },
    ],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  387: {
    id: '387',
    label: 'Equip an Eternal Slayer Ring',
    description: 'Craft and equip an Eternal slayer ring.',
    skillReqs: [
      { skill: 'Slayer', level: 5 },
      { skill: 'Crafting', level: 75 },
    ],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  1090: {
    id: '1090',
    label: 'Equip an Infernal Tool',
    description: 'Equip either an Infernal Axe, an Infernal Pickaxe or an Infernal Harpoon.',
    skillReqs: [
      { skill: 'Attack', level: 60 },
      { skill: 'Woodcutting', level: 61 },
      { skill: 'Firemaking', level: 85 },
      { skill: 'Fishing', level: 75 },
      { skill: 'Cooking', level: 85 },
      { skill: 'Mining', level: 61 },
      { skill: 'Smithing', level: 85 },
    ],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.CERBERUS,
    prerequisite: '',
  },
  1635: {
    id: '1635',
    label: 'Equip the Mask of Rebirth',
    description: 'Equip the Mask of Rebirth.',
    skillReqs: [],
    regions: ['Desert', 'Kandarin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOA,
    prerequisite: '',
  },
  1616: {
    id: '1616',
    label: 'Equip the Soulreaper Axe',
    description: 'Obtain and Equip the Soulreaper Axe.',
    skillReqs: [
      { skill: 'Attack', level: 80 },
      { skill: 'Strength', level: 80 },
    ],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '',
  },
  8: {
    id: '8',
    label: 'Fill 25 Elite Clue Collection Log Slots',
    description: 'Fill 25 slots in the Elite Clue section of the Collection Log.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.ELITE,
    prerequisite: '31',
  },
  10: {
    id: '10',
    label: 'Fill 25 Master Clue Collection Log Slots',
    description: 'Fill 25 slots in the Master Clue section of the Collection Log.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.MASTER,
    prerequisite: '9',
  },
  40: {
    id: '40',
    label: 'Fill 30 Hard Clue Collection Log Slots',
    description: 'Fill 30 slots in the Hard Clue section of the Collection Log.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.HARD,
    prerequisite: '39',
  },
  48: {
    id: '48',
    label: 'Fill 40 Medium Clue Collection Log Slots',
    description: 'Fill 40 slots in the Medium Clue section of the Collection Log.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.MEDIUM,
    prerequisite: '58',
  },
  67: {
    id: '67',
    label: 'Fill 50 Easy Clue Collection Log Slots',
    description: 'Fill 50 slots in the Easy Clue section of the Collection Log.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.EASY,
    prerequisite: '66',
  },
  75: {
    id: '75',
    label: 'Fill Every Beginner Clue Collection Log Slot',
    description: 'Fill every slot in the Beginner Clue section of the Collection Log.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.BEGINNER,
    prerequisite: '85',
  },
  1722: {
    id: '1722',
    label: 'Fletch 100 Dragon Javelins',
    description: 'Fletch 100 Dragon Javelins.',
    skillReqs: [{ skill: 'Fletching', level: 92 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FLETCHING,
    prerequisite: '',
  },
  103: {
    id: '103',
    label: 'Gain 25 Unique Items From Elite Clues',
    description: 'Gain 25 unique items from Elite Clue Scroll Reward Caskets.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.ELITE,
    prerequisite: '102',
  },
  94: {
    id: '94',
    label: 'Gain 25 Unique Items From Master Clues',
    description: 'Gain 25 unique items from Master Clue Scroll Reward Caskets.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.MASTER,
    prerequisite: '93',
  },
  112: {
    id: '112',
    label: 'Gain 50 Unique Items From Hard Clues',
    description: 'Gain 50 unique items from Hard Clue Scroll Reward Caskets.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.HARD,
    prerequisite: '111',
  },
  707: {
    id: '707',
    label: 'Make a Super Combat Potion',
    description: 'Make a Super Combat Potion.',
    skillReqs: [{ skill: 'Herblore', level: 90 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  551: {
    id: '551',
    label: 'Obtain 25 Million Agility XP',
    description: 'Obtain 25 million experience in your Agility skill.',
    skillReqs: [{ skill: 'Agility', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '550',
  },
  533: {
    id: '533',
    label: 'Obtain 25 Million Attack XP',
    description: 'Obtain 25 million experience in your Attack skill.',
    skillReqs: [{ skill: 'Attack', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.ATTACK,
    prerequisite: '532',
  },
  547: {
    id: '547',
    label: 'Obtain 25 Million Construction XP',
    description: 'Obtain 25 million experience in your Construction skill.',
    skillReqs: [{ skill: 'Construction', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '546',
  },
  571: {
    id: '571',
    label: 'Obtain 25 Million Cooking XP',
    description: 'Obtain 25 million experience in your Cooking skill.',
    skillReqs: [{ skill: 'Cooking', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '570',
  },
  557: {
    id: '557',
    label: 'Obtain 25 Million Crafting XP',
    description: 'Obtain 25 million experience in your Crafting skill.',
    skillReqs: [{ skill: 'Crafting', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '556',
  },
  537: {
    id: '537',
    label: 'Obtain 25 Million Defence XP',
    description: 'Obtain 25 million experience in your Defence skill.',
    skillReqs: [{ skill: 'Defence', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.DEFENCE,
    prerequisite: '536',
  },
  577: {
    id: '577',
    label: 'Obtain 25 Million Farming XP',
    description: 'Obtain 25 million experience in your Farming skill.',
    skillReqs: [{ skill: 'Farming', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '576',
  },
  573: {
    id: '573',
    label: 'Obtain 25 Million Firemaking XP',
    description: 'Obtain 25 million experience in your Firemaking skill.',
    skillReqs: [{ skill: 'Firemaking', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FIREMAKING,
    prerequisite: '572',
  },
  569: {
    id: '569',
    label: 'Obtain 25 Million Fishing XP',
    description: 'Obtain 25 million experience in your Fishing skill.',
    skillReqs: [{ skill: 'Fishing', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '568',
  },
  559: {
    id: '559',
    label: 'Obtain 25 Million Fletching XP',
    description: 'Obtain 25 million experience in your Fletching skill.',
    skillReqs: [{ skill: 'Fletching', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FLETCHING,
    prerequisite: '558',
  },
  553: {
    id: '553',
    label: 'Obtain 25 Million Herblore XP',
    description: 'Obtain 25 million experience in your Herblore skill.',
    skillReqs: [{ skill: 'Herblore', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '552',
  },
  549: {
    id: '549',
    label: 'Obtain 25 Million Hitpoints XP',
    description: 'Obtain 25 million experience in your Hitpoints skill.',
    skillReqs: [{ skill: 'Hitpoints', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.HITPOINTS,
    prerequisite: '548',
  },
  563: {
    id: '563',
    label: 'Obtain 25 Million Hunter XP',
    description: 'Obtain 25 million experience in your Hunter skill.',
    skillReqs: [{ skill: 'Hunter', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '562',
  },
  543: {
    id: '543',
    label: 'Obtain 25 Million Magic XP',
    description: 'Obtain 25 million experience in your Magic skill.',
    skillReqs: [{ skill: 'Magic', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '542',
  },
  565: {
    id: '565',
    label: 'Obtain 25 Million Mining XP',
    description: 'Obtain 25 million experience in your Mining skill.',
    skillReqs: [{ skill: 'Mining', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '564',
  },
  541: {
    id: '541',
    label: 'Obtain 25 Million Prayer XP',
    description: 'Obtain 25 million experience in your Prayer skill.',
    skillReqs: [{ skill: 'Prayer', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.PRAYER,
    prerequisite: '540',
  },
  539: {
    id: '539',
    label: 'Obtain 25 Million Ranged XP',
    description: 'Obtain 25 million experience in your Ranged skill.',
    skillReqs: [{ skill: 'Ranged', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.RANGED,
    prerequisite: '538',
  },
  545: {
    id: '545',
    label: 'Obtain 25 Million Runecraft XP',
    description: 'Obtain 25 million experience in your Runecraft skill.',
    skillReqs: [{ skill: 'Runecraft', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '544',
  },
  561: {
    id: '561',
    label: 'Obtain 25 Million Slayer XP',
    description: 'Obtain 25 million experience in your Slayer skill.',
    skillReqs: [{ skill: 'Slayer', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '560',
  },
  567: {
    id: '567',
    label: 'Obtain 25 Million Smithing XP',
    description: 'Obtain 25 million experience in your Smithing skill.',
    skillReqs: [{ skill: 'Smithing', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '566',
  },
  535: {
    id: '535',
    label: 'Obtain 25 Million Strength XP',
    description: 'Obtain 25 million experience in your Strength skill.',
    skillReqs: [{ skill: 'Strength', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.STRENGTH,
    prerequisite: '534',
  },
  555: {
    id: '555',
    label: 'Obtain 25 Million Thieving XP',
    description: 'Obtain 25 million experience in your Thieving skill.',
    skillReqs: [{ skill: 'Thieving', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '554',
  },
  575: {
    id: '575',
    label: 'Obtain 25 Million Woodcutting XP',
    description: 'Obtain 25 million experience in your Woodcutting skill.',
    skillReqs: [{ skill: 'Woodcutting', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '574',
  },
  1827: {
    id: '1827',
    label: 'Obtain 35 Million Agility XP',
    description: 'Obtain 35 million experience in your Agility skill.',
    skillReqs: [{ skill: 'Agility', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '551',
  },
  1818: {
    id: '1818',
    label: 'Obtain 35 Million Attack XP',
    description: 'Obtain 35 million experience in your Attack skill.',
    skillReqs: [{ skill: 'Attack', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.ATTACK,
    prerequisite: '533',
  },
  1825: {
    id: '1825',
    label: 'Obtain 35 Million Construction XP',
    description: 'Obtain 35 million experience in your Construction skill.',
    skillReqs: [{ skill: 'Construction', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '547',
  },
  1837: {
    id: '1837',
    label: 'Obtain 35 Million Cooking XP',
    description: 'Obtain 35 million experience in your Cooking skill.',
    skillReqs: [{ skill: 'Cooking', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '571',
  },
  1830: {
    id: '1830',
    label: 'Obtain 35 Million Crafting XP',
    description: 'Obtain 35 million experience in your Crafting skill.',
    skillReqs: [{ skill: 'Crafting', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '557',
  },
  1820: {
    id: '1820',
    label: 'Obtain 35 Million Defence XP',
    description: 'Obtain 35 million experience in your Defence skill.',
    skillReqs: [{ skill: 'Defence', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.DEFENCE,
    prerequisite: '537',
  },
  1840: {
    id: '1840',
    label: 'Obtain 35 Million Farming XP',
    description: 'Obtain 35 million experience in your Farming skill.',
    skillReqs: [{ skill: 'Farming', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '577',
  },
  1838: {
    id: '1838',
    label: 'Obtain 35 Million Firemaking XP',
    description: 'Obtain 35 million experience in your Firemaking skill.',
    skillReqs: [{ skill: 'Firemaking', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FIREMAKING,
    prerequisite: '573',
  },
  1836: {
    id: '1836',
    label: 'Obtain 35 Million Fishing XP',
    description: 'Obtain 35 million experience in your Fishing skill.',
    skillReqs: [{ skill: 'Fishing', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '569',
  },
  1831: {
    id: '1831',
    label: 'Obtain 35 Million Fletching XP',
    description: 'Obtain 35 million experience in your Fletching skill.',
    skillReqs: [{ skill: 'Fletching', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FLETCHING,
    prerequisite: '559',
  },
  1828: {
    id: '1828',
    label: 'Obtain 35 Million Herblore XP',
    description: 'Obtain 35 million experience in your Herblore skill.',
    skillReqs: [{ skill: 'Herblore', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '553',
  },
  1826: {
    id: '1826',
    label: 'Obtain 35 Million Hitpoints XP',
    description: 'Obtain 35 million experience in your Hitpoints skill.',
    skillReqs: [{ skill: 'Hitpoints', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.HITPOINTS,
    prerequisite: '549',
  },
  1833: {
    id: '1833',
    label: 'Obtain 35 Million Hunter XP',
    description: 'Obtain 35 million experience in your Hunter skill.',
    skillReqs: [{ skill: 'Hunter', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '563',
  },
  1823: {
    id: '1823',
    label: 'Obtain 35 Million Magic XP',
    description: 'Obtain 35 million experience in your Magic skill.',
    skillReqs: [{ skill: 'Magic', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '543',
  },
  1834: {
    id: '1834',
    label: 'Obtain 35 Million Mining XP',
    description: 'Obtain 35 million experience in your Mining skill.',
    skillReqs: [{ skill: 'Mining', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '565',
  },
  1822: {
    id: '1822',
    label: 'Obtain 35 Million Prayer XP',
    description: 'Obtain 35 million experience in your Prayer skill.',
    skillReqs: [{ skill: 'Prayer', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.PRAYER,
    prerequisite: '541',
  },
  1821: {
    id: '1821',
    label: 'Obtain 35 Million Ranged XP',
    description: 'Obtain 35 million experience in your Ranged skill.',
    skillReqs: [{ skill: 'Ranged', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.RANGED,
    prerequisite: '539',
  },
  1824: {
    id: '1824',
    label: 'Obtain 35 Million Runecraft XP',
    description: 'Obtain 35 million experience in your Runecraft skill.',
    skillReqs: [{ skill: 'Runecraft', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '545',
  },
  1832: {
    id: '1832',
    label: 'Obtain 35 Million Slayer XP',
    description: 'Obtain 35 million experience in your Slayer skill.',
    skillReqs: [{ skill: 'Slayer', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '561',
  },
  1835: {
    id: '1835',
    label: 'Obtain 35 Million Smithing XP',
    description: 'Obtain 35 million experience in your Smithing skill.',
    skillReqs: [{ skill: 'Smithing', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '567',
  },
  1819: {
    id: '1819',
    label: 'Obtain 35 Million Strength XP',
    description: 'Obtain 35 million experience in your Strength skill.',
    skillReqs: [{ skill: 'Strength', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.STRENGTH,
    prerequisite: '535',
  },
  1829: {
    id: '1829',
    label: 'Obtain 35 Million Thieving XP',
    description: 'Obtain 35 million experience in your Thieving skill.',
    skillReqs: [{ skill: 'Thieving', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '555',
  },
  1839: {
    id: '1839',
    label: 'Obtain 35 Million Woodcutting XP',
    description: 'Obtain 35 million experience in your Woodcutting skill.',
    skillReqs: [{ skill: 'Woodcutting', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '575',
  },
  1850: {
    id: '1850',
    label: 'Obtain 50 Million Agility XP',
    description: 'Obtain 50 million experience in your Agility skill.',
    skillReqs: [{ skill: 'Agility', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '1827',
  },
  1841: {
    id: '1841',
    label: 'Obtain 50 Million Attack XP',
    description: 'Obtain 50 million experience in your Attack skill.',
    skillReqs: [{ skill: 'Attack', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.ATTACK,
    prerequisite: '1818',
  },
  1848: {
    id: '1848',
    label: 'Obtain 50 Million Construction XP',
    description: 'Obtain 50 million experience in your Construction skill.',
    skillReqs: [{ skill: 'Construction', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '1825',
  },
  1860: {
    id: '1860',
    label: 'Obtain 50 Million Cooking XP',
    description: 'Obtain 50 million experience in your Cooking skill.',
    skillReqs: [{ skill: 'Cooking', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '1837',
  },
  1853: {
    id: '1853',
    label: 'Obtain 50 Million Crafting XP',
    description: 'Obtain 50 million experience in your Crafting skill.',
    skillReqs: [{ skill: 'Crafting', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '1830',
  },
  1843: {
    id: '1843',
    label: 'Obtain 50 Million Defence XP',
    description: 'Obtain 50 million experience in your Defence skill.',
    skillReqs: [{ skill: 'Defence', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.DEFENCE,
    prerequisite: '1820',
  },
  1863: {
    id: '1863',
    label: 'Obtain 50 Million Farming XP',
    description: 'Obtain 50 million experience in your Farming skill.',
    skillReqs: [{ skill: 'Farming', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '1840',
  },
  1861: {
    id: '1861',
    label: 'Obtain 50 Million Firemaking XP',
    description: 'Obtain 50 million experience in your Firemaking skill.',
    skillReqs: [{ skill: 'Firemaking', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FIREMAKING,
    prerequisite: '1838',
  },
  1859: {
    id: '1859',
    label: 'Obtain 50 Million Fishing XP',
    description: 'Obtain 50 million experience in your Fishing skill.',
    skillReqs: [{ skill: 'Fishing', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '1836',
  },
  1854: {
    id: '1854',
    label: 'Obtain 50 Million Fletching XP',
    description: 'Obtain 50 million experience in your Fletching skill.',
    skillReqs: [{ skill: 'Fletching', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FLETCHING,
    prerequisite: '1831',
  },
  1851: {
    id: '1851',
    label: 'Obtain 50 Million Herblore XP',
    description: 'Obtain 50 million experience in your Herblore skill.',
    skillReqs: [{ skill: 'Herblore', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '1828',
  },
  1849: {
    id: '1849',
    label: 'Obtain 50 Million Hitpoints XP',
    description: 'Obtain 50 million experience in your Hitpoints skill.',
    skillReqs: [{ skill: 'Hitpoints', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.HITPOINTS,
    prerequisite: '1826',
  },
  1856: {
    id: '1856',
    label: 'Obtain 50 Million Hunter XP',
    description: 'Obtain 50 million experience in your Hunter skill.',
    skillReqs: [{ skill: 'Hunter', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '1833',
  },
  1846: {
    id: '1846',
    label: 'Obtain 50 Million Magic XP',
    description: 'Obtain 50 million experience in your Magic skill.',
    skillReqs: [{ skill: 'Magic', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '1823',
  },
  1857: {
    id: '1857',
    label: 'Obtain 50 Million Mining XP',
    description: 'Obtain 50 million experience in your Mining skill.',
    skillReqs: [{ skill: 'Mining', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '1834',
  },
  1845: {
    id: '1845',
    label: 'Obtain 50 Million Prayer XP',
    description: 'Obtain 50 million experience in your Prayer skill.',
    skillReqs: [{ skill: 'Prayer', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.PRAYER,
    prerequisite: '1822',
  },
  1844: {
    id: '1844',
    label: 'Obtain 50 Million Ranged XP',
    description: 'Obtain 50 million experience in your Ranged skill.',
    skillReqs: [{ skill: 'Ranged', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.RANGED,
    prerequisite: '1821',
  },
  1847: {
    id: '1847',
    label: 'Obtain 50 Million Runecraft XP',
    description: 'Obtain 50 million experience in your Runecraft skill.',
    skillReqs: [{ skill: 'Runecraft', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '1824',
  },
  1855: {
    id: '1855',
    label: 'Obtain 50 Million Slayer XP',
    description: 'Obtain 50 million experience in your Slayer skill.',
    skillReqs: [{ skill: 'Slayer', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '1832',
  },
  1858: {
    id: '1858',
    label: 'Obtain 50 Million Smithing XP',
    description: 'Obtain 50 million experience in your Smithing skill.',
    skillReqs: [{ skill: 'Smithing', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '1835',
  },
  1842: {
    id: '1842',
    label: 'Obtain 50 Million Strength XP',
    description: 'Obtain 50 million experience in your Strength skill.',
    skillReqs: [{ skill: 'Strength', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.STRENGTH,
    prerequisite: '1819',
  },
  1852: {
    id: '1852',
    label: 'Obtain 50 Million Thieving XP',
    description: 'Obtain 50 million experience in your Thieving skill.',
    skillReqs: [{ skill: 'Thieving', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '1829',
  },
  1862: {
    id: '1862',
    label: 'Obtain 50 Million Woodcutting XP',
    description: 'Obtain 50 million experience in your Woodcutting skill.',
    skillReqs: [{ skill: 'Woodcutting', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '1839',
  },
  708: {
    id: '708',
    label: 'Obtain a Boss Pet',
    description: 'Obtain any Boss Pet.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GENERAL,
    prerequisite: '',
  },
  578: {
    id: '578',
    label: 'Obtain a Skilling Pet',
    description: 'Obtain any Skilling Pet.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.GENERAL,
    prerequisite: '',
  },
  529: {
    id: '529',
    label: 'Reach Base Level 80',
    description: 'Reach level 80 in every skill.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.BASE,
    prerequisite: '528',
  },
  530: {
    id: '530',
    label: 'Reach Base Level 90',
    description: 'Reach level 90 in every skill.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.BASE,
    prerequisite: '529',
  },
  715: {
    id: '715',
    label: 'Reach Combat Level 120',
    description: 'Reach Combat Level 120.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.LEVEL,
    prerequisite: '714',
  },
  716: {
    id: '716',
    label: 'Reach Combat Level 126',
    description: 'Reach Combat Level 126.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.LEVEL,
    prerequisite: '715',
  },
  550: {
    id: '550',
    label: 'Reach Level 99 Agility',
    description: 'Reach level 99 in your Agility skill.',
    skillReqs: [{ skill: 'Agility', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  532: {
    id: '532',
    label: 'Reach Level 99 Attack',
    description: 'Reach level 99 in your Attack skill.',
    skillReqs: [{ skill: 'Attack', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.ATTACK,
    prerequisite: '',
  },
  546: {
    id: '546',
    label: 'Reach Level 99 Construction',
    description: 'Reach level 99 in your Construction skill.',
    skillReqs: [{ skill: 'Construction', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  570: {
    id: '570',
    label: 'Reach Level 99 Cooking',
    description: 'Reach level 99 in your Cooking skill.',
    skillReqs: [{ skill: 'Cooking', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  556: {
    id: '556',
    label: 'Reach Level 99 Crafting',
    description: 'Reach level 99 in your Crafting skill.',
    skillReqs: [{ skill: 'Crafting', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '',
  },
  536: {
    id: '536',
    label: 'Reach Level 99 Defence',
    description: 'Reach level 99 in your Defence skill.',
    skillReqs: [{ skill: 'Defence', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.DEFENCE,
    prerequisite: '',
  },
  576: {
    id: '576',
    label: 'Reach Level 99 Farming',
    description: 'Reach level 99 in your Farming skill.',
    skillReqs: [{ skill: 'Farming', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  572: {
    id: '572',
    label: 'Reach Level 99 Firemaking',
    description: 'Reach level 99 in your Firemaking skill.',
    skillReqs: [{ skill: 'Firemaking', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FIREMAKING,
    prerequisite: '',
  },
  568: {
    id: '568',
    label: 'Reach Level 99 Fishing',
    description: 'Reach level 99 in your Fishing skill.',
    skillReqs: [{ skill: 'Fishing', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  558: {
    id: '558',
    label: 'Reach Level 99 Fletching',
    description: 'Reach level 99 in your Fletching skill.',
    skillReqs: [{ skill: 'Fletching', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FLETCHING,
    prerequisite: '',
  },
  552: {
    id: '552',
    label: 'Reach Level 99 Herblore',
    description: 'Reach level 99 in your Herblore skill.',
    skillReqs: [{ skill: 'Herblore', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  548: {
    id: '548',
    label: 'Reach Level 99 Hitpoints',
    description: 'Reach level 99 in your Hitpoints skill.',
    skillReqs: [{ skill: 'Hitpoints', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.HITPOINTS,
    prerequisite: '',
  },
  562: {
    id: '562',
    label: 'Reach Level 99 Hunter',
    description: 'Reach level 99 in your Hunter skill.',
    skillReqs: [{ skill: 'Hunter', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  542: {
    id: '542',
    label: 'Reach Level 99 Magic',
    description: 'Reach level 99 in your Magic skill.',
    skillReqs: [{ skill: 'Magic', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  564: {
    id: '564',
    label: 'Reach Level 99 Mining',
    description: 'Reach level 99 in your Mining skill.',
    skillReqs: [{ skill: 'Mining', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  540: {
    id: '540',
    label: 'Reach Level 99 Prayer',
    description: 'Reach level 99 in your Prayer skill.',
    skillReqs: [{ skill: 'Prayer', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.PRAYER,
    prerequisite: '',
  },
  538: {
    id: '538',
    label: 'Reach Level 99 Ranged',
    description: 'Reach level 99 in your Ranged skill.',
    skillReqs: [{ skill: 'Ranged', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.RANGED,
    prerequisite: '',
  },
  544: {
    id: '544',
    label: 'Reach Level 99 Runecraft',
    description: 'Reach level 99 in your Runecraft skill.',
    skillReqs: [{ skill: 'Runecraft', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  560: {
    id: '560',
    label: 'Reach Level 99 Slayer',
    description: 'Reach level 99 in your Slayer skill.',
    skillReqs: [{ skill: 'Slayer', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  566: {
    id: '566',
    label: 'Reach Level 99 Smithing',
    description: 'Reach level 99 in your Smithing skill.',
    skillReqs: [{ skill: 'Smithing', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  534: {
    id: '534',
    label: 'Reach Level 99 Strength',
    description: 'Reach level 99 in your Strength skill.',
    skillReqs: [{ skill: 'Strength', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.STRENGTH,
    prerequisite: '',
  },
  554: {
    id: '554',
    label: 'Reach Level 99 Thieving',
    description: 'Reach level 99 in your Thieving skill.',
    skillReqs: [{ skill: 'Thieving', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  574: {
    id: '574',
    label: 'Reach Level 99 Woodcutting',
    description: 'Reach level 99 in your Woodcutting skill.',
    skillReqs: [{ skill: 'Woodcutting', level: 99 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  517: {
    id: '517',
    label: 'Reach Total Level 2000',
    description: 'Reach a Total Level of 2000.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.TOTAL,
    prerequisite: '516',
  },
  518: {
    id: '518',
    label: 'Reach Total Level 2100',
    description: 'Reach a Total Level of 2100.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.TOTAL,
    prerequisite: '517',
  },
  519: {
    id: '519',
    label: 'Reach Total Level 2200',
    description: 'Reach a Total Level of 2200.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.TOTAL,
    prerequisite: '518',
  },
  18: {
    id: '18',
    label: 'Read a Clueless Scroll',
    description: 'Read a Clueless Scroll.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.MEDIUM,
    prerequisite: '',
  },
  636: {
    id: '636',
    label: 'Slay an Abyssal Demon',
    description: 'Kill an Slayer Task.',
    skillReqs: [{ skill: 'Slayer', level: 85 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  663: {
    id: '663',
    label: 'Smith a Dragonfire Shield',
    description: 'Use an Anvil to smith a Dragonfire Shield.',
    skillReqs: [{ skill: 'Smithing', level: 90 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  25: {
    id: '25',
    label: 'Transform into Uri',
    description: 'Use the Uri Transform emote.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.HARD,
    prerequisite: '',
  },
  594: {
    id: '594',
    label: 'Travel Between Your Spirit Trees',
    description: "Travel between any two Spirit Trees that you've grown.",
    skillReqs: [{ skill: 'Farming', level: 91 }],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  14: {
    id: '14',
    label: 'Use the Explore Emote',
    description: 'Use the Explore emote.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.BEGINNER,
    prerequisite: '',
  },
  1141: {
    id: '1141',
    label: 'Complete the Elite Falador Diary',
    description: 'Complete all of the elite tasks in the Diary Falador Achievement Diary.',
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.ELITE,
    prerequisite: '',
  },
  1134: {
    id: '1134',
    label: 'Defeat Any God Wars Dungeon Boss 500 Times',
    description: 'Defeat any one of the God Wars Dungeon bosses 500 times',
    skillReqs: [
      { skill: 'Hitpoints', level: 70 },
      { skill: 'Agility', level: 70 },
      { skill: 'Ranged', level: 70 },
      { skill: 'Strength', level: 70 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '1133',
  },
  1531: {
    id: '1531',
    label: 'Defeat Awakened Whisperer',
    description: 'Defeat Awakened Whisperer.',
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '',
  },
  1751: {
    id: '1751',
    label: 'Defeat Cerberus 300 times',
    description: 'Defeat Cerberus 300 times.',
    skillReqs: [{ skill: 'Slayer', level: 91 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.CERBERUS,
    prerequisite: '1750',
  },
  1246: {
    id: '1246',
    label: 'Defeat Cerberus Before She Summons Souls',
    description: 'Defeat Cerberus before she summons souls.',
    skillReqs: [{ skill: 'Slayer', level: 91 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.CERBERUS,
    prerequisite: '',
  },
  1513: {
    id: '1513',
    label: 'Defeat Nex 300 Times',
    description: 'Defeat Nex in the God Wars Dungeon 300 times.',
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '1512',
  },
  1530: {
    id: '1530',
    label: 'Defeat Whisperer 300 times',
    description: 'Defeat Whisperer 300 times.',
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '1529',
  },
  1114: {
    id: '1114',
    label: 'Equip a Full Armadyl Armour Set',
    description: 'Equip a full set of Armadyl Armour.',
    skillReqs: [
      { skill: 'Ranged', level: 70 },
      { skill: 'Defence', level: 70 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '',
  },
  1119: {
    id: '1119',
    label: 'Equip a Full Bandos Armour Set',
    description: 'Equip a full set of Bandos Armour.',
    skillReqs: [
      { skill: 'Strength', level: 70 },
      { skill: 'Defence', level: 65 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '',
  },
  1092: {
    id: '1092',
    label: 'Equip a Full Graceful Outfit',
    description: 'Equip a full set of Graceful gear.',
    skillReqs: [{ skill: 'Agility', level: 1 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  1489: {
    id: '1489',
    label: 'Equip a Full set of Golden Prospector',
    description:
      'Equip a Golden prospector helmet, a Golden prospector jacket, some Golden prospector legs and Golden prospector boots.',
    skillReqs: [{ skill: 'Mining', level: 30 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  1609: {
    id: '1609',
    label: 'Equip a full set of the Carpenters Outfit',
    description: 'Equip a full set of the Carpenters Outfit',
    skillReqs: [{ skill: 'Construction', level: 1 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  1125: {
    id: '1125',
    label: 'Equip a Godsword',
    description: 'Equip any Godsword.',
    skillReqs: [
      { skill: 'Attack', level: 75 },
      { skill: 'Hitpoints', level: 70 },
      { skill: 'Agility', level: 70 },
      { skill: 'Ranged', level: 70 },
      { skill: 'Strength', level: 70 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '',
  },
  1113: {
    id: '1113',
    label: 'Equip a Piece of the Armadyl Armour Set',
    description: 'Equip any piece of the Armadyl Armour set.',
    skillReqs: [
      { skill: 'Ranged', level: 70 },
      { skill: 'Defence', level: 70 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '',
  },
  1118: {
    id: '1118',
    label: 'Equip a Piece of the Bandos Armour Set',
    description: 'Equip any piece of the Bandos Armour set.',
    skillReqs: [
      { skill: 'Strength', level: 70 },
      { skill: 'Defence', level: 65 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '',
  },
  1517: {
    id: '1517',
    label: 'Equip a Piece of Torva Armour',
    description: 'Equip either a Torva full helm, a Torva platebody or some Torva platelegs.',
    skillReqs: [
      { skill: 'Defence', level: 80 },
      { skill: 'Agility', level: 70 },
      { skill: 'Hitpoints', level: 70 },
      { skill: 'Ranged', level: 70 },
      { skill: 'Strength', level: 70 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '',
  },
  1107: {
    id: '1107',
    label: 'Equip a Staff of the Dead',
    description: 'Equip a Staff of the Dead.',
    skillReqs: [
      { skill: 'Attack', level: 75 },
      { skill: 'Magic', level: 75 },
      { skill: 'Hitpoints', level: 70 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '',
  },
  1112: {
    id: '1112',
    label: 'Equip a Zamorakian Spear',
    description: 'Equip a Zamorakian Spear.',
    skillReqs: [
      { skill: 'Attack', level: 70 },
      { skill: 'Hitpoints', level: 70 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '',
  },
  1520: {
    id: '1520',
    label: 'Equip a Zaryte Crossbow',
    description: 'Obtain and equip a Zaryte Crossbow.',
    skillReqs: [
      { skill: 'Ranged', level: 80 },
      { skill: 'Agility', level: 70 },
      { skill: 'Hitpoints', level: 70 },
      { skill: 'Strength', level: 70 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '',
  },
  1121: {
    id: '1121',
    label: 'Equip an Armadyl Crossbow',
    description: 'Equip an Armadyl Crossbow.',
    skillReqs: [
      { skill: 'Ranged', level: 70 },
      { skill: 'Agility', level: 70 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '',
  },
  1122: {
    id: '1122',
    label: 'Equip Some Primordial, Pegasian or Eternal Boots',
    description: 'Equip either some Primordial Boots, some Pegasian Boots or some Eternal Boots.',
    skillReqs: [
      { skill: 'Slayer', level: 91 },
      { skill: 'Defence', level: 75 },
      { skill: 'Magic', level: 60 },
      { skill: 'Runecraft', level: 60 },
      { skill: 'Attack', level: 75 },
      { skill: 'Ranged', level: 75 },
      { skill: 'Magic', level: 75 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.CERBERUS,
    prerequisite: '',
  },
  1579: {
    id: '1579',
    label: 'Equip the Bellator Ring',
    description: 'Obtain and Equip the Bellator Ring.',
    skillReqs: [
      { skill: 'Magic', level: 90 },
      { skill: 'Crafting', level: 80 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '',
  },
  1195: {
    id: '1195',
    label: 'Cast Ice Barrage',
    description: 'Cast the Ice Barrage spell.',
    skillReqs: [{ skill: 'Magic', level: 94 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  1204: {
    id: '1204',
    label: 'Complete the Elite Desert Diary',
    description: 'Complete all of the elite tasks in the Diary Desert Achievement Diary.',
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.ELITE,
    prerequisite: '',
  },
  1544: {
    id: '1544',
    label: 'Complete Tombs of Amascut 150 times',
    description: 'Complete Tombs of Amascut on normal or Expert 150 times.',
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOA,
    prerequisite: '1543',
  },
  1545: {
    id: '1545',
    label: 'Complete Tombs of Amascut 250 times',
    description: 'Complete Tombs of Amascut on normal or Expert 250 times.',
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOA,
    prerequisite: '1544',
  },
  1372: {
    id: '1372',
    label: 'Craft a Ghorrock Teleport Tablet',
    description: 'Craft a Ghorrock Teleport Tablet.',
    skillReqs: [{ skill: 'Magic', level: 96 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  1536: {
    id: '1536',
    label: 'Defeat Awakened Leviathan',
    description: 'Defeat Awakened Leviathan.',
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '',
  },
  1535: {
    id: '1535',
    label: 'Defeat Leviathan 300 times',
    description: 'Defeat Leviathan 300 times.',
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '1534',
  },
  1190: {
    id: '1190',
    label: 'Equip a Dragon Chainbody in the Kharidian Desert',
    description: 'Equip a Dragon Chainbody in the Kharidian Desert.',
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.KQ,
    prerequisite: '',
  },
  1598: {
    id: '1598',
    label: 'Equip a full set of Raiment of the eye',
    description:
      'Equip a full set of the Raiment of the Eye outfit, purchased from the Guardians of the Rift minigame.',
    skillReqs: [{ skill: 'Runecraft', level: 10 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  1586: {
    id: '1586',
    label: 'Equip a Piece of Masori Armour',
    description: 'Equip either a Masori Mask, a Masori Body or some Masori chaps.',
    skillReqs: [
      { skill: 'Ranged', level: 80 },
      { skill: 'Defence', level: 30 },
    ],
    regions: ['Desert'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOA,
    prerequisite: '',
  },
  1207: {
    id: '1207',
    label: 'Equip a Piece of the Infinity Robe Set',
    description: 'Equip any piece of the Infinity robe set.',
    skillReqs: [
      { skill: 'Magic', level: 50 },
      { skill: 'Defence', level: 25 },
    ],
    regions: ['Desert'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.MTA,
    prerequisite: '',
  },
  1590: {
    id: '1590',
    label: 'Equip Masori Assembler',
    description: 'Obtain and Equip Masori Assembler.',
    skillReqs: [{ skill: 'Ranged', level: 70 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOA,
    prerequisite: '',
  },
  1588: {
    id: '1588',
    label: "Equip Osmumten's Fang",
    description: "Obtain and Equip Osmumten's Fang.",
    skillReqs: [{ skill: 'Attack', level: 82 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOA,
    prerequisite: '',
  },
  1585: {
    id: '1585',
    label: 'Equip the Elidinis Ward (Or)',
    description: 'Obtain and Equip the Elidinis Ward (Or).',
    skillReqs: [
      { skill: 'Magic', level: 80 },
      { skill: 'Defence', level: 80 },
      { skill: 'Prayer', level: 80 },
    ],
    regions: ['Desert'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOA,
    prerequisite: '',
  },
  1584: {
    id: '1584',
    label: 'Equip the Elidinis Ward',
    description: 'Obtain and Equip the Elidinis Ward.',
    skillReqs: [
      { skill: 'Magic', level: 80 },
      { skill: 'Defence', level: 80 },
      { skill: 'Prayer', level: 80 },
    ],
    regions: ['Desert'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOA,
    prerequisite: '',
  },
  1723: {
    id: '1723',
    label: 'Fletch 200 Dragon Darts',
    description: 'Fletch 200 Dragon Darts.',
    skillReqs: [{ skill: 'Fletching', level: 95 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FLETCHING,
    prerequisite: '',
  },
  1577: {
    id: '1577',
    label: 'Hit 150 with the Keris Partisan',
    description: 'Hit 150 damage or more with the Keris Partisan.',
    skillReqs: [{ skill: 'Attack', level: 65 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1164: {
    id: '1164',
    label: 'Room 8 of Pyramid Plunder 150 Times',
    description: 'Search the Golden Chest in Room 8 of Pyramid Plunder in Sophanem 150 times.',
    skillReqs: [{ skill: 'Thieving', level: 91 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '1163',
  },
  1163: {
    id: '1163',
    label: 'Room 8 of Pyramid Plunder 75 Times',
    description: 'Search the Golden Chest in Room 8 of Pyramid Plunder in Sophanem 75 times.',
    skillReqs: [{ skill: 'Thieving', level: 91 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '1162',
  },
  1381: {
    id: '1381',
    label: 'Smith 1,000 Rune Dart Tips',
    description: 'Use an Anvil to smith 1,000 Rune Dart Tips.',
    skillReqs: [{ skill: 'Smithing', level: 89 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  1172: {
    id: '1172',
    label: "Turn in 50 Summer Sq'irkjuices to Osman",
    description: "Turn in 50 Summer Sq'irkjuices to Osman in Al Kharid.",
    skillReqs: [{ skill: 'Thieving', level: 65 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  888: {
    id: '888',
    label: 'Build a Gilded Portal Nexus',
    description: 'Build a Gilded Portal Nexus in a Portal Nexus in your Player Owned House.',
    skillReqs: [{ skill: 'Construction', level: 82 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  1638: {
    id: '1638',
    label: 'Build a Gold Sink',
    description: 'Build a Gold Sink.',
    skillReqs: [{ skill: 'Construction', level: 47 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  885: {
    id: '885',
    label: 'Cast Spellbook Swap',
    description: 'Cast the Spellbook Swap spell.',
    skillReqs: [{ skill: 'Magic', level: 96 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  946: {
    id: '946',
    label: 'Complete the Elite Fremennik Diary',
    description: 'Complete all of the elite tasks in the Diary Fremennik Achievement Diary.',
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.ELITE,
    prerequisite: '',
  },
  899: {
    id: '899',
    label: 'Create a Catherby Teleport Tablet',
    description: 'Create a Catherby Teleport Tablet.',
    skillReqs: [{ skill: 'Magic', level: 87 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  1601: {
    id: '1601',
    label: 'Create the Saturated Heart',
    description: 'Create the Saturated Heart.',
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.MUSPAH,
    prerequisite: '',
  },
  1787: {
    id: '1787',
    label: 'Dagannoth Kings Combat Achievements',
    description: 'Complete all of the Combat Achievements for all three Dagannoth Kings.',
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DKS,
    prerequisite: '',
  },
  1541: {
    id: '1541',
    label: 'Defeat Awakened Duke Sucellus',
    description: 'Defeat Awakened Duke Sucellus.',
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '',
  },
  1540: {
    id: '1540',
    label: 'Defeat Duke Sucellus 300 times',
    description: 'Defeat Duke Sucellus 300 times.',
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '1539',
  },
  935: {
    id: '935',
    label: 'Defeat Each Dagannoth King 300 Times',
    description: 'Defeat all three of the Dagannoth Kings in the Waterbirth Island Dungeon 300 times.',
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DKS,
    prerequisite: '934',
  },
  1552: {
    id: '1552',
    label: 'Defeat Phantom Muspah 300 times',
    description: 'Defeat Phantom Muspah 300 times.',
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.MUSPAH,
    prerequisite: '1551',
  },
  925: {
    id: '925',
    label: 'Defeat Vorkath 15 Times Without Leaving',
    description: 'Defeat Vorkath on Ungael 15 times without leaving his area.',
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.VORKATH,
    prerequisite: '',
  },
  930: {
    id: '930',
    label: 'Defeat Vorkath 300 Times',
    description: 'Defeat Vorkath on Ungael 300 times.',
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.VORKATH,
    prerequisite: '929',
  },
  922: {
    id: '922',
    label: 'Defeat Vorkath 5 Times Without Special Damage',
    description:
      'Defeat Vorkath on Ungael 5 times in a row without taking any damage from his special attacks and without leaving his area. Failing this task requires you to leave the instance to try again.',
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.VORKATH,
    prerequisite: '',
  },
  1400: {
    id: '1400',
    label: 'Equip a Bonecrusher necklace',
    description: 'Equip a Bonecrusher necklace.',
    skillReqs: [
      { skill: 'Prayer', level: 70 },
      { skill: 'Slayer', level: 95 },
    ],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.VORKATH,
    prerequisite: '',
  },
  950: {
    id: '950',
    label: 'Equip a Completed God Book',
    description: 'Equip a completed God Book.',
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.SHARED,
    prerequisite: '',
  },
  778: {
    id: '778',
    label: 'Equip a Dragon Crossbow',
    description: 'Equip a Dragon Crossbow.',
    skillReqs: [
      { skill: 'Ranged', level: 64 },
      { skill: 'Fletching', level: 78 },
    ],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  908: {
    id: '908',
    label: 'Equip a Dragonbone Necklace',
    description: 'Equip a Dragonbone Necklace.',
    skillReqs: [{ skill: 'Prayer', level: 70 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.VORKATH,
    prerequisite: '',
  },
  903: {
    id: '903',
    label: 'Equip a Dragonfire Ward',
    description: 'Equip a Dragonfire Ward.',
    skillReqs: [
      { skill: 'Defence', level: 70 },
      { skill: 'Ranged', level: 70 },
    ],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.VORKATH,
    prerequisite: '',
  },
  920: {
    id: '920',
    label: 'Equip a Neitiznot Faceguard',
    description: 'Equip a Neitiznot Faceguard.',
    skillReqs: [{ skill: 'Defence', level: 70 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  917: {
    id: '917',
    label: 'Equip Every Dagannoth King Ring',
    description: "Equip an Archer's Ring, a Berserker Ring, a Seer's Ring and a Warrior Ring.",
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DKS,
    prerequisite: '',
  },
  1581: {
    id: '1581',
    label: 'Equip the Magus Ring',
    description: 'Obtain and Equip the Magus Ring.',
    skillReqs: [
      { skill: 'Magic', level: 90 },
      { skill: 'Crafting', level: 80 },
    ],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '',
  },
  1600: {
    id: '1600',
    label: 'Equip the Venator Bow',
    description: 'Obtain and Equip the Venator Bow.',
    skillReqs: [{ skill: 'Ranged', level: 80 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.MUSPAH,
    prerequisite: '',
  },
  1697: {
    id: '1697',
    label: 'Make 50 Forgotten Brews',
    description: 'Make 50 Forgotten Brews.',
    skillReqs: [{ skill: 'Herblore', level: 91 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  1789: {
    id: '1789',
    label: 'Phantom Muspah Combat Achievements',
    description: 'Complete all of the Combat Achievements for Phantom Muspah.',
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.MUSPAH,
    prerequisite: '',
  },
  1788: {
    id: '1788',
    label: 'Vorkath Combat Achievements',
    description: 'Complete all of the Combat Achievements for Vorkath.',
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.VORKATH,
    prerequisite: '',
  },
  1015: {
    id: '1015',
    label: 'Catch 150 Red Chinchompas in Kandarin',
    description: 'Catch 150 Red Chinchompas in Kandarin',
    skillReqs: [{ skill: 'Hunter', level: 63 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1026: {
    id: '1026',
    label: 'Check a grown Dragonfruit Tree in Catherby',
    description: "Check the health of a Dragonfruit Tree you've grown in Catherby.",
    skillReqs: [{ skill: 'Farming', level: 81 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  1012: {
    id: '1012',
    label: 'Complete the Ardougne Agility Course',
    description: 'Complete a lap of the Ardougne Rooftop Agility Course.',
    skillReqs: [{ skill: 'Agility', level: 90 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  1386: {
    id: '1386',
    label: 'Complete the Elite Ardougne Diary',
    description: 'Complete all of the elite tasks in the Diary Ardougne Achievement Diary.',
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.ELITE,
    prerequisite: '',
  },
  1085: {
    id: '1085',
    label: 'Complete the Elite Kandarin Diary',
    description: 'Complete all of the elite tasks in the Diary Kandarin Achievement Diary.',
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.ELITE,
    prerequisite: '',
  },
  1620: {
    id: '1620',
    label: 'Craft a Wrath Rune',
    description: 'Craft a Wrath Rune at the Wrath Altar.',
    skillReqs: [{ skill: 'Runecraft', level: 95 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  1055: {
    id: '1055',
    label: 'Defeat 300 Demonic Gorillas',
    description: 'Defeat 300 Demonic Gorillas in the Crash Site Cavern.',
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1056: {
    id: '1056',
    label: 'Defeat 500 Demonic Gorillas',
    description: 'Defeat 500 Demonic Gorillas in the Crash Site Cavern.',
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1053: {
    id: '1053',
    label: 'Defeat the Kraken Boss 300 Times',
    description: 'Defeat the Kraken boss in Kraken Cove 300 times.',
    skillReqs: [{ skill: 'Slayer', level: 87 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.KRAKEN,
    prerequisite: '1052',
  },
  1075: {
    id: '1075',
    label: 'Equip a Dragon Archer Chompy Hat',
    description: 'Equip a Dragon Archer Chompy Hat.',
    skillReqs: [
      { skill: 'Fletching', level: 5 },
      { skill: 'Cooking', level: 30 },
      { skill: 'Ranged', level: 30 },
      { skill: 'Crafting', level: 5 },
    ],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.CHOMPIES,
    prerequisite: '1074',
  },
  1048: {
    id: '1048',
    label: 'Equip a Dragon Full Helm',
    description: 'Equip a Dragon Full Helm.',
    skillReqs: [{ skill: 'Defence', level: 60 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1805: {
    id: '1805',
    label: 'Equip a Gnome Scarf',
    description: 'Obtain and Equip a Gnome Scarf.',
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.OTHER,
    prerequisite: '',
  },
  1035: {
    id: '1035',
    label: 'Equip a Heavy Ballista',
    description: 'Equip a Heavy Ballista.',
    skillReqs: [
      { skill: 'Ranged', level: 75 },
      { skill: 'Fletching', level: 72 },
    ],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1796: {
    id: '1796',
    label: 'Equip a Light Ballista',
    description: 'Equip a Light Ballista.',
    skillReqs: [{ skill: 'Ranged', level: 65 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1079: {
    id: '1079',
    label: 'Equip a Maniacal Monkey Backpack',
    description: 'Equip a Maniacal Monkey Backpack.',
    skillReqs: [{ skill: 'Agility', level: 48 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  1039: {
    id: '1039',
    label: 'Equip an Abyssal Tentacle',
    description: 'Equip an Abyssal Tentacle.',
    skillReqs: [
      { skill: 'Magic', level: 50 },
      { skill: 'Attack', level: 75 },
      { skill: 'Slayer', level: 87 },
    ],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.KRAKEN,
    prerequisite: '',
  },
  1076: {
    id: '1076',
    label: 'Equip an Expert Dragon Archer Chompy Hat',
    description: 'Equip an Expert Dragon Archer Chompy Hat.',
    skillReqs: [
      { skill: 'Fletching', level: 5 },
      { skill: 'Cooking', level: 30 },
      { skill: 'Ranged', level: 30 },
      { skill: 'Crafting', level: 5 },
    ],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.CHOMPIES,
    prerequisite: '1075',
  },
  1046: {
    id: '1046',
    label: 'Equip an Occult Necklace',
    description: 'Equip an Occult Necklace.',
    skillReqs: [
      { skill: 'Magic', level: 70 },
      { skill: 'Slayer', level: 93 },
    ],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.THERMY,
    prerequisite: '',
  },
  1033: {
    id: '1033',
    label: 'Equip Some Zenyte Jewelry',
    description: 'Equip any piece of Zenyte Jewelry.',
    skillReqs: [{ skill: 'Crafting', level: 89 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1803: {
    id: '1803',
    label: 'Purchase a Halo from Castlewars',
    description: 'Purchase any halo from Castlewars.',
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.CWARS,
    prerequisite: '',
  },
  997: {
    id: '997',
    label: 'Complete the Elite Karamja Diary',
    description: 'Complete all of the elite tasks in the Diary Karamja Achievement Diary.',
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.ELITE,
    prerequisite: '',
  },
  988: {
    id: '988',
    label: 'Complete the Fight Caves 10 Times',
    description: 'Complete the TzHaar Fight Cave in Mor Ul Rek 10 times.',
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.JAD,
    prerequisite: '987',
  },
  989: {
    id: '989',
    label: 'Complete the Fight Caves 15 Times',
    description: 'Complete the TzHaar Fight Cave in Mor Ul Rek 15 times.',
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.JAD,
    prerequisite: '988',
  },
  987: {
    id: '987',
    label: 'Complete the Fight Caves 5 Times',
    description: 'Complete the TzHaar Fight Cave in Mor Ul Rek 5 times.',
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.JAD,
    prerequisite: '',
  },
  991: {
    id: '991',
    label: 'Complete the Inferno 10 Times',
    description: 'Complete the Inferno in Mor Ul Rek 10 times.',
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.INFERNO,
    prerequisite: '990',
  },
  990: {
    id: '990',
    label: 'Complete the Inferno 5 Times',
    description: 'Complete the Inferno in Mor Ul Rek 5 times.',
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.INFERNO,
    prerequisite: '',
  },
  1557: {
    id: '1557',
    label: 'Complete Tzhaar-Ket-Raks fifth challenge',
    description: 'Complete Tzhaar-Ket-Raks fifth challenge.',
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GENERAL,
    prerequisite: '',
  },
  1556: {
    id: '1556',
    label: 'Complete Tzhaar-Ket-Raks fourth challenge',
    description: 'Complete Tzhaar-Ket-Raks fourth challenge.',
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GENERAL,
    prerequisite: '',
  },
  1558: {
    id: '1558',
    label: 'Complete Tzhaar-Ket-Raks sixth challenge',
    description: 'Complete Tzhaar-Ket-Raks sixth challenge.',
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GENERAL,
    prerequisite: '',
  },
  1555: {
    id: '1555',
    label: 'Complete Tzhaar-Ket-Raks third challenge',
    description: 'Complete Tzhaar-Ket-Raks third challenge.',
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GENERAL,
    prerequisite: '',
  },
  973: {
    id: '973',
    label: 'Equip a Fire Cape',
    description: 'Equip a Fire Cape.',
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.JAD,
    prerequisite: '',
  },
  963: {
    id: '963',
    label: 'Pickpocket a Diamond From a TzHaar',
    description: 'Pickpocket a Diamond from a TzHaar.',
    skillReqs: [{ skill: 'Thieving', level: 90 }],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1001: {
    id: '1001',
    label: 'Purchase an Onyx in Mor Ul Rek',
    description: 'Purchase an Onyx from an Ore and Gem Store in Mor Ul Rek.',
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  983: {
    id: '983',
    label: 'Sacrifice a Fire Cape to Access the Inferno',
    description: 'Sacrifice a Fire Cape to access the Inferno in Mor Ul Rek.',
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.INFERNO,
    prerequisite: '',
  },
  962: {
    id: '962',
    label: 'Trade 100 Agility Arena Tickets for XP',
    description: 'Trade in 100 or more Agility Arena Tickets for XP in one go.',
    skillReqs: [{ skill: 'Agility', level: 1 }],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  368: {
    id: '368',
    label: '1 Chambers of Xeric Challenge Mode',
    description: 'Complete the Chambers of Xeric Challenge Mode.',
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.COX,
    prerequisite: '',
  },
  324: {
    id: '324',
    label: '1500 Lizardmen Shaman Kills',
    description: 'Help the Shayzien House by killing 1500 Lizardmen shamans.',
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '323',
  },
  362: {
    id: '362',
    label: '250 Chambers of Xeric',
    description: 'Complete the Chambers of Xeric Normal or Challenge Mode 250 times.',
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.COX,
    prerequisite: '361',
  },
  351: {
    id: '351',
    label: '300 Alchemical Hydra Kills',
    description: 'Kill the Alchemical Hydra in Mount Karuulm 300 times.',
    skillReqs: [{ skill: 'Slayer', level: 95 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.HYDRA,
    prerequisite: '350',
  },
  369: {
    id: '369',
    label: '50 Chambers of Xeric Challenge Mode',
    description: 'Complete the Chambers of Xeric Challenge Mode 50 times.',
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.COX,
    prerequisite: '368',
  },
  1794: {
    id: '1794',
    label: 'Alchemical Hydra Combat Achievements',
    description: 'Complete all of the Combat Achievements for Alchemical Hydra.',
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.HYDRA,
    prerequisite: '',
  },
  1714: {
    id: '1714',
    label: 'Burn 200 Redwood Logs',
    description: 'Burn 200 Redwood Logs.',
    skillReqs: [
      { skill: 'Firemaking', level: 90 },
      { skill: 'Woodcutting', level: 90 },
    ],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FIREMAKING,
    prerequisite: '',
  },
  288: {
    id: '288',
    label: 'Check a grown Redwood Tree',
    description: 'Check the health of a Redwood Tree after growing one in the Farming Guild.',
    skillReqs: [{ skill: 'Farming', level: 90 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  1734: {
    id: '1734',
    label: 'Chop 500 Redwood logs',
    description: 'Chop 500 Redwood logs from a Redwood Tree.',
    skillReqs: [{ skill: 'Woodcutting', level: 90 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  1770: {
    id: '1770',
    label: 'Complete A Kingdom Divided',
    description: 'Complete Quest A Kingdom Divided quest.',
    skillReqs: [
      { skill: 'Agility', level: 54 },
      { skill: 'Thieving', level: 52 },
      { skill: 'Woodcutting', level: 52 },
      { skill: 'Herblore', level: 50 },
      { skill: 'Mining', level: 42 },
      { skill: 'Crafting', level: 38 },
      { skill: 'Magic', level: 35 },
    ],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.EXPERIENCED,
    prerequisite: '',
  },
  1707: {
    id: '1707',
    label: 'Cook 100 Anglerfish.',
    description: 'Cook 100 Anglerfish.',
    skillReqs: [{ skill: 'Cooking', level: 84 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  1526: {
    id: '1526',
    label: 'Defeat Awakened Vardorvis',
    description: 'Defeat Awakened Vardorvis.',
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '',
  },
  1525: {
    id: '1525',
    label: 'Defeat Vardorvis 300 times',
    description: 'Defeat Vardorvis 300 times.',
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '1524',
  },
  1390: {
    id: '1390',
    label: 'Equip a Dragon Hunter Crossbow',
    description: 'Equip a Dragon Hunter Crossbow.',
    skillReqs: [{ skill: 'Ranged', level: 70 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.COX,
    prerequisite: '',
  },
  390: {
    id: '390',
    label: 'Equip a Dragon Hunter Lance',
    description: 'Obtain and equip a Dragon hunter lance.',
    skillReqs: [
      { skill: 'Slayer', level: 95 },
      { skill: 'Attack', level: 78 },
      { skill: 'Fishing', level: 55 },
    ],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.HYDRA,
    prerequisite: '',
  },
  382: {
    id: '382',
    label: 'Equip a Dragon Warhammer',
    description: 'Obtain and equip a Dragon warhammer.',
    skillReqs: [{ skill: 'Strength', level: 60 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  493: {
    id: '493',
    label: 'Equip a Golden Tench',
    description: 'Equip a Golden tench, caught by aerial fishing on Molch Island.',
    skillReqs: [
      { skill: 'Hunter', level: 35 },
      { skill: 'Fishing', level: 43 },
    ],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  394: {
    id: '394',
    label: 'Equip a Kodai Wand',
    description: 'Obtain and equip a Kodai wand.',
    skillReqs: [{ skill: 'Magic', level: 80 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.COX,
    prerequisite: '',
  },
  1396: {
    id: '1396',
    label: 'Equip a pair of Dragon Claws',
    description: 'Equip a pair of Dragon Claws.',
    skillReqs: [{ skill: 'Attack', level: 60 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.COX,
    prerequisite: '',
  },
  1391: {
    id: '1391',
    label: 'Equip a Twisted Buckler',
    description: 'Equip a Twisted Buckler.',
    skillReqs: [
      { skill: 'Ranged', level: 75 },
      { skill: 'Defence', level: 75 },
    ],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.COX,
    prerequisite: '',
  },
  393: {
    id: '393',
    label: 'Equip an Elder Maul',
    description: 'Obtain and equip an Elder maul.',
    skillReqs: [
      { skill: 'Attack', level: 75 },
      { skill: 'Strength', level: 75 },
    ],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.COX,
    prerequisite: '',
  },
  1394: {
    id: '1394',
    label: 'Equip any Ancestral piece',
    description: 'Equip either an Ancestral Hat, an Ancestral robe top or an Ancestral robe bottom.',
    skillReqs: [
      { skill: 'Magic', level: 75 },
      { skill: 'Defence', level: 65 },
    ],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.COX,
    prerequisite: '',
  },
  1393: {
    id: '1393',
    label: "Equip Dinh's Bulwark",
    description: "Equip Dinh's Bulwark.",
    skillReqs: [
      { skill: 'Attack', level: 75 },
      { skill: 'Defence', level: 75 },
    ],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.COX,
    prerequisite: '',
  },
  1580: {
    id: '1580',
    label: 'Equip the Ultor Ring',
    description: 'Obtain and Equip the Ultor Ring.',
    skillReqs: [
      { skill: 'Magic', level: 90 },
      { skill: 'Crafting', level: 80 },
    ],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '',
  },
  1721: {
    id: '1721',
    label: 'Fletch 250 Redwood logs into arrow shafts',
    description: 'Fletch 250 Redwood logs into arrow shafts.',
    skillReqs: [
      { skill: 'Fletching', level: 90 },
      { skill: 'Woodcutting', level: 90 },
    ],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FLETCHING,
    prerequisite: '',
  },
  413: {
    id: '413',
    label: 'Kourend and Kebos Elite Diary Tasks',
    description: 'Complete all tasks in the elite tier of the Diary Kourend and Kebos achievement diary.',
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.ELITE,
    prerequisite: '',
  },
  1397: {
    id: '1397',
    label: 'Recolour a piece of the Ancestral Robes',
    description: 'Recolour a piece of the Ancestral robes using a Twisted ancestral colour kit.',
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.COX,
    prerequisite: '',
  },
  1793: {
    id: '1793',
    label: 'Abyssal Sire Combat Achievements',
    description: 'Complete all of the Combat Achievements for Abyssal Sire.',
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SIRE,
    prerequisite: '',
  },
  757: {
    id: '757',
    label: 'Buy an Ash Covered Tome',
    description: "Buy an Ash Covered Tome from Petrified Pete's Ore Shop on Fossil Island.",
    skillReqs: [{ skill: 'Mining', level: 50 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  752: {
    id: '752',
    label: 'Catch a Herbiboar 150 Times',
    description: 'Catch a Herbiboar on Fossil Island 150 times.',
    skillReqs: [
      { skill: 'Hunter', level: 80 },
      { skill: 'Herblore', level: 31 },
    ],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '751',
  },
  800: {
    id: '800',
    label: 'Complete the Elite Lumbridge & Draynor Diary',
    description: 'Complete all of the elite tasks in the Diary Lumbridge & Draynor Achievement Diary.',
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.ELITE,
    prerequisite: '',
  },
  1380: {
    id: '1380',
    label: 'Complete the Elite Varrock Diary',
    description: 'Complete all of the elite tasks in the Diary Varrock Achievement Diary.',
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.ELITE,
    prerequisite: '',
  },
  795: {
    id: '795',
    label: 'Defeat the Abyssal Sire 150 Times',
    description: 'Defeat the Abyssal Sire in the Abyssal Nexus 150 times.',
    skillReqs: [{ skill: 'Slayer', level: 85 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SIRE,
    prerequisite: '794',
  },
  796: {
    id: '796',
    label: 'Defeat the Abyssal Sire 300 Times',
    description: 'Defeat the Abyssal Sire in the Abyssal Nexus 300 times.',
    skillReqs: [{ skill: 'Slayer', level: 85 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SIRE,
    prerequisite: '796',
  },
  794: {
    id: '794',
    label: 'Defeat the Abyssal Sire 50 Times',
    description: 'Defeat the Abyssal Sire in the Abyssal Nexus 50 times.',
    skillReqs: [{ skill: 'Slayer', level: 85 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SIRE,
    prerequisite: '789',
  },
  783: {
    id: '783',
    label: 'Equip a Dragonfire Shield',
    description: 'Equip a Dragonfire Shield.',
    skillReqs: [{ skill: 'Defence', level: 75 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1611: {
    id: '1611',
    label: 'Equip a full set of the Forestry Outfit',
    description: 'Equip a full set of the Forestry outfit, purchased from the Forestry minigame.',
    skillReqs: [{ skill: 'Woodcutting', level: 1 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  790: {
    id: '790',
    label: 'Equip an Abyssal Bludgeon',
    description: 'Equip an Abyssal Bludgeon.',
    skillReqs: [
      { skill: 'Attack', level: 70 },
      { skill: 'Strength', level: 70 },
      { skill: 'Slayer', level: 85 },
    ],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SIRE,
    prerequisite: '',
  },
  792: {
    id: '792',
    label: 'Equip an Abyssal Dagger',
    description: 'Equip an Abyssal Dagger.',
    skillReqs: [
      { skill: 'Attack', level: 70 },
      { skill: 'Slayer', level: 85 },
    ],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SIRE,
    prerequisite: '',
  },
  787: {
    id: '787',
    label: 'Equip an Ancient Wyvern Shield',
    description: 'Equip an Ancient Wyvern Shield.',
    skillReqs: [
      { skill: 'Magic', level: 70 },
      { skill: 'Defence', level: 75 },
    ],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1578: {
    id: '1578',
    label: 'Equip the Venator Ring',
    description: 'Obtain and Equip the Venator Ring.',
    skillReqs: [
      { skill: 'Magic', level: 90 },
      { skill: 'Crafting', level: 80 },
    ],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '',
  },
  758: {
    id: '758',
    label: 'Mine a Runite Ore Fragment',
    description: 'Mine a Runite Ore Fragment in the Volcanic Mine on Fossil Island.',
    skillReqs: [{ skill: 'Mining', level: 85 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  755: {
    id: '755',
    label: 'Receive a Torstol From a Herbiboar',
    description: 'Receive a Torstol from a Herbiboar on Fossil Island.',
    skillReqs: [
      { skill: 'Hunter', level: 80 },
      { skill: 'Herblore', level: 77 },
    ],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1484: {
    id: '1484',
    label: 'Attach a Holy ornament kit to the Scythe of Vitur',
    description: 'Attach a Holy ornament kit to the Scythe of Vitur.',
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOB,
    prerequisite: '',
  },
  1483: {
    id: '1483',
    label: 'Attach a Sanguine ornament kit to the Scythe',
    description: 'Attach a Sanguine ornament kit to the Scythe of Vitur.',
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOB,
    prerequisite: '',
  },
  1283: {
    id: '1283',
    label: 'Complete the Elite Morytania Diary',
    description: 'Complete all of the elite tasks in the Diary Morytania Achievement Diary.',
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.ELITE,
    prerequisite: '',
  },
  1227: {
    id: '1227',
    label: 'Complete the Hallowed Sepulchre in 8:00',
    description: 'Complete the Hallowed Sepulchre in Darkmeyer in 8:00 or less.',
    skillReqs: [{ skill: 'Agility', level: 92 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  1274: {
    id: '1274',
    label: 'Complete the Theatre of Blood 150 Times',
    description: 'Complete the Theatre of Blood on Normal or Hard Mode 150 times.',
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOB,
    prerequisite: '1273',
  },
  1275: {
    id: '1275',
    label: 'Complete the Theatre of Blood 250 Times',
    description: 'Complete the Theatre of Blood on Normal or Hard Mode 250 times.',
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOB,
    prerequisite: '1274',
  },
  1273: {
    id: '1273',
    label: 'Complete the Theatre of Blood 50 Times',
    description: 'Complete the Theatre of Blood on Normal or Hard Mode 50 times.',
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOB,
    prerequisite: '',
  },
  1263: {
    id: '1263',
    label: 'Create an Amulet of Blood Fury',
    description: 'Create an Amulet of Blood Fury.',
    skillReqs: [
      { skill: 'Crafting', level: 90 },
      { skill: 'Magic', level: 87 },
    ],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '',
  },
  1412: {
    id: '1412',
    label: 'Defeat The Nightmare 150 times',
    description: "Defeat The Nightmare or Phosani's Nightmare 150 times combined.",
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.NIGHTMARE,
    prerequisite: '1411',
  },
  1413: {
    id: '1413',
    label: 'Defeat The Nightmare 250 times',
    description: "Defeat The Nightmare or Phosani's Nightmare 250 times combined.",
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.NIGHTMARE,
    prerequisite: '1412',
  },
  1420: {
    id: '1420',
    label: 'Defeat the Theatre of Blood Hardmode 50 times',
    description: 'Defeat the Theatre of Blood Hardmode 50 times.',
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOB,
    prerequisite: '',
  },
  1419: {
    id: '1419',
    label: 'Defeat the Theatre of Blood Hardmode',
    description: 'Defeat the Theatre of Blood Hardmode.',
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOB,
    prerequisite: '1420',
  },
  1491: {
    id: '1491',
    label: 'Equip a Full set of Bloodbark armour',
    description:
      'Equip a Bloodbark helm, a Bloodbark body, some Bloodbark legs, some Bloodbark gauntlets and Bloodbark boots.',
    skillReqs: [
      { skill: 'Magic', level: 60 },
      { skill: 'Defence', level: 60 },
      { skill: 'Firemaking', level: 35 },
    ],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.SHADES,
    prerequisite: '',
  },
  1269: {
    id: '1269',
    label: 'Equip a Ghrazi Rapier',
    description: 'Equip a Ghrazi Rapier.',
    skillReqs: [{ skill: 'Attack', level: 80 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOB,
    prerequisite: '',
  },
  1261: {
    id: '1261',
    label: 'Equip a Nightmare Staff',
    description: 'Equip a normal Nightmare Staff.',
    skillReqs: [
      { skill: 'Magic', level: 72 },
      { skill: 'Hitpoints', level: 50 },
    ],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.NIGHTMARE,
    prerequisite: '',
  },
  1259: {
    id: '1259',
    label: "Equip a Piece of the Inquisitor's Set",
    description: "Equip any piece of the Inquisitor's Armour set.",
    skillReqs: [
      { skill: 'Strength', level: 70 },
      { skill: 'Defence', level: 30 },
    ],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.NIGHTMARE,
    prerequisite: '',
  },
  1267: {
    id: '1267',
    label: 'Equip a Piece of the Justiciar Set',
    description: 'Equip any piece of the Justiciar Armour set.',
    skillReqs: [{ skill: 'Defence', level: 75 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOB,
    prerequisite: '',
  },
  1229: {
    id: '1229',
    label: 'Equip a Ring of Endurance',
    description: 'Equip a Ring of Endurance.',
    skillReqs: [{ skill: 'Agility', level: 92 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  1265: {
    id: '1265',
    label: 'Equip a Sanguinesti Staff',
    description: 'Equip a Sanguinesti Staff.',
    skillReqs: [{ skill: 'Magic', level: 82 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOB,
    prerequisite: '',
  },
  1264: {
    id: '1264',
    label: 'Equip an Avernic Defender',
    description: 'Equip an Avernic Defender.',
    skillReqs: [
      { skill: 'Attack', level: 70 },
      { skill: 'Defence', level: 70 },
    ],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOB,
    prerequisite: '',
  },
  1257: {
    id: '1257',
    label: "Equip an Inquisitor's Mace",
    description: "Equip an Inquisitor's Mace.",
    skillReqs: [{ skill: 'Attack', level: 80 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.NIGHTMARE,
    prerequisite: '',
  },
  1255: {
    id: '1255',
    label: 'Equip Every Barrows Armour Set',
    description: 'Equip all six sets of Barrows armour.',
    skillReqs: [
      { skill: 'Defence', level: 70 },
      { skill: 'Attack', level: 70 },
      { skill: 'Strength', level: 70 },
      { skill: 'Ranged', level: 70 },
      { skill: 'Magic', level: 70 },
    ],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.BARROWS,
    prerequisite: '',
  },
  1251: {
    id: '1251',
    label: 'Equip Some Guardian Boots',
    description: 'Equip a pair of Guardian Boots.',
    skillReqs: [
      { skill: 'Defence', level: 75 },
      { skill: 'Slayer', level: 75 },
    ],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GGS,
    prerequisite: '',
  },
  1226: {
    id: '1226',
    label: 'Floor 5 of the Hallowed Sepulchre',
    description: 'Complete floor 5 of the Hallowed Sepulchre in Darkmeyer.',
    skillReqs: [{ skill: 'Agility', level: 92 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  1792: {
    id: '1792',
    label: 'Grotesque Guardians Combat Achievements',
    description: 'Complete all of the Combat Achievements for Grotesque Guardians.',
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GGS,
    prerequisite: '',
  },
  1791: {
    id: '1791',
    label: "Phosani's Nightmare Combat Achievements",
    description: "Complete all of the Combat Achievements for Phosani's Nightmare.",
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.NIGHTMARE,
    prerequisite: '',
  },
  1790: {
    id: '1790',
    label: 'The Nightmare Combat Achievements',
    description: 'Complete all of the Combat Achievements for The Nightmare.',
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.NIGHTMARE,
    prerequisite: '',
  },
  1735: {
    id: '1735',
    label: 'Catch 300 Red Chinchompas in Tirannwn',
    description: 'Catch 300 Red Chinchompas in Tirannwn.',
    skillReqs: [{ skill: 'Hunter', level: 63 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  865: {
    id: '865',
    label: 'Complete the Corrupted Gauntlet 150 Times',
    description: 'Complete the Corrupted Gauntlet in Prifddinas 150 times.',
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GAUNTLET,
    prerequisite: '864',
  },
  864: {
    id: '864',
    label: 'Complete the Corrupted Gauntlet 50 Times',
    description: 'Complete the Corrupted Gauntlet in Prifddinas 50 times.',
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GAUNTLET,
    prerequisite: '',
  },
  1487: {
    id: '1487',
    label: 'Craft a Celestial Signet',
    description: 'Craft a Celestial signet via a Singing Bowl.',
    skillReqs: [
      { skill: 'Crafting', level: 70 },
      { skill: 'Smithing', level: 70 },
      { skill: 'Hunter', level: 80 },
    ],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.GENERAL,
    prerequisite: '',
  },
  852: {
    id: '852',
    label: 'Craft a Toxic Blowpipe',
    description: 'Craft a Toxic Blowpipe.',
    skillReqs: [
      { skill: 'Fletching', level: 53 },
      { skill: 'Ranged', level: 75 },
    ],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.ZULRAH,
    prerequisite: '',
  },
  854: {
    id: '854',
    label: 'Craft a Toxic Trident',
    description: 'Craft a Toxic Trident.',
    skillReqs: [
      { skill: 'Crafting', level: 59 },
      { skill: 'Magic', level: 78 },
    ],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.ZULRAH,
    prerequisite: '',
  },
  1739: {
    id: '1739',
    label: 'Create 100 Divine Ranging Potions',
    description: 'Create 100 Divine Ranging Potions.',
    skillReqs: [{ skill: 'Herblore', level: 74 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  1738: {
    id: '1738',
    label: 'Create 100 Divine Super Combat Potions',
    description: 'Create 100 Divine Super Combat Potions.',
    skillReqs: [{ skill: 'Herblore', level: 97 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  868: {
    id: '868',
    label: 'Defeat Zalcano 150 Times',
    description: 'Defeat Zalcano in Prifddinas 150 times.',
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.ZALCANO,
    prerequisite: '867',
  },
  1754: {
    id: '1754',
    label: 'Defeat Zalcano 300 Times',
    description: 'Defeat Zalcano in Prifddinas 300 times.',
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.ZALCANO,
    prerequisite: '868',
  },
  871: {
    id: '871',
    label: 'Defeat Zulrah 150 Times',
    description: 'Defeat Zulrah at the Poison Waste 150 times.',
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.ZULRAH,
    prerequisite: '870',
  },
  1755: {
    id: '1755',
    label: 'Defeat Zulrah 300 Times',
    description: 'Defeat Zulrah at the Poison Waste 300 times.',
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.ZULRAH,
    prerequisite: '871',
  },
  1747: {
    id: '1747',
    label: 'Dismantle a Zulrah scale unique',
    description: 'Dismantle a Zulrah scale unique for 20,000 scales.',
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.ZULRAH,
    prerequisite: '',
  },
  824: {
    id: '824',
    label: 'Dissect 50 Sacred Eels',
    description: 'Dissect 50 Sacred Eels.',
    skillReqs: [
      { skill: 'Fishing', level: 87 },
      { skill: 'Cooking', level: 72 },
    ],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  833: {
    id: '833',
    label: 'Equip a Crystal Grail',
    description: 'Equip a Crystal Grail.',
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  840: {
    id: '840',
    label: 'Equip a Full Crystal Armour Set',
    description: 'Equip a full set of Crystal armour.',
    skillReqs: [{ skill: 'Defence', level: 70 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GAUNTLET,
    prerequisite: '',
  },
  876: {
    id: '876',
    label: 'Equip a Piece of the Dragonstone Armour Set',
    description: 'Equip any piece of the Dragonstone armour set.',
    skillReqs: [{ skill: 'Defence', level: 40 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  850: {
    id: '850',
    label: 'Equip a Serpentine Helm',
    description: 'Equip a Serpentine Helm.',
    skillReqs: [
      { skill: 'Crafting', level: 52 },
      { skill: 'Defence', level: 75 },
    ],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.ZULRAH,
    prerequisite: '',
  },
  829: {
    id: '829',
    label: 'Equip an Elven Signet',
    description: 'Equip an Elven Signet.',
    skillReqs: [{ skill: 'Hunter', level: 80 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1753: {
    id: '1753',
    label: 'Equip an Enhanced Crystal Weapon',
    description: 'Equip a Blade of Saeldor or Bow of faerdhinen.',
    skillReqs: [
      { skill: 'Attack', level: 80 },
      { skill: 'Ranged', level: 80 },
      { skill: 'Agility', level: 70 },
    ],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GAUNTLET,
    prerequisite: '',
  },
  1872: {
    id: '1872',
    label: 'Load a blowpipe with Dragon Darts',
    description: 'Load a Blowpipe with Dragon Darts.',
    skillReqs: [
      { skill: 'Fletching', level: 53 },
      { skill: 'Hunter', level: 83 },
      { skill: 'Fletching', level: 95 },
    ],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.ZULRAH,
    prerequisite: '',
  },
  1737: {
    id: '1737',
    label: 'Mine 200 Runite Ore in Tirannwn',
    description: 'Mine 200 Runite Ore in Tirannwn.',
    skillReqs: [{ skill: 'Mining', level: 85 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  848: {
    id: '848',
    label: 'Obtain a Crystal Tool Seed',
    description: 'Obtain a Crystal Tool Seed as a drop from Zalcano.',
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.ZALCANO,
    prerequisite: '',
  },
  1382: {
    id: '1382',
    label: 'Obtain a Zalcano Shard',
    description: 'Obtain a Zalcano Shard as a drop from Zalcano in Prifddinas.',
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.ZALCANO,
    prerequisite: '',
  },
  1740: {
    id: '1740',
    label: 'Smith 500 Rune Dart Tips in Prifddinas',
    description: 'Smith 500 Rune Dart Tips in Prifddinas.',
    skillReqs: [{ skill: 'Smithing', level: 89 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  1786: {
    id: '1786',
    label: 'Zulrah Combat Achievements',
    description: 'Complete all of the Combat Achievements for Zulrah.',
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.ZULRAH,
    prerequisite: '',
  },
  1349: {
    id: '1349',
    label: 'Build an Obelisk',
    description: 'Build an Obelisk in your Player Owned House.',
    skillReqs: [{ skill: 'Construction', level: 80 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  1359: {
    id: '1359',
    label: 'Complete the Elite Wilderness Diary',
    description: 'Complete all of the elite tasks in the Diary Wilderness Achievement Diary.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.ELITE,
    prerequisite: '',
  },
  1302: {
    id: '1302',
    label: 'Cook a Dark Crab',
    description: 'Cook a Dark Crab.',
    skillReqs: [
      { skill: 'Cooking', level: 90 },
      { skill: 'Fishing', level: 85 },
    ],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  1764: {
    id: '1764',
    label: 'Defeat Callisto 300 times',
    description: 'Defeat Callisto or Artio 300 times.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '1763',
  },
  1335: {
    id: '1335',
    label: 'Defeat the Corporeal Beast 150 Times',
    description: 'Defeat the Corporeal Beast in the Wilderness 150 times.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '1334',
  },
  1336: {
    id: '1336',
    label: 'Defeat the Corporeal Beast 250 Times',
    description: 'Defeat the Corporeal Beast in the Wilderness 250 times.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '1335',
  },
  1758: {
    id: '1758',
    label: 'Defeat Venenatis 300 times',
    description: 'Defeat Venenatis or Spindel 300 times.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '1757',
  },
  1761: {
    id: '1761',
    label: "Defeat Vet'ion 300 times",
    description: "Defeat Vet'ion or Calvar'ion 300 times.",
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '1760',
  },
  1308: {
    id: '1308',
    label: 'Equip 250 Black Chinchompas',
    description: 'Equip a stack of at least 250 Black Chinchompas.',
    skillReqs: [
      { skill: 'Hunter', level: 73 },
      { skill: 'Ranged', level: 65 },
    ],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '1307',
  },
  1309: {
    id: '1309',
    label: 'Equip 500 Black Chinchompas',
    description: 'Equip a stack of at least 500 Black Chinchompas.',
    skillReqs: [
      { skill: 'Hunter', level: 73 },
      { skill: 'Ranged', level: 65 },
    ],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '1308',
  },
  1339: {
    id: '1339',
    label: 'Equip a Blessed Spirit Shield',
    description: 'Equip a Blessed Spirit Shield.',
    skillReqs: [
      { skill: 'Defence', level: 70 },
      { skill: 'Prayer', level: 85 },
    ],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.CORP,
    prerequisite: '',
  },
  1371: {
    id: '1371',
    label: "Equip a Full Dagon'Hai Set",
    description: "Equip a full set of Dagon'hai robes.",
    skillReqs: [
      { skill: 'Magic', level: 70 },
      { skill: 'Defence', level: 40 },
    ],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  1344: {
    id: '1344',
    label: 'Equip a Malediction Ward',
    description: 'Equip a Malediction Ward.',
    skillReqs: [{ skill: 'Defence', level: 60 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  1370: {
    id: '1370',
    label: "Equip a Piece of the Dagon'Hai Set",
    description: "Equip any piece of the Dagon'hai robe set.",
    skillReqs: [
      { skill: 'Magic', level: 70 },
      { skill: 'Defence', level: 40 },
    ],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  1326: {
    id: '1326',
    label: 'Equip a Ring of the Gods',
    description: 'Equip a Ring of the Gods.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  1020: {
    id: '1020',
    label: "Equip a Thammaron's Sceptre",
    description: "Equip a Thammaron's Sceptre.",
    skillReqs: [{ skill: 'Magic', level: 60 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1318: {
    id: '1318',
    label: 'Equip a Treasonous Ring',
    description: 'Equip a Treasonous Ring.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  1322: {
    id: '1322',
    label: 'Equip a Tyrannical Ring',
    description: 'Equip a Tyrannical Ring.',
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  1345: {
    id: '1345',
    label: 'Equip an Odium Ward',
    description: 'Equip an Odium Ward.',
    skillReqs: [{ skill: 'Defence', level: 60 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  1347: {
    id: '1347',
    label: "Equip Craw's Bow",
    description: "Equip Craw's Bow.",
    skillReqs: [{ skill: 'Ranged', level: 60 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1654: {
    id: '1654',
    label: 'Equip full Elder chaos robe',
    description: 'Equip the Robe, and top.',
    skillReqs: [{ skill: 'Magic', level: 40 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1614: {
    id: '1614',
    label: 'Equip the Accursed Sceptre',
    description: 'Obtain and Equip the Accursed Sceptre.',
    skillReqs: [{ skill: 'Magic', level: 70 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  1615: {
    id: '1615',
    label: 'Equip the Ursine Chainmace',
    description: 'Obtain and Equip the Ursine Chainmace.',
    skillReqs: [{ skill: 'Attack', level: 70 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  1612: {
    id: '1612',
    label: 'Equip the Voidwaker',
    description: 'Obtain and Equip the Voidwaker.',
    skillReqs: [{ skill: 'Attack', level: 75 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  1613: {
    id: '1613',
    label: 'Equip the Webweaver',
    description: 'Obtain and Equip the Webweaver.',
    skillReqs: [{ skill: 'Ranged', level: 70 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  1234: {
    id: '1234',
    label: "Equip Viggora's Chainmace",
    description: "Equip Viggora's Chainmace.",
    skillReqs: [{ skill: 'Attack', level: 60 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  1303: {
    id: '1303',
    label: 'Make an Extended Antifire Potion',
    description: 'Make an Extended Antifire Potion.',
    skillReqs: [{ skill: 'Herblore', level: 84 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  1314: {
    id: '1314',
    label: 'Mine Some Runite Ore in the Wilderness',
    description: 'Mine some Runite Ore in the Wilderness.',
    skillReqs: [{ skill: 'Mining', level: 85 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  1814: {
    id: '1814',
    label: '750 Collection log slots',
    description: 'Obtain 750 unique Collection Log slots.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.COLLECTION_LOG,
    prerequisite: '1813',
  },
  1470: {
    id: '1470',
    label: 'Combat Achievements Elite Tier',
    description: 'Obtain enough points to unlock the elite tier of combat achievements',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.ELITE,
    prerequisite: '1469',
  },
  1469: {
    id: '1469',
    label: 'Combat Achievements Hard Tier',
    description: 'Obtain enough points to unlock the hard tier of combat achievements',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.HARD,
    prerequisite: '1468',
  },
  1471: {
    id: '1471',
    label: 'Combat Achievements Master Tier',
    description: 'Obtain enough points to unlock the master tier of combat achievements',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.MASTER,
    prerequisite: '1470',
  },
  779: {
    id: '779',
    label: 'Equip a Dragon Platebody or Dragon Kiteshield',
    description: 'Equip either a Dragon Platebody or a Dragon Kiteshield.',
    skillReqs: [
      { skill: 'Defence', level: 60 },
      { skill: 'Smithing', level: 75 },
    ],
    regions: ['General'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1582: {
    id: '1582',
    label: 'Equip full Virtus',
    description: 'Equip full Virtus outfit, obtained from Desert Treasure II bosses.',
    skillReqs: [
      { skill: 'Magic', level: 78 },
      { skill: 'Defence', level: 75 },
    ],
    regions: ['General'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '',
  },
  531: {
    id: '531',
    label: 'Reach Base Level 95',
    description: 'Reach level 95 in every skill.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.BASE,
    prerequisite: '530',
  },
  520: {
    id: '520',
    label: 'Reach Total Level 2277',
    description: 'Reach the highest possible Total Level of 2277.',
    skillReqs: [],
    regions: ['General'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.TOTAL,
    prerequisite: '519',
  },
  1518: {
    id: '1518',
    label: 'Equip a Full Set of Torva Armour',
    description: 'Equip a Torva full helm, a Torva platebody and some Torva platelegs.',
    skillReqs: [
      { skill: 'Defence', level: 80 },
      { skill: 'Agility', level: 70 },
      { skill: 'Hitpoints', level: 70 },
      { skill: 'Ranged', level: 70 },
      { skill: 'Strength', level: 70 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '',
  },
  1108: {
    id: '1108',
    label: 'Equip Every Godsword',
    description: 'Equip all five Godswords',
    skillReqs: [
      { skill: 'Attack', level: 75 },
      { skill: 'Agility', level: 70 },
      { skill: 'Hitpoints', level: 70 },
      { skill: 'Ranged', level: 70 },
      { skill: 'Strength', level: 70 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '',
  },
  1785: {
    id: '1785',
    label: 'Godwars Dungeon Combat Achievements',
    description: 'Complete all of the Combat Achievements for Godwars Dungeon bosses, including Nex.',
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '',
  },
  1208: {
    id: '1208',
    label: 'Equip a Full Infinity Robe Set',
    description: 'Equip a full set of Infinity robes.',
    skillReqs: [
      { skill: 'Magic', level: 50 },
      { skill: 'Defence', level: 25 },
    ],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.MTA,
    prerequisite: '',
  },
  1587: {
    id: '1587',
    label: 'Equip a full set of Masori (f)',
    description: 'Obtain and Equip Masori Mask, Body and chaps. All Fortified.',
    skillReqs: [
      { skill: 'Ranged', level: 80 },
      { skill: 'Defence', level: 80 },
      { skill: 'Crafting', level: 90 },
    ],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOA,
    prerequisite: '',
  },
  1589: {
    id: '1589',
    label: "Equip Osmumten's Fang (or)",
    description: "Obtain and Equip Osmumten's Fang (or).",
    skillReqs: [{ skill: 'Attack', level: 82 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOA,
    prerequisite: '',
  },
  1592: {
    id: '1592',
    label: "Equip the Tumeken's Shadow",
    description: "Obtain and Equip the Tumeken's Shadow.",
    skillReqs: [{ skill: 'Magic', level: 85 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOA,
    prerequisite: '',
  },
  1784: {
    id: '1784',
    label: 'Tombs of Amascut Combat Achievements',
    description: 'Complete all of the Combat Achievements for Tombs of Amascut: Entry, Normal and Expert.',
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOA,
    prerequisite: '',
  },
  889: {
    id: '889',
    label: 'Build a Demonic Throne',
    description: 'Build a Demonic Throne in a Throne Room in your Player Owned House.',
    skillReqs: [{ skill: 'Construction', level: 99 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  951: {
    id: '951',
    label: 'Equip Every Completed God Book',
    description: 'Equip every completed God Book.',
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.SHARED,
    prerequisite: '',
  },
  904: {
    id: '904',
    label: 'Equip Every Draconic Shield',
    description: 'Equip a Dragonfire Ward, a Dragonfire Shield and an Ancient Wyvern Shield.',
    skillReqs: [{ skill: 'Defence', level: 75 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1043: {
    id: '1043',
    label: 'Enhance a Trident of the Seas',
    description: 'Enhance a Trident of the Seas.',
    skillReqs: [{ skill: 'Slayer', level: 87 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.KRAKEN,
    prerequisite: '',
  },
  1080: {
    id: '1080',
    label: 'Equip a Kruk Jr. Monkey Backpack',
    description: 'Equip a Kruk Jr. Monkey Backpack.',
    skillReqs: [{ skill: 'Agility', level: 48 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  1081: {
    id: '1081',
    label: 'Equip a Prince Awowogei Monkey Backpack',
    description: 'Equip a Prince Awowogei Monkey Backpack.',
    skillReqs: [{ skill: 'Agility', level: 48 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  1036: {
    id: '1036',
    label: 'Equip All Zenyte Jewelry',
    description: 'Equip every piece of Zenyte Jewelry.',
    skillReqs: [{ skill: 'Crafting', level: 98 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '',
  },
  992: {
    id: '992',
    label: 'Complete the Inferno 15 Times',
    description: 'Complete the Inferno in Mor Ul Rek 15 times.',
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.INFERNO,
    prerequisite: '991',
  },
  1879: {
    id: '1879',
    label: 'Complete Tzhaar-Ket-Raks Special challenge',
    description: 'Complete Tzhaar-Ket-Raks league-only challenge.',
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GENERAL,
    prerequisite: '',
  },
  974: {
    id: '974',
    label: 'Equip an Infernal Cape',
    description: 'Equip an Infernal Cape.',
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.INFERNO,
    prerequisite: '',
  },
  1777: {
    id: '1777',
    label: 'The Fight Caves Combat Achievements',
    description: 'Complete all of the Combat Achievements for The Fight Caves.',
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.JAD,
    prerequisite: '',
  },
  1776: {
    id: '1776',
    label: 'The Inferno Combat Achievements',
    description: 'Complete all of the Combat Achievements for The Inferno.',
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.INFERNO,
    prerequisite: '',
  },
  1816: {
    id: '1816',
    label: "TzHaar-Ket-Rak's Combat Achievements",
    description: "Complete all of the Combat Achievements for TzHaar-Ket-Rak's Challenges.",
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GENERAL,
    prerequisite: '',
  },
  1782: {
    id: '1782',
    label: 'Chambers of Xeric Combat Achievements',
    description: 'Complete all of the Combat Achievements for Chambers of Xeric: Normal mode and Challenge mode.',
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.COX,
    prerequisite: '',
  },
  1395: {
    id: '1395',
    label: 'Equip a full set of Ancestral',
    description: 'Equip an Ancestral Hat, an Ancestral robe top and an Ancestral robe bottom.',
    skillReqs: [
      { skill: 'Magic', level: 75 },
      { skill: 'Defence', level: 65 },
    ],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.COX,
    prerequisite: '',
  },
  392: {
    id: '392',
    label: 'Equip a Twisted Bow',
    description: 'Obtain and equip a Twisted bow.',
    skillReqs: [{ skill: 'Ranged', level: 85 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.COX,
    prerequisite: '',
  },
  1228: {
    id: '1228',
    label: 'Complete the Hallowed Sepulchre in 6:30',
    description: 'Complete the Hallowed Sepulchre in Darkmeyer in 6:30 or less.',
    skillReqs: [{ skill: 'Agility', level: 92 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  1260: {
    id: '1260',
    label: "Equip a Full Inquisitor's Set",
    description: "Equip a full set of Inquisitor's Armour.",
    skillReqs: [
      { skill: 'Strength', level: 70 },
      { skill: 'Defence', level: 30 },
    ],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.NIGHTMARE,
    prerequisite: '',
  },
  1268: {
    id: '1268',
    label: 'Equip a Full Justiciar Set',
    description: 'Equip a full set of Justiciar Armour.',
    skillReqs: [{ skill: 'Defence', level: 75 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOB,
    prerequisite: '',
  },
  1262: {
    id: '1262',
    label: 'Equip a Nightmare Staff With an Orb',
    description:
      'Equip either an Eldritch Nightmare Staff, a Harmonised Nightmare Staff or a Volatile Nightmare Staff.',
    skillReqs: [
      { skill: 'Magic', level: 82 },
      { skill: 'Hitpoints', level: 50 },
    ],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.NIGHTMARE,
    prerequisite: '',
  },
  1266: {
    id: '1266',
    label: 'Equip a Scythe of Vitur',
    description: 'Equip a Scythe of Vitur.',
    skillReqs: [
      { skill: 'Attack', level: 80 },
      { skill: 'Strength', level: 90 },
    ],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOB,
    prerequisite: '',
  },
  1783: {
    id: '1783',
    label: 'Theatre of Blood Combat Achievements',
    description: 'Complete all of the Combat Achievements for Theatre of Blood: Entry, Normal and Hard mode.',
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOB,
    prerequisite: '',
  },
  845: {
    id: '845',
    label: 'Equip a Corrupted Blade of Saeldor',
    description: 'Equip a Corrupted Blade of Saeldor.',
    skillReqs: [{ skill: 'Attack', level: 80 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GAUNTLET,
    prerequisite: '',
  },
  1622: {
    id: '1622',
    label: 'Equip a Corrupted Bow of Faerdhinen',
    description: 'Obtain and Equip a Corrupted Bow of Faerdhinen.',
    skillReqs: [
      { skill: 'Ranged', level: 80 },
      { skill: 'Agility', level: 70 },
    ],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GAUNTLET,
    prerequisite: '',
  },
  877: {
    id: '877',
    label: 'Equip a Crystal Crown',
    description: 'Equip a Crystal Crown.',
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1795: {
    id: '1795',
    label: 'Gauntlet Combat Achievements',
    description: 'Complete all of the Combat Achievements for the Gauntlet & Corrupted Gauntlet.',
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GAUNTLET,
    prerequisite: '',
  },
  1337: {
    id: '1337',
    label: 'Equip a Spectral or Arcane Spirit Shield',
    description: 'Equip either a Spectral Spirit Shield or an Arcane Spirit Shield.',
    skillReqs: [
      { skill: 'Defence', level: 45 },
      { skill: 'Prayer', level: 55 },
    ],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.CORP,
    prerequisite: '',
  },
  1360: {
    id: '1360',
    label: 'Equip an Amulet of Eternal Glory',
    description: 'Equip an Amulet of Eternal Glory.',
    skillReqs: [
      { skill: 'Hunter', level: 83 },
      { skill: 'Crafting', level: 80 },
      { skill: 'Magic', level: 68 },
    ],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1338: {
    id: '1338',
    label: 'Equip an Elysian Spirit Shield',
    description: 'Equip an Elysian Spirit Shield.',
    skillReqs: [
      { skill: 'Defence', level: 75 },
      { skill: 'Prayer', level: 85 },
      { skill: 'Prayer', level: 90 },
      { skill: 'Smithing', level: 85 },
    ],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.CORP,
    prerequisite: '',
  },
  1348: {
    id: '1348',
    label: 'Obtain Every Revenant Weapon',
    description: "Obtain Craws Bow, Viggora's Chainmace and Thammaron's Sceptre as drops from Revenants.",
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
};
